<template>
  <Navbar />
  <ScrollButton/>
  <loading
    v-mode:active="isLoading"
    :is-full-page="true"
    color="#ffc300"
  ></loading>
  <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <i class="fa fa-home"></i>
              <p>S.S. Hasköy</p>
              <h4>Firmalar</h4>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->
  <section class="our-team-area">
    <div class="container">
      <div class="header-new mb-5">
      <div class="sec-title" >
        <h1>Firmalar</h1>
        <input
            class="search-input"
            type="text"
            v-model="searchQuery"
            @input="search"
            placeholder="Arama yapın..."
          />
      </div>
    </div>
      <div class="row mt-1" v-for="f in displayedCompany" :key="f.id">
        <div class="col-md-3 col-sm-3 col-sm-1">
          <div class="company">
            <div class="single-client-wrap">
              <img src="/images/icon-image/factory.png" />
            </div>
          </div>
        </div>
        
        <div class="col-md-9 col-sm-9 col-xs-6">
          <div class="text-holder">
            <h3>{{ f.firmaninAdi }}</h3>
            <h5>Blok No: {{ f.blokNo }} | İşyeri No: {{ f.isyeriNo }}</h5>
            <ul>
              <li><span> Tel: </span>{{ f.telNo }}</li>
              <li><span> Mail: </span>{{ f.email }}</li>
              <li><span> Üretim: </span>{{ f.uretimTur }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="pagination">
        <button @click="prevPage"  v-if="currentPage > 1"><i class="bi bi-chevron-double-left"></i></button>
        <button
          v-for="pageNumber in visiblePages"
          :key="pageNumber"
          @click="goToPage(pageNumber)"
          :class="{ active: currentPage === pageNumber }"
        >
          {{ pageNumber }}
        </button>
        <span v-if="totalPages > 5 && currentPage < totalPages - 2">...</span>
        <button @click="nextPage" v-if="currentPage < totalPages "><i class="bi bi-chevron-double-right"></i></button>
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import { ref, onMounted,computed } from "vue";
import { auth, firestore } from "@/firebase/config";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";

import ConfirmDialog from "@/components/ConfirmDialog.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Loading,
    ConfirmDialog,
    Footer,
    Navbar,
    ScrollButton
  },
  
  setup() {
    const firmalar = ref([]);
    const isLoading = ref(true);

    const bosMesajı = ref("Firma Bulunamadı");
    const searchQuery = ref('');

    const currentPage = ref(1);
    const pageSize = 20; // Sayfa başına öğe sayısı
    const totalItems = ref(null); // Toplam öğe sayısı

    const totalPages = computed(() => Math.ceil(totalItems.value / pageSize));

    const displayedCompany = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      console.log(firmalar.value.slice(startIndex, endIndex));
      return firmalar.value.slice(startIndex, endIndex);
    });

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        // Sayfaya göre verileri güncelleme işlemleri burada yapılabilir
      }
    };
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        goToPage(currentPage.value);
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        goToPage(currentPage.value);
      }
    };
    const visiblePages = computed(() => {
      if (totalPages.value <= 5) {
        return Array.from({ length: totalPages.value }, (_, i) => i + 1);
      } else {
        const startPage = Math.max(currentPage.value - 2, 1);
        const endPage = Math.min(startPage + 4, totalPages.value);
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
      }
    });

    const search = async () => {
      console.log('Arama:', searchQuery.value);
      
      // Firestore'dan tüm verileri getirme
      const q = query(
        collection(firestore, "firmalar"),
        where("durum", "==", true),
        orderBy("olusturulmaTarihi", "desc")
      );

      const querySnapshot = await getDocs(q);
      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      // Arama sorgusuyla verileri filtreleme
      const filteredData = veri.filter(item => {
        return item.firmaninAdi.toLowerCase().includes(searchQuery.value.toLowerCase());
      });

      firmalar.value = filteredData;
    };
    onMounted(async () => {
      let q = query(
        collection(firestore, "firmalar"),
        where("durum", "==", true),
        orderBy("olusturulmaTarihi", "desc")
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      firmalar.value = veri;
      isLoading.value = false;
      totalItems.value = veri.length;
    });

    return {
      firmalar,
      isLoading,
      bosMesajı,
      searchQuery,
      search,
      currentPage,
      totalPages,
      goToPage,
      displayedCompany,
      nextPage,
      prevPage,
      visiblePages
    };
  },
};
</script>

<style scoped>
.header-new{
  height: 100px;
}
.single-client-wrap {
  border: 1px solid #eee;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.company{
  border-bottom: 1px solid #001e57e4;
}


</style>