<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="fileHata">{{ fileHata }}</div>
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Sanayi Üye Bilgileri Güncelleme</h2>
          <p class="lead">
            <router-link to="/admin/member-list"
              >Sanayi Üye Listesi içn Tıklayınız</router-link
            >
          </p>
        </div>
      </div>
      <div class="row g-5">
        <div class="col-md-8 col-lg-8">
          <h4 class="mb-3">Sanayi Üye Bilgileri</h4>
          <div class="d-flex justify-content-between">
            <p>Üyelik tarihi: {{ tarih }}</p>
          </div>
          <form class="needs-validation" @submit.prevent="handleSubmit">
            <div class="row g-3">
              <div class="col-sm-6">
                <label for="isim" class="form-label">Üye İsim</label>
                <input
                  type="text"
                  class="form-control"
                  id="isim"
                  placeholder=""
                  v-model="isim"
                />
              </div>
              <div class="col-sm-6">
                <label for="telNo" class="form-label">Telefon No</label>
                <input
                  type="text"
                  class="form-control"
                  id="telNo"
                  placeholder=""
                  v-model="telNo"
                />
              </div>
              <div class="col-6">
                Durum
                <label for="aktif" class="form-label">Durum</label>
                <div class="col-3">
                  <input
                    type="radio"
                    id="aktif"
                    name="durum"
                    :value="true"
                    v-model="durum"
                  />
                  <label for="aktif" class="form-label">Aktif</label>
                </div>
                <div class="col-3">
                  <input
                    type="radio"
                    id="pasif"
                    name="durum"
                    :value="false"
                    v-model="durum"
                  />
                  <label for="pasif" class="form-label">Pasif</label>
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <button class="w-100 btn btn-success btn-lg mb-4" type="submit">
              Güncelle
            </button>
          </form>
        </div>
      </div>
    </main>
  </div>
  <AdminFooter />
</template>
    
    <script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { doc, getDoc, updateDoc,collection } from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { notify } from "@kyvg/vue3-notification";
import { useRoute,useRouter } from "vue-router";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";

export default {
  components: {
    Loading,
    AdminFooter,
    AdminNavbar,
    VueFinalModal,
    ModalsContainer,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const showModal = ref(false);
    const isLoading = ref(true);
    const hId = ref(route.params.uyeId);
    const tarih = ref("");

    const olusturulmaTarihi = ref("");
    const isim = ref("");
    const telNo = ref("");
    const durum = ref(false);
    const fileHata = ref(null);
    const hataMesaj = ref("");

    const bilgiKontrol = ref(false);
    moment.locale("tr");

    onMounted(async () => {
      const docRef = doc(firestore, "haskoyUyeler", hId.value);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        olusturulmaTarihi.value = docSnap.data().olusturulmaTarihi.toDate();
        isim.value = docSnap.data().isim;
        telNo.value = docSnap.data().telNo;
        durum.value = docSnap.data().durum;

        isLoading.value = false;
        tarih.value = moment(olusturulmaTarihi.value).fromNow();
      } else {
        console.log("Veriye erişilemedi");
      }
    });

    const handleSubmit = async () => {
      isLoading.value = true;
      const myCollection = collection(firestore, "haskoyUyeler");
      const myDocRef = doc(myCollection, hId.value);
      try {
        const data = {
          isim: isim.value,
          telNo: telNo.value,
          durum: durum.value === true ? true : false,
        };
        await updateDoc(myDocRef, data);
        router.push("/admin/member-list");

      } catch (error) {
        hataMesaj.value = error.message;
        isLoading.value = false;
        showModal.value = true;
      }
    };

    return {
      tarih,
      hId,
      olusturulmaTarihi,
      isim,
      telNo,
      durum,
      isLoading,
      handleSubmit,
      hataMesaj,
      fileHata,
      showModal
    };
  },
};
</script>
    
    <style>
</style>
    
    