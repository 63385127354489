<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Gelen Mesajlar</h2>
        </div>
      </div>
      <custom-table
        :rows="displayedMessage"
        @updateButtonClick="updateHandler($event)"
        @deleteButtonClick="deleteHandler($event)"
        :update-button-visible="true"
        :delete-button-visible="false"
        :actionsButtonsVisible="true"
        :columns="tableColumns"
      >
      </custom-table>
    </main>
    <div class="pagination-admin">
      <button @click="prevPage" v-if="currentPage > 1">
        <i class="bi bi-chevron-double-left"></i>
      </button>
      <button
        v-for="pageNumber in totalPages"
        :key="pageNumber"
        @click="goToPage(pageNumber)"
        :class="{ active: currentPage === pageNumber }"
      >
        {{ pageNumber }}
      </button>
      <button @click="nextPage" v-if="currentPage < totalPages">
        <i class="bi bi-chevron-double-right"></i>
      </button>
    </div>
  </div>
  <AdminFooter />
</template>
  
  <script>
import { ref, onMounted, computed } from "vue";
import { auth, firestore } from "@/firebase/config";
import { query, collection, orderBy, limit, getDocs } from "firebase/firestore";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
import CustomTable from "@/components/Table.vue";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
    VueFinalModal,
    ModalsContainer,
    AdminFooter,
    AdminNavbar,
    CustomTable,
  },
  data() {
    return {
      tableColumns: [
        { label: "İsim", value: "isim" },
        { label: "Email", value: "email" },
        { label: "Telefon", value: "telNo" },
        { label: "Konu", value: "konu" },
        { label: "İlgili Kurum", value: "kurum" },
        { label: "Mesaj", value: "mesaj" },
        { label: "Talep/Öneri", value: "oneriTalep" },
        {
          label: "Okundu",
          value: "okundu",
        },
      ],
    };
  },

  methods: {
    updateHandler(data) {
      this.$router.push({
        name: "MessageDetail",
        params: { mesajId: data.item.id },
        props: true,
      });
    },
  },
  setup() {
    const mesajlar = ref([]);
    const isLoading = ref(true);
    const showModal = ref(false);
    const hataMesaj = ref("");
    const currentPage = ref(1);
    const pageSize = 10; // Sayfa başına öğe sayısı
    const totalItems = ref(null); // Toplam öğe sayısı

    const totalPages = computed(() => Math.ceil(totalItems.value / pageSize));

    const displayedMessage = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      console.log(mesajlar.value.slice(startIndex, endIndex));
      return mesajlar.value.slice(startIndex, endIndex);
    });
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        // Sayfaya göre verileri güncelleme işlemleri burada yapılabilir
      }
    };
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        goToPage(currentPage.value);
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        goToPage(currentPage.value);
      }
    };

    const truncate = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength - 3) + "...";
      } else {
        return text;
      }
    };
    onMounted(async () => {
      let q = query(
        collection(firestore, "mesajlar"),
        orderBy("olusturulmaTarihi", "desc")
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      mesajlar.value = veri;

      veri.forEach((a) => {
        a.mesaj = truncate(a.mesaj, 20);

        if (a.okundu) {
          a.okundu = "Evet";
        } else {
          a.okundu = "Hayır";
        }
      });
      isLoading.value = false;
      totalItems.value = veri.length;
    });

    return {
      mesajlar,
      isLoading,
      showModal,
      hataMesaj,
      currentPage,
      totalPages,
      goToPage,
      displayedMessage,
      prevPage,
      nextPage,
    };
  },
};
</script>
  
  <style scoped>
</style>