<template>
  <section id="facts" class="section-padding">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div class="counter-wrap">
            <div class="counter-img">
              <img
                src="images/slider/1-resize.jpg"
                class="rounded-2"
                alt="total member and company"
              />
            </div>
            <div class="counter-content">
              <div class="single-counter-item dark-bg rounded-2">
                <img
                  loading="lazy"
                  src="images/icon-image/ind.png"
                  alt="total company img"
                />
                <h1><span class="counterUp" data-count="500">0</span></h1>
                <h3 class="ts-facts-title">Toplam <br />Firma Sayısı</h3>
              </div>
              <div class="single-counter-item dark-bg rounded-2">
                <img
                  loading="lazy"
                  src="images/icon-image/worker.png"
                  alt="total member img"
                />
                <h1><span class="counterUp" data-count="315">0</span></h1>
                <h3 class="ts-facts-title">
                  Toplam <br />
                  Üye Sayısı
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div class="counter-wrap2">
            <div class="top">
              <h3>Duyurular</h3>
            </div>
            <div class="content">
              <ul>
                <li
                  class="border-bottom"
                  v-for="(notice, index) in displayedNotice"
                  :key="index"
                >
                  <h5>
                    {{ notice.olusturulmaTarihi }}
                  </h5>
                  <img
                    src="images/icon-image/megaphone.png"
                    width="20px"
                    height="20px"
                    style="float: left; margin-right: 10px"
                  />
                  <h4>{{ notice.baslik }}</h4>
                  <p>{{ notice.icerik }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="pagination">
            <button @click="prevPage" v-if="currentPage > 1">
              <i class="bi bi-chevron-double-left"></i>
            </button>
            <button
              v-for="pageNumber in totalPages"
              :key="pageNumber"
              @click="goToPage(pageNumber)"
              :class="{ active: currentPage === pageNumber }"
            >
              {{ pageNumber }}
            </button>
            <button @click="nextPage" v-if="currentPage < totalPages">
              <i class="bi bi-chevron-double-right"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Col end -->
    </div>
    <!--/ Content row end -->
  </section>
  <!-- Facts end -->
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import { ref, onMounted, computed } from "vue";
import { auth, firestore } from "@/firebase/config";
import moment from "moment";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";
export default {
  data() {
    return {};
  },
  setup() {
    const currentPage = ref(1);
    const pageSize = 3; // Sayfa başına öğe sayısı
    const totalItems = ref(null); // Toplam öğe sayısı

    const duyurular = ref([]);
    const isLoading = ref(true);
    moment.locale("tr");

    const totalPages = computed(() => Math.ceil(totalItems.value / pageSize));

    const displayedNotice = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      console.log(duyurular.value.slice(startIndex, endIndex));
      return duyurular.value.slice(startIndex, endIndex);
    });
    // Sayfa değiştirme işlevi
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        // Sayfaya göre verileri güncelleme işlemleri burada yapılabilir
      }
    };
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        goToPage(currentPage.value);
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        goToPage(currentPage.value);
      }
    };
    onMounted(async () => {
      let q = query(
        collection(firestore, "duyurular"),
        where("durum", "==", true),
        orderBy("olusturulmaTarihi", "desc")
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      duyurular.value = veri;
      isLoading.value = false;
      totalItems.value = veri.length;
      duyurular.value.forEach((item) => {
      item.olusturulmaTarihi= moment(item.olusturulmaTarihi.toDate()).format("DD-MM-YYYY");
      });
      

    });
    return {
      duyurular,
      isLoading,
      currentPage,
      totalPages,
      goToPage,
      displayedNotice,
      prevPage,
      nextPage,
    };
  },
};
</script>



<style scoped>
.content {
  max-height: 80%;
}
.top {
  display: flex;
  justify-content: center;
}

.theme-bg {
  background: #001e57;
}
.theme-bg h3 {
  color: #fff;
}
ul {
  list-style: none;
}
.dark-bg {
  background: #001e57;
}

.single-counter-item {
  padding: 20px;
  text-align: center;
  width: 260px;
}
.single-counter-item img {
  width: 70px;
}
.single-counter-item h1 {
  font-size: 50px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
  margin-top: 10px;
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .single-counter-item {
    padding: 20px;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-counter-item {
    padding: 20px;
    width: 100%;
  }
  .single-counter-item img {
    width: 40px;
  }
  .single-counter-item h1 {
    font-size: 20px;
  }
  .single-counter-item h3 {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .single-counter-item {
    padding: 20px;
    width: 100%;
    margin-right: 30px;
  }
  .single-counter-item img {
    width: 40px;
  }
  .single-counter-item h1 {
    font-size: 20px;
  }
  .single-counter-item h3 {
    font-size: 20px;
  }
}

.counter-content {
  display: flex;
  gap: 20px;
  position: absolute;
  bottom: 20px;
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .counter-content {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 20px;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-content {
    width: 100%;
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .counter-content {
    bottom: 0px;
    gap: 0px;
    width: 86%;
  }
}

.single-counter-item p {
  color: #fff;
}
@media (max-width: 767px) {
  .single-counter-item p {
    margin: 0;
  }
}
.counter-wrap2 {
  position: relative;
  margin-top: 60px;
}
.counter-wrap {
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-wrap {
    margin-top: 60px;
  }
  .counter-wrap2 {
    margin-top: 60px;
  }
}

.counter-img {
  margin-top: 60px;
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .counter-img img {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-img img {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .counter-img img {
    width: 80%;
  }
}
.section-padding {
  padding: 0px 0;
}

/*Section Title */
.section-title {
  position: relative;
  margin-bottom: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .section-title {
    margin-bottom: 10px;
  }
}

.section-title h1 {
  font-size: 70px;
  font-weight: 600;
  line-height: 75px;
  letter-spacing: -2px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title h1 {
    font-size: 60px;
    line-height: 65px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h1 {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .section-title h1 {
    font-size: 40px;
    line-height: 50px;
  }
}

.section-title h2 {
  font-size: 60px;
  font-weight: 500;
  line-height: 65px;
  color: #181d4e;
  letter-spacing: -1px;
  margin-top: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title h2 {
    font-size: 55px;
    line-height: 60px;
    margin: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2 {
    font-size: 45px;
    line-height: 50px;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .section-title h2 {
    font-size: 35px;
    line-height: 45px;
    margin: 15px 0;
  }
}

.section-title h3 {
  font-size: 30px;
  line-height: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title h3 {
    font-size: 30px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h3 {
    font-size: 18px;
    line-height: 20px;
  }
}
@media (max-width: 767px) {
  .section-title h3 {
    font-size: 12px;
    line-height: 15px;
  }
}

.section-title h6 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 10px;
  opacity: 0.5;
}

.section-title p {
  margin: 0;
}

.section-title span {
  color: #fa4729;
}
</style>