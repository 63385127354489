<template>
    <footer id="footer" class="footer bg-overlay mt-auto">
  
        <div class="copyright bg-dark text-white">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="copyright-info text-center">
                  <span>2024 &copy; <b>Gebze Hasköy Sanayi Sitesi.</b> Tüm hakları saklıdır.
                  </span>
                </div>
              </div>
            </div><!-- Row end -->
  
            <div id="back-to-top" data-spy="affix" data-offset-top="10" class="back-to-top position-fixed">
              <button class="btn btn-primary" title="Back to Top">
                <i class="fa fa-angle-double-up"></i>
              </button>
            </div>
  
          </div><!-- Container end -->
        </div><!-- Copyright end -->
      </footer><!-- Footer end -->
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style scoped>
#footer{
    margin-top:50px;
}

  
  </style>