<template>
  <transition name="modal-animation">
    <div v-show="modalActive" class="modal">
      <transition name="modal-animation-inner">
        <div v-show="modalActive" class="modal-inner">
          <i @click="close" class="far fa-times-circle"></i>
          <slot />
          <button @click="handleDelete()"  type="button">Evet</button>

          <button @click="close" type="button">Hayır</button>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { onMounted, ref } from "vue";
import { firestore } from "@/firebase/config";
import { doc, deleteDoc, getDoc } from "firebase/firestore";
import { useRouter } from "vue-router";
export default {
  props: ["modalActive", "firmaId", "huyeId"],

  setup(props, { emit }) {

    const firmaId = ref("");
    const router = useRouter();
    const errorCode = ref("");
    const errorMes = ref("");
    const huyeId = ref(props.huyeId);

    console.log(huyeId.value);

    const close = () => {
      emit("close");
    };

    const handleDelete = async () => {

      if (firmaId.value !== "" || firmaId.value !== "") {
        const docRef = doc(firestore, "firmalar", firmaId.value);

        const docSnap = await getDoc(docRef);

        console.log(firmaId.value);
        if (docSnap.exists()) {
          await deleteDoc(docRef)
            .then(() => {
              console.log("silindi");
            })
            .catch((error) => {
              errorCode = error.code;
              errorMes = error.message;
              console.log(errorMes);
            });
          emit("close");
          if (errorCode !== "") {
            router.push({ name: "CompanyAddRemove" });
          }
        }
      } else if (huyeId.value !== "" || huyeId.value !== "") {

      
  

        const docRef = doc(firestore, "haskoyUyeler", huyeId.value);

        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          await deleteDoc(docRef)
            .then(() => {
              console.log("silindi");
            })
            .catch((error) => {
              errorCode = error.code;
              errorMes = error.message;
              console.log(errorMes);
            });
          emit("close");
          if (errorCode !== "") {
            router.push({ name: "MemberAddRemove" });
          }
        }
      }
    };
    return { close, handleDelete, huyeId, router, errorCode, errorMes };
  },
};
</script>

<style scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}

.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}

.modal-animation-inner-leave-to {
  transform: scale(0.8);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
  .modal-inner {
    position: relative;
    max-width: 640px;
    width: 80%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    padding: 64px 16px;
  }
    i {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
      cursor: pointer;
    }
      i:hover {
        color: crimson;
      }
    

    button {
      padding: 20px 30px;
      border: none;
      font-size: 16px;
      background-color: crimson;
      color: #fff;
      cursor: pointer;
      margin-right: 10px;
    }

</style>
