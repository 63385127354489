import { ref } from "vue";
import {auth} from '../firebase/config'
import {signInWithEmailAndPassword} from 'firebase/auth'

const hataLogin=ref(null)

const login=async (email,password)=>{
    hataLogin.value=null;

    try {
        const res=signInWithEmailAndPassword(auth,email,password);
        hataLogin.value=null;
        return res
    } catch (error) {
        hataLogin.value=error.message;
    }

  
}


const useLogin=()=>{

    return {hataLogin,login}
}
export default useLogin;
