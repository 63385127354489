<template>
    <Navbar />
    <ScrollButton/>
    <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <i class="fa fa-home"></i>
              <p>S.S. Hasköy</p>
              <h4>Kısaca Biz</h4>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->

    <div class="body-inner">
      <section id="main-container" class="main-container">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-xs-4">
              <h3 class="column-title">Kısaca Biz</h3>
              <p>
                Bölgedeki büyük sanayi kuruluşlarına yakın olması sebebi nedeniyle onların yan sanayi 
                ihtiyaçlarını anında karşılayabilen sanayi sitesinin ismi, OSB'lerin yoğunluğu nedeniyle fazla ön plana çıkmazken,
                esas itibariyle tam anlamıyla onların adını duyuran
                 gizli bir kahraman olarak bölgedeki sanayiciler arasında kulaktan kulağa yayılan yayılıyoruz.</p>
                 
                 <p>Haköy Sanayi Sitesi'nde yer alan firmalar, ürettikleri ürünleri pazarlamalarının yanı sıra, 
                  büyük firmalar için yaptıkları ürünlerle de tedarikçilik anlamında onların çok önemli eksikliklerini kapatıyor.</p>
                <p> S.S Hasköy Pik dökümcü ve işleme sanatkarları küçük sanayi sitesi işletme kooperatifi</p>
            
              
              <blockquote>
                <p>
                Bölge firmalarının yan sanayi ihtiyacını karşılayan "KÜÇÜK DEV"
                </p>
              </blockquote>
            </div>
            <!-- Col end -->
  
            <div class="col-lg-6 col-md-6 col-xs-4 mt-5 mt-lg-0">
              <div class="work-img-wrap" >
                <div class="image-one text-end wow fadeInUp" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
                  <img src="images/slider/cark-bg.jpg" />
                </div>
               <div class="image-two wow fadeInUp" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
                <img src="images/slider/cark-bg2.jpg" />
              </div>
             
              </div>
            </div>
          </div>
          <!-- Content row end -->
        </div>
        <!-- Container end -->
      </section>
      <!-- Main container end -->
    </div>
    <!-- Body inner end -->
    <Footer />
  </template>
  
  <script>
  import Footer from "@/components/Footer.vue";
  import Navbar from "@/components/Navbar.vue";
  import SiderBar from "@/components/SiderBar.vue";
  import ScrollButton from "@/components/ScrollButton.vue";
  
  export default {
    components: {
      Footer,
      Navbar,
      SiderBar,
      ScrollButton
    },
  };
  </script>
  
  <style scoped>
.work-img-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work-img-wrap {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .work-img-wrap {
    display: none;

  }
}
.work-img-wrap .image-one img {
  max-width: calc(100% - 130px);
}
.work-img-wrap .image-two {
  position: absolute;
  top: 35%;
  left: 0;
}
@media (max-width: 767px) {
  .work-img-wrap .image-two {
    bottom: -40px;
  }

}
.work-img-wrap .image-two img {
  max-width: calc(100% - 60px);
}
  </style>