<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="fileHata">{{ fileHata }}</div>
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Yeni Firma Ekle</h2>
          <p class="lead">
            <router-link to="/admin/company-list"
              >Firmaların Listesi içn Tıklayınız</router-link
            >
          </p>
        </div>
      </div>

      <div class="row g-5">
        <div class="col-md-8 col-lg-8">
          <h4 class="mb-3">Firma Bilgileri</h4>
          <div class="d-flex justify-content-between"></div>
          <form class="needs-validation" @submit.prevent="handleSubmit">
            <div class="row g-3">
              <div class="col-sm-6">
                <label for="firmaAd" class="form-label">Firmanın Adı</label>
                <input
                  type="text"
                  class="form-control"
                  id="firmaAd"
                  placeholder=""
                  v-model="firmaAd"
                />
              </div>

              <div class="col-sm-6">
                <label for="blokNo" class="form-label">Blok No</label>
                <input
                  type="text"
                  class="form-control"
                  id="blokNo"
                  placeholder=""
                  v-model="blokNo"
                />
              </div>

              <div class="col-6">
                <label for="isyeriNo" class="form-label">İsyeri No</label>
                <input
                  type="text"
                  class="form-control"
                  id="isyeriNo"
                  placeholder=""
                  v-model="isyeriNo"
                />
              </div>

              <div class="col-6">
                <label for="telNo" class="form-label">Telefon No</label>
                <input
                  type="text"
                  class="form-control"
                  id="telNo"
                  placeholder=""
                  v-model="telNo"
                />
              </div>
              <div class="col-6">
                <label for="email" class="form-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="abc@abc.com"
                  v-model="email"
                />
              </div>
              <div class="col-6">
                <label for="uretimTur" class="form-label">Üretim Türü</label>
                <input
                  type="text"
                  class="form-control"
                  id="uretimTur"
                  placeholder="Dökümcü,Tornacı vs."
                  v-model="uretimTur"
                />
              </div>
              <div class="col-6">
                Durum
                <label for="aktif" class="form-label">Durum</label>
                <div class="col-3">
                  <input
                    type="radio"
                    id="aktif"
                    name="durum"
                    :value="true"
                    v-model="durum"
                  />
                  <label for="aktif" class="form-label">Aktif</label>
                </div>
                <div class="col-3">
                  <input
                    type="radio"
                    id="pasif"
                    name="durum"
                    :value="false"
                    v-model="durum"
                  />
                  <label for="pasif" class="form-label">Pasif</label>
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <button class="w-100 btn btn-success btn-lg mb-4" type="submit">
              Firma Ekle
            </button>
          </form>
        </div>
      </div>
    </main>
  </div>
  <AdminFooter />
</template>
    
    <script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import {
  doc,
  collection,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import useStorage from "../../../composables/useStorage";
import useCollection from "../../../composables/useCollection";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { notify } from "@kyvg/vue3-notification";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import { useRouter } from "vue-router";

export default {
  components: {
    Loading,
    AdminFooter,
    AdminNavbar,
    VueFinalModal,
    ModalsContainer,
  },
  setup() {
    const showModal = ref(false);
    const isLoading = ref(true);
    // const fId = ref(route.params.userId);

    const olusturulmaTarihi = ref("");
    const firmaAd = ref("");
    const blokNo = ref("");
    const isyeriNo = ref("");
    const telNo = ref("");
    const email = ref("");
    const durum = ref(true);
    const uretimTur = ref("");

    const file = ref(null);
    const fileHata = ref(null);
    const hataMesaj = ref("");

    const router = useRouter();

    const { hataFirestore, yeniBelgeEkle, id } = useCollection("firmalar");

    moment.locale("tr");

    onMounted(async () => {
      isLoading.value = false;
    });

    const handleSubmit = async () => {
      isLoading.value = true;
      const myDocumentData = {
        firmaninAdi: firmaAd.value,
        blokNo: blokNo.value,
        isyeriNo: isyeriNo.value,
        telNo: telNo.value,
        email: email.value,
        uretimTur: uretimTur.value,
        durum: durum.value === true ? true : false,
        olusturulmaTarihi: serverTimestamp(),
      };

      console.log(myDocumentData);
      await yeniBelgeEkle(myDocumentData);

      if (!hataFirestore.value) {
        router.push("/admin/company-list");
      } else {
        hataMesaj.value = hataFirestore.value;
        isLoading.value = false;
        showModal.value = true;
      }
    };

    return {
      olusturulmaTarihi,
      firmaAd,
      blokNo,
      isyeriNo,
      telNo,
      email,
      isLoading,
      showModal,
      handleSubmit,
      file,
      fileHata,
      hataMesaj,
      durum,
      uretimTur,
    };
  },
};
</script>
    
    <style>
</style>
    
    
    