<template>
  <Navbar/>
  <ScrollButton/>
  <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <h1 class="banner-title">Yönetim</h1>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb justify-content-center">
                  <li class="breadcrumb-item"><router-link to="/home">Anasayfa</router-link></li>
                  <li class="breadcrumb-item"><router-link to="/director">Yönetim</router-link></li>
                </ol>
              </nav>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->


      <div class="row justify-content-center">
        <div class="col-lg-2 col-md-4 col-sm-6 mb-5">
          <div class="ts-team-wrapper">
            <div class="team-img-wrapper">
              <img
                loading="lazy"
                src="images/team/team3.jpg"
                class="img-fluid"
                alt="team-img"
              />
            </div>
            <div class="ts-team-content-classic">
              <h3 class="ts-name">Mark Conter</h3>
            </div>
          </div>
          <!--/ Team wrapper 3 end -->
        </div>
        <!-- Col end -->

        <div class="col-lg-2 col-md-4 col-sm-6 mb-5">
          <div class="ts-team-wrapper">
            <div class="team-img-wrapper">
              <img
                loading="lazy"
                src="images/team/team3.jpg"
                class="img-fluid"
                alt="team-img"
              />
            </div>
            <div class="ts-team-content-classic">
              <h3 class="ts-name">Mark Conter</h3>
            </div>
          </div>
          <!--/ Team wrapper 3 end -->
        </div>
        <!-- Col end -->

        <div class="col-lg-2 col-md-4 col-sm-6 mb-5">
          <div class="ts-team-wrapper">
            <div class="team-img-wrapper">
              <img
                loading="lazy"
                src="images/team/team3.jpg"
                class="img-fluid"
                alt="team-img"
              />
            </div>
            <div class="ts-team-content-classic">
              <h3 class="ts-name">Mark Conter</h3>
            </div>
          </div>
          <!--/ Team wrapper 3 end -->
        </div>
        <!-- Col end -->
        <div class="col-lg-2 col-md-4 col-sm-6 mb-5">
          <div class="ts-team-wrapper">
            <div class="team-img-wrapper">
              <img
                loading="lazy"
                src="images/team/team3.jpg"
                class="img-fluid"
                alt="team-img"
              />
            </div>
            <div class="ts-team-content-classic">
              <h3 class="ts-name">Mark Conter</h3>
            </div>
          </div>
          <!--/ Team wrapper 3 end -->
        </div>
        <!-- Col end -->
        <div class="col-lg-2 col-md-4 col-sm-6 mb-5">
          <div class="ts-team-wrapper">
            <div class="team-img-wrapper">
              <img
                loading="lazy"
                src="images/team/team3.jpg"
                class="img-fluid"
                alt="team-img"
              />
            </div>
            <div class="ts-team-content-classic">
              <h3 class="ts-name">Mark Conter</h3>
            </div>
          </div>
          <!--/ Team wrapper 3 end -->
        </div>
        <!-- Col end -->
      </div>

  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import TabNav from "@/components/TabNav.vue";
import Tab from "@/components/Tab.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  data() {
    return {
      selected: "Yönetim Kurulu",
    };
  },
  methods: {
    setSelected(tab) {
      this.selected = tab;
    },
  },
  components: {
    Footer,
    TabNav,
    Tab,
    Navbar,
    ScrollButton
  },
};
</script>

<style scoped>
.nav-bar{
  border-bottom: 3px solid gray;
}
.nav-bar a.active{
  background: gray;
}
</style>