<template>

    <div class="navbar-language">
      <button @click="changeLanguage($event)" value="en">EN </button>
    |
      <button @click="changeLanguage($event)" value="tr">TR</button>
     
    </div>

</template>

<script>
export default {
  setup() {
    const changeLanguage = (event) => {
      console.log(event.target.innerText);
    };
    return { changeLanguage };
  },
};
</script>

<style scoped>
.navbar-language {
 display: flex;
 float: left;
}

.navbar-language button {
  background-color: transparent;
  border: none;
  color: #999; /* Buton rengini isteğinize göre ayarlayabilirsiniz */
  font-size: 16px; /* Buton yazı boyutunu isteğinize göre ayarlayabilirsiniz */
  cursor: pointer;
}

.navbar-language button:hover {
  text-decoration: underline;
}
</style>