<template>
  <Navbar />
  <ScrollButton/>
  <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <i class="fa fa-home"></i>
              <p>S.S. Hasköy</p>
              <h4>Kısaca Biz</h4>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->
  <div class="body-inner">
    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <h3 class="column-title">Her yönüyle sanayi kenti; KOCAELİ</h3>
            <p>
              Küçük bir il olmasına rağmen konumu ve altyapı olanaklarıyla
              sanayinin önde gelen bir şehri olan Kocaeli’nin Türkiye imalat
              sanayinde aldığı pay yıllardır yüzde 13 seviyesini korumaktadır.
              Doğal bir liman özelliği taşıyan İzmit Körfezi içinde barındırdığı
              35 liman ve iskele ; ilimizi sanayinin yanı sıra aynı zamanda bir
              liman kenti yapmaktadır. Yatırımcıların tercih ettiği bir bölge
              olan Kocaeli, sanayi kenti olmanın gereklerini karşılayan bir il.
            </p>
            <p>
              En önemli özelliği ilimizin Avrupa’yı Anadolu’ya bağlayan geçiş
              yolu üzerinde konumlanmış olmasıdır.
            </p>
            <p>
              Körfez geçiş köprüsü ile üçüncü otoyol ve bağlantıları
              tamamlandığında ilin lojistik üstünlüğü daha da artacaktır.
            </p>
            <p>
              Kocaeli aynı zamanda, sanayicinin ihtiyaç duyduğu gelişmiş
              nitelikli insan kaynağını barındırmaktadır. Özellikle sanayinin
              ihtiyaç duyduğu ara elemanları yetiştiren meslek liseleri ve
              meslek yüksek okulları ilimizde yoğun olarak bulunuyor. Bu konuda
              geliştirilen projeleri de en başarılı uygulayan ildir.
            </p>
            <p>
              Çalışma Bakanlığı, Milli Eğitim Bakanlığı ve Türkiye Odalar ve
              Borsalar Birliği tarafından uygulana Uzmanlaşmış Meslek Edindirme
              Projesinde il düzeyinde en çok istihdam sağlayan il Kocaeli
              olmuştur. Kocaeli düzeyinde Başkanlığını Odamızın yürüttüğü bu
              proje sanayicilerimiz tarafından benimsenmiştir ve işsizliği
              azaltılmasına ciddi katkı vermektedir.
            </p>
            <p>
              Sanayide dışa bağımlılığımızı azaltmanın yolunun, kendi
              teknolojimizi üretmekten geçtiğini düşünüyoruz. Sanayinin kendi
              teknolojisini üretebilmesi için de bu teknolojiyi üretebilecek
              bilgiye kolayca ulaşabilmesi gerekmektedir. Bunun ancak bilim,
              emek, sermayenin birleşmesiyle ve bunu sağlayacak olan güçlü bir
              üniversite-sanayi işbirliği ile mümkün olduğuna inanıyoruz.
              Kocaeli Sanayi Odası olarak bu konuda sanayicimiz ve üniversiteler
              arası arayüz olma gayreti içerisindeyiz. En önemlisi gerek mevcut
              sanayi kuruluşlarımız için gerekse yeni yatırımcılar için bu
              imkanların ilimizde mevcut olmasıdır. İlimiz üniversiteleri,
              araştırma merkezleri, teknoparkları ile Ar-Ge çalışmalarının
              yapılabileceği bir alt yapıya sahiptir.
            </p>
            <p>
              Sonuç olarak; tüm bu özellikler Kocaeli’ni sanayi kuruluşlarının
              rahat rekabet edebildikleri bir konuma taşımaktadır.
            </p>
            <p>
              Kısaca bölgenin sanayi yapısından bahsetmek gerekiyorsa;
              Kocaeli’nde kimya ve petro- kimya sanayi ile otomotiv sanayinin
              öne çıktığını görüyoruz. Her iki sektör de Türkiye’nin hemen hemen
              üçte birini temsil ediyor. Ayrıca, metal sanayi, makine sanayi,
              plastik sanayi de ilimizin öne çıkan diğer sektörleri.
            </p>
            <p>
              Bugün itibariyle odamıza kayıtlı firma sayısı 2 bin 500
              civarındadır. Bu işletmelerimizin 259’u yabancı sermayelidir ve
              87’si Türkiye’nin en büyük 500 firması içerisinde yer almaktadır.
            </p>

            <p>
              Bölgemizde sanayinin gelişimi ve bölgede yer alan teknoparklarla
              yazılım sektöründe de önemli bir gelişim yaşanmaktadır. Bölgedeki
              teknoparklarda 2 binin üzerinde yazılım firması faaliyet
              göstermektedir. Bu gelişimin Muallimköy’de kurulacak olan Bilişim
              Vadisi ile daha da ileri noktalarca taşınacağını umut ediyoruz.
            </p>
            <p>
              Kocaeli dış ticaret rakamlarına gelince, ilin dış ticaretini,
              Kocaeli gümrüklerinden takip ediyoruz. Kocaeli 2014 yılında
              Türkiye dış ticaretinin yüzde 17,1’ini gerçekleştirmiştir.
              İlimizin Türkiye ihracatındaki payı da bu doğrultuda artmıştır.
              Böylece, ihracattaki payımız yüzde 12,1’e yükselmiştir.
            </p>

            <p>
              Kocaeli, İstanbul’dan ve İzmir’den sonra toplana vergilerin yüzde
              11,2’sini karşılayan üçüncü ildir. Maliye Bakanlığı’nın verilerine
              göre Kocaeli, 2014 yılında 39,5 milyar TL vergi ödedi. İlimiz 2014
              yılında da kişi başına ödediğimiz 22 bin 991 TL ile Türkiye
              genelinde açık ara en fazla vergiyi ödemiştir.
            </p>

            <p>
              Önümüzde Hükümetimizin açıkladığı 500 milyar dolar 2023 yılı
              ihracat hedefi bulunmaktadır. Kocaeli 500 milyar dolarlık hedefe
              en büyük katkıyı verecek illerden biri olacaktır.
            </p>
            <p>
              Kocaeli sanayinin temsilcisi olan Kocaeli Sanayi Odası olarak biz
              de bu hedefe odaklandık. Bu kapsamda bölgesel kalkınmayı
              sağlayacak projeler geliştiriyoruz. İşbirlikleri kuruyoruz.
              Bölgemizin ticaret hacmini artıracak faaliyetler yürütüyoruz.
            </p>
            <p>
              Geçen yıl ikincisini gerçekleştirdiğimiz Doğu Marmara Sanayi ve
              Teknoloji Fuarı da sadece İlimizde değil Doğu Marmara Bölgesindeki
              tüm sanayi kuruluşlarımızın ticaretine bir canlılık getirmektedir.
            </p>
            <p>
              Özellikle Doğu Marmara sanayinde yer alan işletmelerimizin
              ihtiyacı olan bu fuar ile firmalarımızın birbirini daha yakından
              tanıma fırsatı yakalayabileceklerine ve bir çok işbirliği
              olanaklarının doğacağına inanıyoruz. En büyük arzumuz SANTEK’in
              marka değerinin artması ve sürdürülebilir olmasıdır. Aynı zamanda;
              “Doğu Marmara sanayi ve Teknoloji Fuarı” ile eşzamanlı
              gerçekleştirilen ve ikili iş görüşmelerinin yapıldığı
              Match4Industry etkinliği bölgemizin ve firmalarımızın kalkınması
              ve tanıtımı için büyük önem arz etmekte olup fuarımıza
              uluslararası bir nitelik de kazandırmaktadır.
            </p>
            <p>
              Kocaeli sanayi odası olarak bölgemizde bulunan bir çok OSB’nin,
              serbest bölgelerin ve 3 adet teknoparkın kurucu ortağıyız.
              Bölge’de 7’si aktif 13 OSB, 3 Teknopark, 2 Serbest Bölge
              bulunmaktadır.
            </p>
            <p>
              Bununla birlikte marka değeri giderek artan iki adet ödül
              organizasyonumuz var Kocaeli Sanayi Odası olarak çevrenin ve
              doğanın korunmasını, iyileştirilmesini yasal yükümlülüklerin
              ötesinde bir görev olarak kabul eden sanayi kuruluşlarının
              ödüllendirilmesi amacıyla “Şehabettin Bilgisu Çevre Ödülleri”
              yarışmasını düzenledik. Bu yıl 21.sini gerçekleştirdik.
            </p>
            <p>
              2008 yılında başlattığımız ve bu yıl sekizincisini
              gerçekleştireceğimiz “Sektörel Performans Değerlendirme” ödül
              törenini Doğu Marmara Bölgesi’ndeki başarılı sanayi
              kuruluşlarımızı ödüllendirmek amacıyla düzenliyoruz. Ödül Törenini
              TOOB Yönetim Kurulu Başkanı Rıfat Hisarcıklıoğlu’nun
              katılımlarıyla gerçekleştirildi.
            </p>
          </div>
        </div>
        <!-- Content row end -->
      </div>
      <!-- Container end -->
    </section>
    <!-- Main container end -->
  </div>
  <!-- Body inner end -->
  <Footer />
</template>
  
  <script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Footer,
    Navbar,
    ScrollButton
  },
};
</script>
  
  <style scoped>
.mission-img {
  display: block;
  position: absolute;
  left: 20%;
  width: 500px;
  max-height: 100%;
}

.work-img-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work-img-wrap {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .work-img-wrap {
    margin-top: 60px;
  }
}
.work-img-wrap .image-one img {
  max-width: calc(100% - 150px);
}
.work-img-wrap .image-two {
  position: absolute;
  top: 35%;
  left: 0;
}
@media (max-width: 767px) {
  .work-img-wrap .image-two {
    bottom: -40px;
  }
}
.work-img-wrap .image-two img {
  max-width: calc(100% - 60px);
}
</style>