<template>
  <Navbar />
  <ScrollButton />
  <loading
    v-mode:active="isLoading"
    :is-full-page="true"
    color="#ffc300"
  ></loading>
  <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <i class="fa fa-home"></i>
              <p>S.S. Hasköy</p>
              <h4>Üye Listesi</h4>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->
  <section class="our-team-area">
    <div class="container">
      <div class="header-new">
        <div class="sec-title">
          <h1>Hasköy Sanayi Üye Listesi</h1>
          <input
            class="search-input"
            type="text"
            v-model="searchQuery"
            @input="search"
            placeholder="Arama yapın..."
          />
        </div>
      </div>
      <div class="row mb-1 pl-4">
        <div class="col-md-3"><p class="fw-bold">Üye İsim Soyisim</p></div>
      </div>
      <div class="mb-5" v-if="displayedMember.length !== 0">
        <ol class="list-group list-group-numbered">
          <li
            class="list-group-item d-flex align-items-start"
            v-for="h in displayedMember"
            :key="h.id"
          >
            <div class="col-md-10 fw-bold">{{ h.isim }} {{ h.soyisim }}</div>
          </li>
        </ol>
      </div>
      <div
        class="row justify-content-md-center"
        v-if="displayedMember.length == 0"
      >
        <h3>{{ bosMesajı }}</h3>
      </div>
      <div class="pagination">
        <button @click="prevPage"  v-if="currentPage > 1"><i class="bi bi-chevron-double-left"></i></button>
        <button
          v-for="pageNumber in visiblePages"
          :key="pageNumber"
          @click="goToPage(pageNumber)"
          :class="{ active: currentPage === pageNumber }"
        >
          {{ pageNumber }}
        </button>
        <span v-if="totalPages > 5 && currentPage < totalPages - 2">...</span>
        <button @click="nextPage" v-if="currentPage < totalPages "><i class="bi bi-chevron-double-right"></i></button>
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { auth, firestore } from "@/firebase/config";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Loading,
    Footer,
    Navbar,
    ScrollButton,
  },
  setup() {
    const haskoyUyeler = ref([]);
    const isLoading = ref(true);
    const deleteDocId = ref("");
    const deleteFunc = ref(null);

    const bosMesajı = ref("Üye Bulunamadı");

    const huyeId = ref("");
    const searchQuery = ref("");

    const currentPage = ref(1);
    const pageSize = 20; // Sayfa başına öğe sayısı
    const totalItems = ref(null); // Toplam öğe sayısı

    const totalPages = computed(() =>
     Math.ceil(totalItems.value / pageSize)
     );

    const displayedMember = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      return haskoyUyeler.value.slice(startIndex, endIndex);
    });
    // Sayfa değiştirme işlevi
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        // Sayfaya göre verileri güncelleme işlemleri burada yapılabilir
      }
    };
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        goToPage(currentPage.value);
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        goToPage(currentPage.value);
      }
    };

    const visiblePages = computed(() => {
      if (totalPages.value <= 5) {
        return Array.from({ length: totalPages.value }, (_, i) => i + 1);
      } else {
        const startPage = Math.max(currentPage.value - 2, 1);
        const endPage = Math.min(startPage + 4, totalPages.value);
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
      }
    });

    const search = async () => {
      console.log("Arama:", searchQuery.value);

      // Firestore'dan tüm verileri getirme
      const q = query(
        collection(firestore, "haskoyUyeler"),
        where("durum", "==", true),
        orderBy("olusturulmaTarihi", "desc")
      );

      const querySnapshot = await getDocs(q);
      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      // Arama sorgusuyla verileri filtreleme
      const filteredData = veri.filter((item) => {
        return item.isim
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase());
      });

      haskoyUyeler.value = filteredData;
      
    };
    onMounted(async () => {
      let q = query(
        collection(firestore, "haskoyUyeler"),
        where("durum", "==", true),
        orderBy("olusturulmaTarihi", "desc")
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      haskoyUyeler.value = veri;
      isLoading.value = false;
      totalItems.value = veri.length;
    });
    return {
      haskoyUyeler,
      isLoading,
      bosMesajı,
      deleteDocId,
      deleteFunc,
      huyeId,
      searchQuery,
      search,
      currentPage,
      totalPages,
      goToPage,
      displayedMember,
      prevPage,
      nextPage,
      visiblePages
    };
  },
};
</script>
  
  <style scoped>
.alert-dark {
  font-family: "Open Sans", sans-serif;
}
</style>