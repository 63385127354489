import { ref } from "vue";
import { storage } from "@/firebase/config";
import { getStorage,ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";


const hataStorage = ref(null)
const getstorage =getStorage();
const useStorage = () => {
    hataStorage.value = null

    const url = ref(null)
    const urlArray = ref([])
    const fileYol = ref(null)

    const resimYukle = async (file, uId) => {
        try {
            fileYol.value = 'resimler/' + uId;
            const imageRef = storageRef(storage, fileYol.value)

            await uploadBytes(imageRef, file)
            url.value = await getDownloadURL(storageRef(storage, fileYol.value))

        } catch (error) {
            hataStorage.value = error.message
        }
    }

    const resimArrYukle = async (secilenDosyalar) => {
        try {
            for (let i = 0; i < secilenDosyalar.value.length; i++) {
                const dosya = secilenDosyalar.value[i];
                const storageDosyaYolu = `dosyalar/${dosya.name}`;
                const imageRef = storageRef(storage, storageDosyaYolu);
                await uploadBytes(imageRef, dosya);
                urlArray.value.push(await getDownloadURL(storageRef(storage,storageDosyaYolu)))
                console.log(`${dosya.name} Firestore'a yüklendi.`);
            }

        } catch (error) {
            hataStorage.value = error.message
        }
    }
    return { resimYukle, resimArrYukle, url, urlArray, fileYol, hataStorage }

}
export default useStorage