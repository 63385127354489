<template>
  <div class="feature_wrap" style="background-image: url(/images/slider/feature-bg.jpg)">
    <div
      class="feature_item slick-initialized slick-slider"
      data-background="/images/slider/feature-bg.jpg"
    >
    <h5 class="h5">
            <marquee  scrollamount="20" direction=left>Bölge firmaların yan sanayi ihtiyacını karşılayan “Küçük dev”</marquee>
        </h5>
    
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.feature_wrap {
  position: relative;
  z-index: 1;
	background-attachment:fixed;
	background-position:center center;
	background-repeat:no-repeat;
	background-size:cover;
}
.feature_wrap:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #171717;
  opacity: 0.2;
}
.feature_item {
  display: flex;
  align-items: center;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature_item {
    height: 200px;
  }
}
@media (max-width: 767px) {
  .feature_item {
    height: 150px;
  }
}
.h5{
    font-size:80px;
    line-height: 100px;
}
.feature_item h5 marquee{
  color: #fff;
  font-size: 90px;
  font-weight: 500;
  margin-right: 60px;
  margin-left: 60px;
  color: transparent;
  -webkit-text-stroke: 0.8px #fffbfb;
  -webkit-text-stroke-width: 1px;
  letter-spacing: -2px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature_item h5 marquee{
    font-size: 80px;
  }
  .h5{
    font-size:90px;
    line-height: 80px;
  }
}
@media (max-width: 767px) {
  .feature_item h5 marquee{
    font-size: 60px;
  }
  .h5{
    font-size:70px;
    line-height: 60px;
  }
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;
  }

 
}
</style>