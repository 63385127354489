<template>
<div class="table-responsive">
  <table class="table table-hover">
    <thead>
      <tr>
        <th v-for="(title, i) in columns" :key="i">{{ title.label }}</th>
        <th v-if="actionsButtonsVisible">İşlemler</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, i) in rows" :key="i">
        <td v-for="(column, i) in columns" :key="i">
          <p>{{ row[column.value] }}</p>
        </td>
        <td>
          <div class=" min-width">
            <button
              class="btn btn-sm btn-outline-danger"
              v-if="deleteButtonVisible"
              @click="deleteButtonClick(row, i)"
            >
              Sil
            </button>
            <button
              class="ml-2 btn btn-sm btn-outline-warning"
              @click="updateButtonClick(row, i)"
              v-if="updateButtonVisible"
            >
              Güncelle
            </button>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <p class="mt-2">
        Toplam Kayıt Sayısı :
        {{ rows.length > 0 ? rows.length : "Kayıt Bulunamadı" }}
      </p>
    </tfoot>
  </table>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    deleteButtonVisible: {
      type: Boolean,
      required: false,
    },
    actionsButtonsVisible: {
      type: Boolean,
      required: false,
    },
    updateButtonVisible: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    deleteButtonClick(item, index) {
      this.$emit("deleteButtonClick", { item, index });
    },
    updateButtonClick(item,index){
        this.$emit('updateButtonClick',{item,index})
    }
  },
};
</script>

<style scoped>
.min-width{
    min-width: 130px;
}
</style>