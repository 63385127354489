<template>
  <Navbar />
  <ScrollButton/>
  <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <i class="fa fa-home"></i>
              <p>S.S. Hasköy</p>
              <h4>Kısaca Biz</h4>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->
  <div class="body-inner">
    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-lg-10">
            <h3 class="column-title">Bölge firmalarının yan sanayi ihtiyacını karşılayan <b>KÜÇÜK DEV</b></h3>
            <p>
              Hava, kara ve deniz yollarına yakınlığı nedeniyle coğrafi açıdan
              çok önemli bir avantaja sahip olan Gebze Bölgesi yıllardan beri
              Türk sanayisine çok önemli faydalar sağlıyor.
            </p>
            <p>
              İstanbul Büyükşehir Belediyesi tarafından ‘’Haliç İmar Projesi
              Uygulaması’’ nedeniyle Hasköy’deki torna-döküm ve pres
              imalatçılarının işyerlerinin istimlak edilmesi üzerine, 1985
              yılında esnaf ve sanatkarların kooperatifleşmesi düşüncesi ile
              kurulan ve 1990 yılında inşaat çalışmalarına başlanan Hasköy
              Sanayi Sitesi, yaklaşık 20 yıllık bir süreden beri faaliyetlerine
              devam ediyor.
            </p>
            <p>
              E-6 diye bilinen otoyolun Gebze gişelerine sınır olan sanayi
              sitesinde, günümüz itibariyle 15 blokta yer alan 350 firma,
              üretimi, satış ve pazarlama faaliyetleri ile bölgede büyük bir
              sanayi merkezi olarak adından söz ettiriyor.
            </p>
            <p>
              Bünyesinde faaliyet gösteren 350 firmayla yaklaşık 5 bin kişiye
              istihdam sağlayan Hasköy Sanayi Sitesi, bölgedeki OSB’lerin
              arasından işlevselliği ile sıyrılıyor.
            </p>
            <p>
              Altyapı anlamında hiçbir eksiği bulunmayan Hasköy Sanayi Sitesi,
              Gebze bölgesinde, Türkiye’nin önemli firmalarının yan sanayi
              ihtiyaçlarını anında karşılayabiliyor.
            </p>
            <p>
              Toplam 10 OSB’yi sınırları içinde barındıran bölgede yer alan
              Hasköy Sanayi Sitesi Çayırova, Darıca, Gebze ve Dilovası’ndaki
              büyük kuruluşlara verdiği yan sanayi hizmetiyle bölgede önemli bir
              açığı kapatıyor.
            </p>
            <p>
              Bölgedeki büyük sanayi kuruluşlarına yakın olması nedeniyle
              onların yan sanayi ihtiyaçlarını anında karşılayabilen sanayi
              sitesinin ismi, OSB’lerin yoğunluğu nedeniyle fazla ön plana
              çıkmazken, esas itibariyle tam anlamıyla onların adını duyuran
              gizli bir kahraman olarak bölgedeki sanayiciler arasında kulaktan
              kulağa yayılıyor.
            </p>
            <p>
              Hasköy Sanayi Sitesi’nde yer alan firmalar, ürettikleri ürünleri
              pazarlamalarının yanı sıra, büyük firmalar için yaptıkları
              ürünlerle de tedarikçilik anlamında onların çok önemli
              eksikliklerini kapatıyor.
            </p>
            <p>
              Üyeler arasında döküm, makine, pres, kalıp, torna gibi birbirinden
              farklı sektörlere yönelik çalışan firmalar mevcut. Sanayi
              sitesinde 42 tanesi 432, 68 tanesi 288, 100 tanesi 216 ve 154
              tanesi de 108 metrekare alanlı toplam 364 tane atölyenin yer
              aldığını belirtmeliyim.
            </p>
            <p>
              Birim olarak 753 işyerinin yer aldığı sitede yaklaşık olarak 5 bin
              kişi istihdam ediliyor. Yaklaşık 25 kişilik personeliyle Hasköy
              Sanayi Sitesi’nin idari, sosyal ve güvenlik gibi işlerini
              yürütüyoruz. Yönetim bölümünün yer aldığı idari bina kısmında ise
              23 iş yeri bulunuyor.
            </p>
            <p>
              Hasköy Sanayi Sitesi’nin bölgede tutulmasının sebepleri sıralamak
              gerekir. Bölgedeki diğer sanayi sitelerine göre biz daha derli
              toplu bir şekilde faaliyetlerimizi sürdürüyoruz.
            </p>
            <p>
              Öncelikle yönetim olarak sanayi sitesindeki alt yapı işlerini tam
              anlamıyla bitirdik. Üyelerimizin tamamı elektrik, enerji ve su
              gibi altyapı ihtiyaçlarının hepsini kullanabiliyor. Kısacası
              Hasköy Sanayi Sitesi’nin bölgedeki diğer sanayi sitelerine göre
              alt yapı anlamında hiçbir eksiği bulunmuyor.
            </p>
            <p>
              Bölgede yanıcı, kokucu, patlayıcı ve kimyasal ürünler alanında
              faaliyet gösteren firmalar haricindeki her sektörden firma yer
              alabilir. Sitelerimizde aynı sektörde yer alan firmaların sayısı
              oldukça fazla. Bu sebeple firmalarımız birbiriyle rahatça iletişim
              kurabiliyor. Bu anlamda aynı sektörde yer alan firmalar,
              birbirlerinin sorunlarını da daha yakından takip edebiliyor.
            </p>
            <p>
              Üyelerimizin sorunları olduğunda onların yanında oluyor ve beraber
              yeni çözüm yolları üretiyoruz.
            </p>
          </div>
        </div>
        <!-- Content row end -->
      </div>
      <!-- Container end -->
    </section>
    <!-- Main container end -->
  </div>
  <!-- Body inner end -->
  <Footer />
</template>
  
  <script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Footer,
    Navbar,
    ScrollButton
  },
};
</script>
  
  <style scoped>
.mission-img {
  display: block;
  position: absolute;
  left: 20%;
  width: 500px;
  max-height: 100%;
}

.work-img-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work-img-wrap {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .work-img-wrap {
    margin-top: 60px;
  }
}
.work-img-wrap .image-one img {
  max-width: calc(100% - 150px);
}
.work-img-wrap .image-two {
  position: absolute;
  top: 35%;
  left: 0;
}
@media (max-width: 767px) {
  .work-img-wrap .image-two {
    bottom: -40px;
  }
}
.work-img-wrap .image-two img {
  max-width: calc(100% - 60px);
}
</style>