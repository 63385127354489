import { createRouter, createWebHashHistory } from 'vue-router'

import { auth, firestore } from '@/firebase/config'
import { doc, getDoc } from 'firebase/firestore'


import HomeView from '../views/HomeView.vue'
import Dashboard from '../views/admin/Dashboard'
import Profile from '../views/admin/Profile'

import ChangeAuth from '../views/admin/ChangeAuth'
import Signup from '../views/admin/Signup'
import Login from '../views/admin/Login'

import NewUserList from '../views/admin/dashboard/NewUserList'
import ChangeUserStatus from '../views/admin/dashboard/ChangeUserStatus'
import UserDetail from '../views/admin/dashboard/UserDetail'

import CompanyListAdmin from '../views/admin/company/CompanyList'
import CompanyDetail from '../views/admin/company/CompanyDetail'
import AddNewCompany from '../views/admin/company/AddNewCompany'

import AddBoard from '../views/admin/board/AddBoard'
import BoardList from '../views/admin/board/BoardList'
import BoardDetail from '../views/admin/board/BoardDetail'

import AddAds from '../views/admin/ads/AddAds'
import AdsList from '../views/admin/ads/AdsList'
import AdsDetail from '../views/admin/ads/AdsDetail'

import MessageList from '../views/admin/message/MessageList'
import MessageDetail from '../views/admin/message/MessageDetail'

import MemberDetail from '../views/admin/member/MemberDetail'
import MemberListAdmin from '../views/admin/member/MemberList'
import AddMember from '../views/admin/member/AddMember'

import NewsList from '../views/admin/news/NewsList'
import AddNews from '../views/admin/news/AddNews'
import NewsDetail from '../views/admin/news/NewsDetail'

import NoticeList from '../views/admin/notice/NoticeList'
import AddNotice from '../views/admin/notice/AddNotice'
import NoticeDetail from '../views/admin/notice/NoticeDetail'


import CompanyList from '../views/frontend/CompanyList'
import MemberList from '../views/frontend/MemberList'

import NewsDetailById from '../views/frontend/NewsDetailById'

import Contact from '../views/frontend/Contact'
import Director from '../views/frontend/Director'
import HomePage from '../views/frontend/HomePage'

import About from '../views/frontend/About'
import MissionVision from '../views/frontend/MissionVision'
import History from '../views/frontend/History'

import SupervisorBoard from '../views/frontend/SupervisorBoard'
import FoundingBoard from '../views/frontend/FoundingBoard'
import DirectorBoard from '../views/frontend/DirectorBoard'

import ContentFirst from '../views/frontend/ContentFirst'
import ContentSecond from '../views/frontend/ContentSecond'
import ContentThird from '../views/frontend/ContentThird'
import ContentFourth from '../views/frontend/ContentFourth'

import Kvkk from '../views/frontend/Kvkk'

const adminKontrol = async (to, from, next) => {

  let kullanici = auth.currentUser;

  if (!kullanici) {
    next({ name: 'Login' })
  }
  else {
    const docRef = doc(firestore, 'kullanicilar', kullanici.uid)

    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      if (docSnap.data().yetki == 'admin') {
        next()
      }
      else {
        next({ name: 'Profile' })
      }
    }
    else {
      next({ name: 'Login' })
    }
  }

}

const uyeKontrol = async (to, from, next) => {

  let kullanici = auth.currentUser;

  if (!kullanici) {
    next({ name: 'Login' })
  }
  else {
    const docRef = doc(firestore, 'kullanicilar', kullanici.uid)

    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      if (docSnap.data().yetki == 'uye' || docSnap.data().yetki == 'admin') {
        next()
      }
      else {
        next({ name: 'Profile' })
      }
    }
    else {
      next({ name: 'Login' })
    }
  }
}

const basitKontrol = async (to, from, next) => {

  let kullanici = auth.currentUser;

  if (!kullanici) {
    next({ name: 'Login' })
  }
  else {
    const docRef = doc(firestore, 'kullanicilar', kullanici.uid)

    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      if (docSnap.data().yetki == 'basit' || docSnap.data().yetki == 'uye' || docSnap.data().yetki == 'admin') {
        next()
      }
      else {
        next({ name: 'Profile' })
      }
    }
    else {
      next({ name: 'Login' })
    }
  }

}

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/admin/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: basitKontrol
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: adminKontrol
  },
  {
    path: '/admin',
    name: 'admin',
    component: Dashboard,
    beforeEnter: adminKontrol
  },
  {
    path: '/admin/new-user-list',
    name: 'NewUserList',
    component: NewUserList,
    beforeEnter: adminKontrol
  },
  {
    path: '/admin/change-user-status',
    name: 'ChangeUserStatus',
    component: ChangeUserStatus,
    beforeEnter: adminKontrol
  },
  {
    path: '/admin/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/admin/change-auth',
    name: 'ChangeAuth',
    component: ChangeAuth,
    beforeEnter: basitKontrol
  },
  {
    path: '/admin/user-detail/:userId',
    name: 'UserDetail',
    component: UserDetail,
    beforeEnter: adminKontrol
  },
  {
    path: '/admin/company-list',
    name: 'CompanyListAdmin',
    component: CompanyListAdmin
  },
  {
    path: '/admin/company-detail/:firmId',
    name: 'CompanyDetail',
    component: CompanyDetail
  },
  {
    path: '/admin/add-new-company',
    name: 'AddNewCompany',
    component: AddNewCompany
  },
  {
    path: '/admin/member-list',
    name: 'MemberListAdmin',
    component: MemberListAdmin
  },
  {
    path: '/admin/member-detail/:uyeId',
    name: 'MemberDetail',
    component: MemberDetail
  },
  {
    path: '/admin/add-member',
    name: 'AddMember',
    component: AddMember
  },
  {
    path: '/admin/ads-list',
    name: 'AdsList',
    component: AdsList
  },
  {
    path: '/admin/add-ads',
    name: 'AddAds',
    component: AddAds
  },
  {
    path: '/admin/ads-detail/:reklamId',
    name: 'AdsDetail',
    component: AdsDetail
  },
  {
    path: '/admin/news-list',
    name: 'NewsList',
    component: NewsList
  },
  {
    path: '/admin/add-news',
    name: 'AddNews',
    component: AddNews
  },
  {
    path: '/admin/news-detail/:haberId',
    name: 'NewsDetail',
    component: NewsDetail
  },  
  {
    path: '/admin/notice-list',
    name: 'NoticeList',
    component: NoticeList
  },
  {
    path: '/admin/add-notice',
    name: 'AddNotice',
    component: AddNotice
  },
  {
    path: '/admin/notice-detail/:duyuruId',
    name: 'NoticeDetail',
    component: NoticeDetail
  }, 
  {
    path: '/admin/board-list',
    name: 'BoardList',
    component: BoardList
  },
  {
    path: '/admin/add-board',
    name: 'AddBoard',
    component: AddBoard
  },
  {
    path: '/admin/news-detail/:kurulId',
    name: 'BoardDetail',
    component: BoardDetail
  }, 
  {
    path: '/admin/message-list',
    name: 'MessageList',
    component: MessageList
  },
  {
    path: '/admin/message-detail/:mesajId',
    name: 'MessageDetail',
    component: MessageDetail
  },
  {
    path: '/company-list',
    name: 'CompanyList',
    component: CompanyList
  },
  {
    path: '/news-detail-by-id/:haberId',
    name: 'NewsDetailById',
    component: NewsDetailById
  },
  {
    path: '/member-list',
    name: 'MemberList',
    component: MemberList
  },
  {
    path: '/mission-vision',
    name: 'MissionVision',
    component: MissionVision
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/director',
    name: 'Director',
    component: Director
  },
  {
    path: '/home-page',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/supervisor-board',
    name: 'SupervisorBoard',
    component: SupervisorBoard
  },
  {
    path: '/director-board',
    name: 'DirectorBoard',
    component: DirectorBoard
  },
  {
    path: '/founding-board',
    name: 'FoundingBoard',
    component: FoundingBoard
  },
  {
    path: '/content-first',
    name: 'ContentFirst',
    component: ContentFirst
  },
  {
    path: '/content-second',
    name: 'ContentSecond',
    component: ContentSecond
  },
  {
    path: '/content-third',
    name: 'ContentThird',
    component: ContentThird
  },
  {
    path: '/content-fourth',
    name: 'ContentFourth',
    component: ContentFourth
  },
  {
    path: '/kvkk',
    name: 'Kvkk',
    component: Kvkk
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
