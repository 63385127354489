<template>

<Navbar />
<Slider />
<BannerBottom/>
<News />
<TotalMember />
<Ads />
<Feature/>
<Contents/>
<Footer />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Slider from "@/components/Slider.vue";
import Footer from "@/components/Footer.vue";
import Ads from "@/components/Ads.vue";
import News from "@/components/News.vue";
import TotalMember from "@/components/TotalMember.vue";
import BannerBottom from "@/components/BannerBottom.vue";
import Contents from "@/components/Contents.vue";
import Feature from "@/components/Feature.vue";

export default {
  name: "HomeView",
  components: {
     Navbar,
    Slider,
    Footer,
    Ads,
    News,
    TotalMember,
    BannerBottom,
    Contents,
    Feature
  },
};
</script>
<style>

</style>
