<template>
  <Navbar />
  <ScrollButton/>
  <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <i class="fa fa-home"></i>
              <p>S.S. Hasköy</p>
              <h4>Kısaca Biz</h4>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->
  <div class="body-inner">
    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <h3 class="column-title">Kazanan Türkiye olacak</h3>
            <p>
              Bilindiği üzere; Küçük Sanayi Siteleri, farklı yerlerde
              konumlanan, bununla beraber zaman içerisinde modern çalışma
              koşullarından uzaklaşmaya başlamış, ağırlık olarak esnaf ve
              sanatkarları bünyesinde barındıran, yerleşim yerleri arasında
              sıkışıp kalan ve rekabete açık olmayan atölyeleri daha düzenli ve
              nitelikli işletmelere dönüştürmektedir.
            </p>
            <p>
              Koşulların iyileştirilmesi, ihtiyaçların daha kolay ve daha az
              maliyetle karşılanmasını sağlarken, dayanışma ve organizasyonun
              gelişmesiyle, ana sanayi – yan sanayi ilişkisi de güçlenmektedir.
            </p>
            <p>
              Küçük Sanayi Sitelerinin ekonomimize en büyük katkısı, teknik ve
              ekonomik yönden günümüz şartlarına uygun, modern işyeri inşa etmek
              suretiyle, tamirattan imalata, imalattan seri üretime, dolayısıyla
              tamircilikten küçük sanayiye ve orta sanayiye geçişlerine imkan
              sağlayan bir merkez görevi görmesidir. Küçük Sanayi Siteleri
              ayrıca, küçük ve orta ölçekli sanayicilere, uğraşları gereği,
              ihtiyaç duydukları bütün fonksiyonları yerine getirmeye imkan
              bulabilecekleri ve sağlıklı çevre koşullarına kavuşacakları bir
              alan sağlar. Böylece sanayicilerin verimliliği artar ve büyük
              sanayi işletmelerine yan sanayi olarak hizmet vererek büyük
              sanayinin gelişmesine yardım eder.
            </p>
            <p>
              Ülkemizdeki tüm işletmelerin yüzde 99’unu, istihdamın yüzde
              78’ini, ihracatın yüzde 60’ını küçük ve orta ölçekli işletmelerin
              oluşturduğunu düşünürsek, küçük sanayi sitelerinin çoğalmasına
              verilecek destek, ülkemizin 2023 vizyonundaki 500 milyar dolarlık
              ihracat hedefi için oldukça önemlidir. Bu kapsamda ülkemiz,
              KOSGEB, Kalkınma Ajansı gibi kurumlar aracılığıyla; küçük ve orta
              ölçekli işletmelerden oluşan sanayi sitelerindeki esnaf ve
              sanatkarlar için önemli kredi destekleri sağlamaktadır. Bulunduğu
              bölge ve ölçeğine göre yatırım işletme, altyapı inşaatı gibi
              birçok başlık altında kredi imkanı sunulan ticaret erbapları için,
              emlak, çevre temizlik gibi vergilerde de indirim ve muafiyet söz
              konusudur.
            </p>
            <p>
              Sanayi siteleri oluşumlarının desteklenerek küçük ve orta ölçekli
              işletmelerin kooperatif çatışı altında toplanması daha güçlü, daha
              etkin, daha verimli olmasını sağlayacaktır.
            </p>
            <p>
              Her bir işletme, ulusal ve küresel rekabet gücünü
              artırabilecektir. Bunun yanı sıra; su, kanalizasyon, yol, elektrik
              giderlerinde maliyet azalacak ve çevre kirliliği ile plansız
              kentleşme önlenmiş olacaktır. Birbirini tamamlayıcı ve birbirinin
              yan ürününü teşvik eden sanatkarların bir arada ve bir program
              dahilinde üretim yapmalarıyla, üretimde verimlilik ve istihdam
              artacaktır. Bir diğer deyişle, kazanan Türkiye olacaktır.
            </p>
          </div>
        </div>
        <!-- Content row end -->
      </div>
      <!-- Container end -->
    </section>
    <!-- Main container end -->
  </div>
  <!-- Body inner end -->
  <Footer />
</template>
  
  <script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Footer,
    Navbar,
    ScrollButton
  },
};
</script>
  
  <style scoped>
.mission-img {
  display: block;
  position: absolute;
  left: 20%;
  width: 500px;
  max-height: 100%;
}

.work-img-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work-img-wrap {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .work-img-wrap {
    margin-top: 60px;
  }
}
.work-img-wrap .image-one img {
  max-width: calc(100% - 150px);
}
.work-img-wrap .image-two {
  position: absolute;
  top: 35%;
  left: 0;
}
@media (max-width: 767px) {
  .work-img-wrap .image-two {
    bottom: -40px;
  }
}
.work-img-wrap .image-two img {
  max-width: calc(100% - 60px);
}
</style>