<template>
    <Navbar />
    <ScrollButton/>
    <div
      id="banner-area"
      class="banner-area"
      style="background-image: url(images/director.jpg)"
    >
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="banner-heading">
                <i class="fa fa-home"></i>
                <p>S.S. Hasköy</p>
                <h4>Kısaca Biz</h4>
              </div>
            </div>
            <!-- Col end -->
          </div>
          <!-- Row end -->
        </div>
        <!-- Container end -->
      </div>
      <!-- Banner text end -->
    </div>
    <!-- Banner area end -->
    <div class="body-inner">
      <section id="main-container" class="main-container">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <h3 class="column-title">Bir memleket sanayiyle kalkınıyor.</h3>
              <p>
                Sanayi siteleri’nin ülke kalkınmasına sağladığı yarar apaçıktır.
                OSB’lerin yanı sıra ‘sanayi siteleri’ olarak isimlendirilen küçük
                fakat etkili aktörler bu kalkınmayı ateşleyen öğelerdir.
              </p>
              <p>
                Hasköy Sanayi Sitesi de ekonomiye aktif katkı sağlayan işler bir
                yapıdır.
              </p>
              <p>
                Sanayi sitesinde firmalarca üretilen ürünler dünyanın birçok
                bölgesine dolaylı olarak ihraç edilmektedir. Bu vesileyle
                ülkemizin ve ilimizin ekonomisine dev girdi sağlanmaktadır.
              </p>
              <p>Sanayi siteleri, iç piyasaya ürün üreten yapılardır.</p>
              <p>
                Hasköy Sanayi Sitesi üyeleri arasında döküm, makine, pres kalıp,
                torna gibi birbirinden farklı sektörlere yönelik çalışan firmalar
                mevcuttur. Sanayi sitesinde 42 adet 432, 68 adet 288, 100 adet 216
                ve 154 adet de 108 metrekare alanlı toplam 364 tane atölye yer
                almaktadır. 753 işyerinin yer aldığı sitede yaklaşık olarak 5 bin
                kişi istihdam edilmektedir.
              </p>
              <p>
                Hasköy Sanayi Sitesi Yönetim Kurulu Başkanı Ahmet Bektaş’ın da
                ifade ettiği gibi, Hasköy Sanayi Sitesi’nde yer alan firmaların,
                ürettikleri ürünleri pazarlamalarının yanı sıra, büyük firmalar
                için yaptıkları ürünlerle de tedarikçilik anlamında çok önemli bir
                eksiği kapatıyor.
              </p>
              <p>
                Hasköy Sanayi Sitesi tedarik ediyor, besliyor ve ihraç ediyor.
              </p>
              <p>
                Hasköy Sanayi Sitesi’nin ürettiği değer, sanayiyle iç içe bir
                yayın politikasına sahip olan bizlerinde dikkatini uzun zamandır
                cezbetmekteydi. Bu sebeple bizler, Hasköy Sanayi Sitesi’ni firma
                başarılarını ön planda tutarak sizlerin değerlendirmesine sunduk.
                Lojistik, tedarik, makine, otomotiv ve mühendislik hizmetleri
                veren firmalarla bir araya geldik. Sanayi sitelerinin Türkiye
                ekonomisine sağladığı katkıları merak ettik; firmaların kapısını
                çaldık. Bizler sorduk, onlar anlattı…
              </p>
              <p>
                Yanıtlar çerçevesinde sanayi sitelerinin daha profesyonelce
                desteklenmesinin gerekliliği üzerine bir çıkarımda bulunduk. Bu
                anlamda sanayi sitemizin gelişmesi için Belediye Başkanlarının
                yanı sıra devletinde küçük ölçekli olarak nitelendirilen sanayi
                siteleri içerisindeki esnaflara verdiği desteği artırarak
                sürdürmesi gerekmektedir.
              </p>
              <p>
                Çünkü sanayinin çekirdeği Sanayi Siteleri’nden filizlenmektedir…
              </p>
              <p>
                Türkiye’nin gizli kahramanlarına bir nebze ışık tutabilseydik ne
                mutlu…
              </p>
              <p>Emeği geçen Sanayi Gazetesi ekibine teşekkürlerimle…..</p>
            </div>
          </div>
          <!-- Content row end -->
        </div>
        <!-- Container end -->
      </section>
      <!-- Main container end -->
    </div>
    <!-- Body inner end -->
    <Footer />
  </template>
    
    <script>
  import Footer from "@/components/Footer.vue";
  import Navbar from "@/components/Navbar.vue";
  import ScrollButton from "@/components/ScrollButton.vue";
  
  export default {
    components: {
      Footer,
      Navbar,
      ScrollButton
    },
  };
  </script>
    
    <style scoped>
  .mission-img {
    display: block;
    position: absolute;
    left: 20%;
    width: 500px;
    max-height: 100%;
  }
  
  .work-img-wrap {
    position: relative;
    z-index: 1;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .work-img-wrap {
      margin-top: 60px;
    }
  }
  @media (max-width: 767px) {
    .work-img-wrap {
      margin-top: 60px;
    }
  }
  .work-img-wrap .image-one img {
    max-width: calc(100% - 150px);
  }
  .work-img-wrap .image-two {
    position: absolute;
    top: 35%;
    left: 0;
  }
  @media (max-width: 767px) {
    .work-img-wrap .image-two {
      bottom: -40px;
    }
  }
  .work-img-wrap .image-two img {
    max-width: calc(100% - 60px);
  }
  </style>