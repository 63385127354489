<template>
 <div id="top-bar" class="top-bar"> 
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-7">
          <ul class="top-info text-md-left">
            <li style="vertical-align: middle">
              <i class="fas fa-map-marker-alt white"></i>
              <p class="info-text white">Gebze / KOCAELİ</p>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-3">
          <div class="navbar-social">
            <ul>
              <li>
                <a href="https://www.facebook.com/people/Gebze-Hask%C3%B6y-Sanayi-Sitesi/61556941386615/" target="_blank"  aria-label="Facebook"
                  ><i class="fab fa-facebook-f"></i
                ></a>
              </li>
              <li>
                <a href="#" aria-label="Twitter"
                  ><i class="fab fa-twitter"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/haskoysanayisitesi/" target="_blank"
                  aria-label="Instagram"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
              <li>
                <a href="#" aria-label="Github"
                  ><i class="fab fa-github"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-1">
          <div class="navbar-login">
            <ul class="top-info">
              <li v-if="!kullanici">
                <router-link to="/admin/login" class="btn btn-secondary white"
                  >Giriş</router-link
                >
              </li>
              <li v-else>
                <router-link to="/admin" class="btn btn-secondary white"
                  >Giriş</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        
        <!--/ Top info end -->
      </div>
      <!--/ Content row end -->
    </div>
    <!--/ Container end -->
   
  </div>
  
  <!--/ Topbar end -->
  <!-- Header start -->
  <header id="header" class="header-one">
    <div class="bg-white">
      <div class="container">
        <div class="logo-area">
          <div class="row align-items-center">
            <div class="logo col-lg-4 col-md-4 text-lg-left">
              <router-link class="d-block" to="/home">
                <img
                  loading="lazy"
                  src="images/haskoy-logo-yazi3.png"
                  alt="Hasköy"
                />
              </router-link>
            </div>
            <!-- logo end -->
            <div class="col-lg-2 col-md-2 none">
              <img loading="lazy" src="images/slogan.png" alt="" />
            </div>
            <!-- logo end -->
          </div>
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <div class="site-navigation">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-8">
            <nav class="navbar navbar-expand-lg navbar-dark p-0">
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target=".navbar-collapse"
                aria-controls="navbar-collapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <div id="navbar-collapse" class="collapse navbar-collapse">
                <ul class="nav navbar-nav mr-auto">
                  <li class="nav-item dropdown active">
                    <router-link
                      to="/home"
                      class="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      >Anasayfa
                    </router-link>
                  </li>

                  <li class="nav-item dropdown">
                    <router-link
                    :to="''"
                      class="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      >Hakkında<i class="fa fa-angle-down"></i>
                    </router-link>
                    <ul class="dropdown-menu" role="menu">
                      <li>
                        <router-link to="/about">Kısaca Biz </router-link>
                      </li>
                      <li>
                        <router-link to="/mission-vision"
                          >Vizyon ve Değerlerimiz
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/history">Tarihçe </router-link>
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item dropdown">
                    <router-link
                    :to="''"
                      class="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      >Kurumsal <i class="fa fa-angle-down"></i>
                    </router-link>
                    <ul class="dropdown-menu" role="menu">
                      <li>
                        <router-link to="/director-board"
                          >S.S. Hasköy Yönetim Kurulu</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/supervisor-board"
                          >S.S. Hasköy Denetim Kurulu
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/founding-board"
                          >S.S. Hasköy Kurucu Kurulu
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown">
                    <router-link
                      to="/company-list"
                      class="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      >Firma Listesi
                    </router-link>
                  </li>

                  <li class="nav-item dropdown">
                    <router-link
                      to="/member-list"
                      class="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      >Üye Listesi
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link
                      to="/contact"
                      class="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      >İletişim</router-link
                    >
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <!--/ Col end -->
        </div>
        <!--/ Row end -->
      </div>
      <!--/ Container end -->
    </div>
    <!--/ Navigation end -->
  </header>
</template>

<script>
import { ref, onMounted } from "vue";
import getUser from "@/composables/getUser";
import LanguageVue from './Language.vue';

export default {
  components:{
    LanguageVue
  },
  setup() {
    const kId = ref("");
    const { kullanici } = getUser();

    onMounted(() => {
      if (kullanici.value) {
        console.log(kullanici.value.uid);
      } else {
        console.log(kullanici.value);
      }

      // console.log(kullanici.value.uid);
    });
    return { kullanici, kId };
  },
};
</script>

<style scoped>
.navbar-login{
  margin-left: 30px;
}
.white {
  color: #fff;
}

.navbar-social ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-social ul li {
  display: inline-block;
}

.navbar-social ul li a i {
  display: block;
  font-size: 16px;
  color: #999;
  transition: 400ms;
  padding: 10px 13px;
}

.navbar-social ul li:hover {
  color: #fff;
}
</style>