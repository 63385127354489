<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="fileHata">{{ fileHata }}</div>
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Mesaj Detay</h2>
          <p class="lead">
            <router-link to="/admin/message-list"
              >Mesaj Listesi içn Tıklayınız</router-link
            >
          </p>
        </div>
      </div>
      <div class="row g-5">
        <div class="col-md-8 col-lg-8">
          <div class="d-flex justify-content-between">
            <p>Mesaj tarihi: {{ tarih }}</p>
          </div>
          <form class="needs-validation" @submit.prevent="handleSubmit">
            <div class="row g-3">
              <div class="col-sm-6">
                <label for="isim" class="form-label">İsim Soyisim</label>
                <input
                  type="text"
                  class="form-control"
                  id="isim"
                  v-model="isim"
                  readonly
                />
              </div>
              <div class="col-sm-6">
                <label for="email" class="form-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="email"
                  readonly
                />
              </div>
              <div class="col-sm-6">
                <label for="konu" class="form-label">Konu</label>
                <input
                  type="text"
                  class="form-control"
                  id="konu"
                  v-model="konu"
                  readonly
                />
                Okundu
                <div>
                  <input
                    type="radio"
                    id="yes"
                    name="read"
                    :value="true"
                    v-model="okundu"
                    :checked="okundu == true"
                  />
                  <label for="yes" class="form-label">Evet</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="no"
                    name="read"
                    :value="false"
                    v-model="okundu"
                    :checked="okundu == false"
                  />
                  <label for="no" class="form-label">Hayır</label>
                </div>
              </div>
              <div class="col-sm-6">
                <label for="mesaj" class="form-label">Mesaj</label>
                <textarea
                rows="10"
                  class="form-control"
                  id="mesaj"
                  v-model="mesaj"
                  readonly
                ></textarea>
              </div>
            </div>
            <hr class="my-4" />
            <button class="w-100 btn btn-success btn-lg mb-4" type="submit">
              Güncelle
            </button>
          </form>
        </div>
      </div>
    </main>
  </div>
  <AdminFooter />
</template>
      
      <script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { doc, getDoc, updateDoc, collection } from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import { notify } from "@kyvg/vue3-notification";
import { useRoute, useRouter } from "vue-router";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";

export default {
  components: {
    Loading,
    AdminFooter,
    AdminNavbar,
    ModalsContainer,
    VueFinalModal
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isLoading = ref(true);
    const showModal = ref(false);
    const fileHata = ref(null);
    const hataMesaj = ref("");
    const mId = ref(route.params.mesajId);
    const tarih = ref("");

    const olusturulmaTarihi = ref("");
    const isim = ref("");
    const konu = ref("");
    const mesaj = ref("");
    const email = ref("");
    const telNo = ref("");
    const okundu = ref(false);

    const bilgiKontrol = ref(false);
    moment.locale("tr");

    onMounted(async () => {
      const docRef = doc(firestore, "mesajlar", mId.value);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        olusturulmaTarihi.value = docSnap.data().olusturulmaTarihi.toDate();
        isim.value = docSnap.data().isim;
        konu.value = docSnap.data().konu;
        mesaj.value = docSnap.data().mesaj;
        email.value = docSnap.data().email;
        telNo.value = docSnap.data().telNo;
        okundu.value = docSnap.data().okundu;

        isLoading.value = false;
        tarih.value = moment(olusturulmaTarihi.value).fromNow();
      } else {
        console.log("Veriye erişilemedi");
      }
    });

    const handleSubmit = async () => {
      isLoading.value = false;
      const myCollection = collection(firestore, "mesajlar");
      const myDocRef = doc(myCollection, mId.value);
      try {
        const data = {
          okundu: okundu.value,
        };
        await updateDoc(myDocRef, data);
        router.push("/admin/message-list");
      } catch (error) {}
    };
    return {
      tarih,
      mId,
      olusturulmaTarihi,
      isim,
      konu,
      mesaj,
      telNo,
      okundu,
      email,
      isLoading,
      handleSubmit,
      hataMesaj,
      fileHata,
      showModal
    };
  },
};
</script>
      
      <style>
</style>
      
      