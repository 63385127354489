<template>
      <button v-if="showScrollButton" @click="scrollToTop" class="scroll-button">
    <i class="fa fa-angle-double-up"></i>
  </button>
</template>
<script>
import { ref, onMounted, onUnmounted } from 'vue';
export default {
setup() {
  const showScrollButton = ref(false);

  // Sayfanın en üstüne kaydırma işlevi
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Yumuşak animasyonlu kaydırma
    });
  };

  // Scroll olay dinleyicisi
  const handleScroll = () => {
    // Scroll konumu belirli bir değerden büyükse "Yukarı Çık" düğmesini göster
    showScrollButton.value = window.pageYOffset > 300;
  };

  // Component yüklendiğinde scroll olayını dinlemeye başla
  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });

  // Component kaldırıldığında scroll olayını durdur
  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  return { showScrollButton, scrollToTop };
}
};
</script>