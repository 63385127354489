<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>
  <div class="container">
    <div class="pt-5 text-center">
      <div class="alert alert-dark" role="alert">
        <h2>Kurul Üyeler</h2>

        <router-link to="/admin/add-board" class="btn btn-secondary"
          >Kurul Üye Ekle</router-link
        >
      </div>
    </div>
    <custom-table
      :rows="kurulListe"
      @updateButtonClick="updateHandler($event)"
      @deleteButtonClick="deleteHandler($event)"
      :update-button-visible="true"
      :delete-button-visible="true"
      :actionsButtonsVisible="true"
      :columns="tableColumns"
    >
    </custom-table>
  </div>

  <AdminFooter />
</template>
  
  <script>
import { ref, onMounted } from "vue";
import { auth, firestore } from "@/firebase/config";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
import CustomTable from "@/components/Table.vue";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import "vue-loading-overlay/dist/vue-loading.css";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    Loading,
    VueFinalModal,
    ModalsContainer,
    AdminFooter,
    AdminNavbar,
    CustomTable,
  },
  data() {
    return {
      tableColumns: [
        { label: "İsim Soyisim", value: "isim" },
        { label: "Ünvan", value: "baslik" },
        { label: "Tür", value: "tur" },
        { label: "Sıra", value: "sira" },
        { label: "Resim", value: "profilResim" },
        { label: "Durum", value: "durum" },
      ],
    };
  },
  methods: {
    updateHandler(data) {
      console.log("update", data);
      console.log(data.item.id);

      this.$router.push({
        name: "BoardDetail",
        params: { kurulId: data.item.id },
        props: true,
      });
    },
  },
  setup() {
    const kurulListe = ref([]);
    const isLoading = ref(true);
    const router = useRouter();
    const showModal = ref(false);
    const hataMesaj = ref("");

    const deleteHandler = async (data) => {
      isLoading.value = true;
      const myCollection = collection(firestore, "kurulListe");
      const myDocRef = doc(myCollection, data.item.id);
      try {
        const data = {
          durum: false,
        };
        await updateDoc(myDocRef, data);
        router.go(0);
        isLoading.value = false;
      } catch (error) {
        hataMesaj.value = error.message;
        isLoading.value = false;
        showModal.value = true;
      }
    };
    onMounted(async () => {
      isLoading.value = false;

      let q = query(
        collection(firestore, "kurulListe"),
        orderBy("olusturulmaTarihi", "desc"),
        where("durum", "==", true)
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });
      console.log(veri);

      kurulListe.value = veri;
      isLoading.value = false;
    });

    return {
      kurulListe,
      isLoading,
      showModal,
      hataMesaj,
      deleteHandler,
    };
  },
};
</script>
  
  <style scoped>
</style>