<template>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>
  <div class="container">
    <div
      class="modal fade"
      id="exampleModalToggle"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel"
      tabindex="-1"
      v-show="showModel"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalToggleLabel">
              S.S. Hasköy'e öneri sunmak istermisiniz?
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="close"
            ></button>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div class="d-flex justify-content-between"></div>
              <form class="needs-validation" @submit.prevent="handleSubmit">
                <div class="modal-body">
                  <div class="col-xs-12">
                    <input
                      type="text"
                      class="form-control mt-2"
                      id="email"
                      placeholder="Mail Adresiniz *"
                      v-model="email"
                      required
                    />
                  </div>
                  <div class="col-xs-12">
                    <input
                      type="text"
                      class="form-control mt-2"
                      id="telNo"
                      placeholder="Telefon Numaranız *"
                      v-model="telNo"
                      required
                    />
                  </div>
                  <div class="col-xs-12">
                    <input
                      type="text"
                      class="form-control mt-2"
                      id="isim"
                      placeholder="Adınız Soyadınız *"
                      v-model="isim"
                      required
                    />
                  </div>
                  <div class="col-xs-12">
                    <input
                      type="text"
                      class="form-control mt-2"
                      id="kurum"
                      placeholder="İlgili Kurum *"
                      v-model="kurum"
                      required
                    />
                  </div>
                  <div class="col-xs-12">
                    <input
                      type="text"
                      class="form-control mt-2"
                      id="konu"
                      placeholder="Konu Başlığı *"
                      v-model="konu"
                      required
                    />
                  </div>
                  <div class="col-xs-12">
                    <textarea
                      rows="5"
                      class="form-control textarea mt-2"
                      id="mesaj"
                      placeholder="Öneriniz *"
                      v-model="mesaj"
                      required
                    ></textarea>
                  </div>
                  <div class="col-xs-12">
                    <input
                      type="checkbox"
                      id="chk_user"
                      class="checkbox-custom"
                      required
                    />
                    <label for="chk_user" class="checkbox-custom-label">
                      <router-link to="/kvkk" target="_blank"
                        >S.S. Hasköy Kişisel Verilerin Korunması Aydınlatma
                        Metni’ni okudum</router-link
                      >
                    </label>
                  </div>
                  <div class="col-xs-12">
                    <button
                      class="w-100 btn btn-success btn-lg mb-4"
                      type="submit"
                    >
                      Gönder
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="background-color">
  <div class="container">
    <div class="row">
      <div class="bg-center">
        <div class="top-left col-lg-6 col-md-6 col-sm-6 mt-3">
          <p class="fw-bold">Görüş ve önerileriniz bizim için değerlidir.</p>
        </div>
        <div class="top-right col-lg-2 col-md-4 col-sm-4 mt-4">
          <a
            class="btn btn-primary"
            data-bs-toggle="modal"
            href="#exampleModalToggle"
            role="button"
            @click="openModal"
            >Değerlendir
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
   
   <script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { notify } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";

export default {
  components: {
    Loading,
  },
  data(){
    return{
     
    }
  },

  setup() {
    const email = ref("");
    const telNo = ref("");
    const isim = ref("");
    const olusturulmaTarihi = ref("");
    const okundu = ref(false);
    const konu = ref("");
    const mesaj = ref("");
    const kurum = ref("");
    const oneriTalep = ref("");
    const isLoading = ref(false);
    const showModal = ref(false);
    
    const openModal = () => {
      showModal.value = true;
    };

    const handleSubmit = async () => {
      isLoading.value = true;
      showModal.value=true;
      const myDocumentData = {
        email: email.value,
        telNo: telNo.value,
        isim: isim.value,
        okundu: okundu.value,
        konu: konu.value,
        mesaj: mesaj.value,
        kurum: kurum.value,
        olusturulmaTarihi: serverTimestamp(),
        oneriTalep: "Öneri",
        kvkk:true
      };

      await addDoc(collection(firestore, "mesajlar"), myDocumentData)
        .then(() => {
          isLoading.value = false;
          notify({
            title: "Başarılı",
            text: "Gönderildi",
            type: "success",
            duration: 2000,
          });
        })
        .catch(() => {
          isLoading.value = false;
          notify({
            title: "Uppppsss Hata Oluştu",
            text: "Mesajınız gönderilemedi.",
            type: "error",
            duration: 2000,
          });
        });
        showModal.value=false;
    };

    return {
      email,
      telNo,
      isim,
      okundu,
      konu,
      mesaj,
      kurum,
      olusturulmaTarihi,
      oneriTalep,
      handleSubmit,
      isLoading,
      openModal,
      showModal
    };
  },
};
</script>
   
   
   <style scoped>
.checkbox-custom {
  float: left;
  margin-top: 20px;
}
.checkbox-custom-label {
  float: left;
  max-width: 90%;
  padding-left: 15px;
}
.background-color
{
background: rgb(248, 248, 248);
}
.form-control {
  box-shadow: none;
  border: 1px solid #dadada;
  padding: 5px 20px;
  height: 44px;
  background: none;
  color: #959595;
  font-size: 14px;
  border-radius: 2px;
}
.textarea {
  height: 122px !important;
}
.bg-center {
  position: relative;
  background: rgb(248, 248, 248);
  height: 100px;
  margin-top: -50px;
  border-radius: 5px;
  border: 1px solid #001e57;
}
.bg-center p {
  color: #001e57;
  font-size: 20px;
  line-height: 60px;
  font-family: "Montserrat", sans-serif;
}
@media only screen and (min-width:991px) and (max-width: 1200px) {
  .bg-center {
  height: 100px;
  margin-top: -50px;
  border-radius: 5px;
}
.bg-center p {
  font-size: 20px;
  line-height: 60px;
  font-family: "Montserrat", sans-serif;
}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bg-center {
  height: 100px;
  margin-top: -50px;
  border-radius: 5px;
}
.bg-center p {
  font-size: 20px;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
}
}
@media (max-width: 767px) {
  .bg-center {
  height: 100px;
  margin-top: -50px;
  border-radius: 5px;
}
.bg-center p {
  font-size: 20px;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
}
}
.top-right {
  float: right;
}
.top-left {
  float: left;
}
</style>