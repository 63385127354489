<template>
    <Navbar/>
    <ScrollButton/>
    <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <i class="fa fa-home"></i>
              <p>S.S. Hasköy</p>
              <h4>Denetim Kurulu</h4>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->
  <div id="about-3" class="about-section gray-bg section-padding">
    <div class="container">
      <div class="row align-items-center mb-5" v-for="d in denetim" :key="d.id">
          <div
            class="col-xl-3 col-lg-3 col-md-4 col-sm-6 wow fadeInUp"
            data-wow-delay=".2s"
            style="
              visibility: visible;
              animation-delay: 0.2s;
              animation-name: fadeInUp;
            "
          >
            <div class="about-bg-wrap">
              <!-- <img :src="d.profilResim" /> --> <!--resimler düzgün eklendikten sonra açılacak 260x300-->
              <img :src="d.profilResim">
            </div>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-8 col-sm-6">
            <div class="about-content-wrap">
              <div class="section-title">
                <h6>{{ d.baslik }}</h6>
                <h2>{{ d.isim }}</h2>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
    <Footer />
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
import { auth, firestore } from "@/firebase/config";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";


import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Loading,
    Footer,
    Navbar,
    ScrollButton
  },
  setup() {
    const denetim = ref([]);
    const isLoading = ref(true);

    const bosMesajı = ref("Liste Bulunamadı");

    onMounted(async () => {
      let q = query(
        collection(firestore, "kurulListe"),
        where("tur", "==", "Denetim"),
        where("durum","==",true),
        orderBy("sira","asc")
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      denetim.value = veri;
      isLoading.value = false;
    });

    return {
      denetim,
      isLoading,
      bosMesajı,
    };
  },
};
  </script>
  
  <style scoped>
  </style>