<template>
  <section id="ts-team" class="ts-team" style="background-color: #f8f8f8">
    <div class="container">
      <div class="row text-center">
        <div class="col-lg-12">
          <!-- <h2 class="section-title">Hasköy'den Haberler</h2> -->
          <h3 class="section-sub-title">Hasköy'den Haberler</h3>
        </div>
      </div>
      <!--/ Title row end -->

      <div class="row" v-if="displayedNews.length !== 0">
        <div
          class="col-lg-4 col-md-4 col-sm-6"
          v-for="haber in displayedNews"
          :key="haber"
        >
          <div id="team-slide" class="team-slide">
            <div class="item">
              <ul>
                <li>
                  <h5><i class="fas fa-calendar-alt">{{
                   haber.olusturulmaTarihi
                  }}</i></h5>
                </li>
              </ul>
              <div class="ts-team-wrapper">
                <div class="team-img-wrapper">
                  <img
                    loading="lazy"
                    class="w-100 rounded-2"
                    :src="haber.resim"
                    alt="team-img"
                  />
                </div>
                <router-link
                  :to="{
                    name: 'NewsDetailById',
                    params: { haberId: haber.id },
                  }"
                >
                  <div class="ts-team-content">
                    <h3 class="ts-name">{{ haber.baslik }}</h3>
                    <p class="ts-designation">{{ haber.baslikAlt }}</p>
                    <p class="ts-description truncate">{{ haber.icerik }}</p>
                  </div>
                </router-link>
              </div>
              <!--/ Team wrapper end -->
            </div>
            <!-- Team 2 end -->
          </div>
          <!-- Team slide end -->
        </div>
      </div>
      <!--/ Content row end -->
      <div class="pagination">
        <button @click="prevPage" v-if="currentPage > 1">
          <i class="bi bi-chevron-double-left"></i>
        </button>
        <button
          v-for="pageNumber in totalPages"
          :key="pageNumber"
          @click="goToPage(pageNumber)"
          :class="{ active: currentPage === pageNumber }"
        >
          {{ pageNumber }}
        </button>
        <button @click="nextPage" v-if="currentPage < totalPages">
          <i class="bi bi-chevron-double-right"></i>
        </button>
      </div>
    </div>
    <!--/ Container end -->
  </section>
  <!--/ Team end -->
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import { ref, onMounted,computed } from "vue";
import { auth, firestore } from "@/firebase/config";
import { formatDistance } from 'date-fns';
import moment from "moment";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";
export default {
  data() {
    return {
      slickOptions: {
        arows: false,
        slidesToShow: 3,
      },
    };
  },
  setup() {
    const haberler = ref([]);
    const isLoading = ref(true);
    const currentPage = ref(1);
    const pageSize = 3; // Sayfa başına öğe sayısı
    const totalItems = ref(null); // Toplam öğe sayısı

    moment.locale("tr");
    const totalPages = computed(() => Math.ceil(totalItems.value / pageSize));

    const displayedNews = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      console.log(haberler.value.slice(startIndex, endIndex));
      return haberler.value.slice(startIndex, endIndex);
    });
    // Sayfa değiştirme işlevi
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        // Sayfaya göre verileri güncelleme işlemleri burada yapılabilir
      }
    };
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        goToPage(currentPage.value);
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        goToPage(currentPage.value);
      }
    };

    const formatDate=(date)=>{
      return formatDistance(date, new Date(), { addSuffix: true });
    }
    onMounted(async () => {
      let q = query(
        collection(firestore, "haberler"),
        where("durum", "==", true),
        orderBy("olusturulmaTarihi", "desc"),
        limit(20)
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      haberler.value = veri;
      isLoading.value = false;
      totalItems.value = veri.length;

      haberler.value.forEach((item) => {
      item.olusturulmaTarihi= moment(item.olusturulmaTarihi.toDate()).format("DD-MM-YYYY");
      });
    });
    return {
      haberler,
      isLoading,
      currentPage,
      totalPages,
      goToPage,
      displayedNews,
      prevPage,
      nextPage,
      formatDate
    };
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}
.carousel-wrapper {
  padding: 40px;
}
.img-wrapper img {
  margin: auto;
  width: 200px;
  height: 100px;
  background-image: linear-gradient(gray 100%, transparent 0);
}
.section-sub-title {
  border-bottom: 1px solid rgba(157, 157, 157, 0.5);
}
</style>