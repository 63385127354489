import { ref } from "vue";
import { firestore } from "@/firebase/config";
import { addDoc, doc, setDoc, collection, db } from "firebase/firestore";

const useCollection = (koleksiyon) => {

    const hataFirestore = ref(null);
    const id = ref("");
    const subId = ref("");

    const belgeEkle = async (belge, uid) => {
        hataFirestore.value = null

        try {
            const docRef = await doc(firestore, koleksiyon, uid)
            await setDoc(docRef, belge)
        } catch (error) {
            hataFirestore.value = 'Veritabanı hatası oluştu'
        }
    }
    const yeniBelgeEkle = async (data) => {
        hataFirestore.value = null;
        try {
            const docRef = await addDoc(collection(firestore, koleksiyon), data);
            console.log("Document written with ID: ", docRef.id);
            id.value = docRef.id;

        } catch (error) {
            hataFirestore.value = error.message;
        }
    }
    const subBelgeEkle = async (data, dataDetail) => {
        hataFirestore.value = null;
        try {

            const docRef = await addDoc(collection(firestore, koleksiyon), data);
            console.log("Document written with ID: ", docRef.id);
            id.value = docRef.id;

            const docRefDetail = doc(firestore, koleksiyon, id.value);
            const colRefDetail = await addDoc(collection(docRefDetail, "haberDetay"),dataDetail)
            subId.value=colRefDetail.id

        } catch (error) {
            hataFirestore.value = error.message;
        }
    }
    return { hataFirestore, belgeEkle, yeniBelgeEkle,subBelgeEkle, id,subId }
}

export default useCollection
