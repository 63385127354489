<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Yeni Üye Ekle</h2>
          <p class="lead">
            <router-link to="/admin/notice-list"
              >Duyuru Listesi için Tıklayınız</router-link
            >
          </p>
        </div>
      </div>

      <div class="row g-5">
        <div class="col-md-8 col-lg-8">
          <h4 class="mb-3">Duyuru Bilgileri</h4>
          <div class="d-flex justify-content-between"></div>
          <form class="needs-validation" @submit.prevent="handleSubmit">
            <div class="row g-3">
              <div class="col-sm-6">
                <label for="baslik" class="form-label">Duyuru Başlık</label>
                <input
                  type="text"
                  class="form-control"
                  id="baslik"
                  placeholder=""
                  v-model="baslik"
                />
              </div>

              <div class="col-6">
                <label for="icerik" class="form-label">Duyuru İçerik</label>
                <input
                  type="text"
                  class="form-control"
                  id="icerik"
                  placeholder=""
                  v-model="icerik"
                />
              </div>
              <div class="col-6">
                Durum
                <label for="aktif" class="form-label">Durum</label>
                <div class="col-3">
                  <input
                    type="radio"
                    id="aktif"
                    name="durum"
                    :value="true"
                    v-model="durum"
                  />
                  <label for="aktif" class="form-label">Aktif</label>
                </div>
                <div class="col-3">
                  <input
                    type="radio"
                    id="pasif"
                    name="durum"
                    :value="false"
                    v-model="durum"
                  />
                  <label for="pasif" class="form-label">Pasif</label>
                </div>
              </div>
            </div>

            <hr class="my-4" />
            <button class="w-100 btn btn-success btn-lg mb-4" type="submit">
              Duyuru Ekle
            </button>
          </form>
        </div>
      </div>
    </main>
  </div>
  <AdminFooter />
</template>
        
        <script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import {
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import { useRouter } from "vue-router";

export default {
  components: {
    Loading,
    AdminFooter,
    AdminNavbar,
    VueFinalModal,
    ModalsContainer,
  },
  setup() {
    const showModal = ref(false);
    const isLoading = ref(true);

    const olusturulmaTarihi = ref("");
    const baslik = ref("");
    const icerik = ref("");
    const durum = ref(true);

    const hataMesaj = ref("");

    const router = useRouter();

    moment.locale("tr");

    onMounted(async () => {
      isLoading.value = false;
    });

    const handleSubmit = async () => {
      isLoading.value = true;
      const myDocumentData = {
        icerik: icerik.value,
        baslik: baslik.value,
        durum: durum.value === true ? true : false,
        olusturulmaTarihi: serverTimestamp(),
      };
      try {
        await addDoc(collection(firestore, "duyurular"), myDocumentData);

        router.push("/admin/notice-list");
      } catch (error) {
        hataMesaj.value = error.message;
        isLoading.value = false;
        showModal.value = true;
      }
    };

    return {
      olusturulmaTarihi,
      icerik,
      baslik,
      isLoading,
      handleSubmit,
      hataMesaj,
      durum,
    };
  },
};
</script>
        
        <style>
</style>
        
        
        