<template>
  <Notifications position="top center" :width="400" />
  <router-view />

</template>

<script>
import HomeView from "./views/HomeView.vue";
export default {
  name: "App",
  components: {
    HomeView,
  },
};
</script>

<style>
</style>
