<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  
      <router-link to="/admin" class="navbar-brand text-uppercase">
        <img
          src="images/haskoy-logo.ico"
          alt=""
          width="30"
          height="30"
          class="d-inline-block align-text-top"
        />
        Admin
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li v-if="yetki == 'admin'" class="nav-item">
            <router-link
              to="/admin/dashboard"
              class="nav-link active"
              aria-current="page"
              >Anasayfa</router-link
            >
          </li>
          <li
            v-if="yetki == 'admin' || yetki == 'uye' || yetki == 'basit'"
            class="nav-item"
          >
            <router-link
              to="/admin/profile"
              class="nav-link active"
              aria-current="page"
              >Profilim</router-link
            >
          </li>
          <li v-if="yetki == 'admin'" class="nav-item">
            <router-link
              to="/admin/board-list"
              class="nav-link active"
              aria-current="page"
              >Yönetim</router-link
            >
          </li>
          <li v-if="yetki == 'admin'" class="nav-item">
            <router-link
              to="/admin/company-list"
              class="nav-link active"
              aria-current="page"
              >Firma Listesi</router-link
            >
          </li>
       
          <li v-if="yetki == 'admin'" class="nav-item">
            <router-link
              to="/admin/member-list"
              class="nav-link active"
              aria-current="page"
              >Sanayi Üye Listesi</router-link
            >
          </li>
          <li v-if="yetki == 'admin'" class="nav-item">
            <router-link
              to="/admin/ads-list"
              class="nav-link active"
              aria-current="page"
              >Reklamlar</router-link
            >
          </li>
          <li v-if="yetki == 'admin'" class="nav-item">
            <router-link
              to="/admin/news-list"
              class="nav-link active"
              aria-current="page"
              >Haberler</router-link
            >
          </li>
          <li v-if="yetki == 'admin'" class="nav-item">
            <router-link
              to="/admin/notice-list"
              class="nav-link active"
              aria-current="page"
              >Duyurular</router-link
            >
          </li>
          <li v-if="yetki == 'admin'" class="nav-item">
            <router-link
              to="/admin/message-list"
              class="nav-link active"
              aria-current="page"
              >Mesajlar</router-link
            >
          </li>
        </ul>
        <ul
          v-if="!kullanici"
          class="navbar-nav me-auto mb-2 mb-lg-0 text-warning"
        >
          <li class="nav-item">İşlemler için doğru yerdesiniz</li>
        </ul>
        <div v-if="kullanici" class="text-end">
          <span style="color: white">{{ mesajlar.length }} yeni mesajınız var</span><br />
          <span class="me-2 text-warning">Merhaba {{ kullaniciAd }}</span>

          <button
            @click="handleLogout"
            type="button"
            class="btn btn-outline-danger me-2"
          >
            Çıkış
          </button>
        </div>

        <div v-if="!kullanici" class="d-flex">
          <router-link
            to="/admin/login"
            class="btn btn-outline-light me-2"
            type="submit"
            >Giriş</router-link
          >
          <router-link to="/admin/signup" class="btn btn-warning" type="submit"
            >Üye Ol</router-link
          >
        </div>
      </div>
  </nav>
</template>

<script>
import { ref, onMounted, onBeforeUpdate } from "vue";
import getUser from "../composables/getUser";

import useLogout from "../composables/useLogout";
import { useRouter } from "vue-router";

import getYetkili from "../composables/getYetkili";
import { auth, firestore } from "@/firebase/config";
import { getDocs,query,collection,where,limit } from "firebase/firestore";

export default {
  setup() {
    const { kullanici } = getUser();
    const kullaniciAd = ref("");
    const router = useRouter();
    const { hataLogout, logout } = useLogout();
    const { yetki, yetkiKontrol } = getYetkili();
    const mesajlar = ref([]);

    onBeforeUpdate(async () => {
      if (kullanici.value) {
        kullaniciAd.value = kullanici.value.displayName;

        await yetkiKontrol(kullanici.value.uid);
      }
    });

    onMounted(async () => {
      if (kullanici.value) {
        //console.log(kullanici.value)
        kullaniciAd.value = kullanici.value.displayName;
        await yetkiKontrol(kullanici.value.uid);

        let q = query(
          collection(firestore, "mesajlar"),
          where("okundu", "==", false),
          limit(20)
        );
        const querySnapshot = await getDocs(q);

        const veri = [];

        querySnapshot.forEach((doc) => {
          veri.push({ ...doc.data(), id: doc.id });
        });

        mesajlar.value = veri;
      }
    });

    const handleLogout = async () => {
      await logout();

      router.push({ name: "HomeView" });
    };

    return { kullanici, kullaniciAd, handleLogout, yetki ,mesajlar};
  },
};
</script>

<style scoped>
ul.navbar-nav > li > a {
    font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.84) !important; /*navbar color*/
  text-rendering: optimizeLegibility;
  font-weight: 700 !important;
  text-transform: uppercase;
  letter-spacing: -.2px;
  font-size: 14px;
  margin: 0;
  line-height: 40px;
  padding: 30px 0;
  transition: 350ms;
}
ul.navbar-nav > li{
  padding: 0 10px;
  position: relative;
}

</style>