<template>
  <AdminNavbar/>
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Kimlik bilgilerinizi güncelleyin</h2>
          <p class="lead">
            <router-link to="/admin/profile" class="text-primary"
              >Profil Sayfası</router-link
            >
          </p>
        </div>
      </div>

      <div class="row g-5">
        <div class="col-md-12 col-lg-12">
          <h4 class="mb-3">Kimlik bilgilerim</h4>
          <form class="needs-validation">
            <div class="row g-3">
              <div class="col-4">
                <label for="kAd" class="form-label">Kullanıcı Adı:</label>
                <div class="input-group has-validation">
                  <span class="input-group-text"
                    ><i class="bi bi-person"></i
                  ></span>
                  <input
                    v-model="kAd"
                    type="text"
                    class="form-control"
                    id="kAd"
                    placeholder="Kullanıcı adını giriniz"
                  />
                </div>
              </div>

              <div class="col-4">
                <label for="email" class="form-label">Email:</label>
                <div class="input-group has-validation">
                  <span class="input-group-text"
                    ><i class="bi bi-envelope"></i
                  ></span>
                  <input v-model="email"
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                  />
                </div>
              </div>

              <div class="col-4">
                <label for="parola" class="form-label">Parola:</label>
                <div class="input-group has-validation">
                  <span class="input-group-text"
                    ><i class="bi bi-lock"></i
                  ></span>
                  <input
                    type="password"
                    class="form-control"
                    id="parola"
                    placeholder="Parola"
                    v-model="parola"
                  />
                </div>
              </div>
            </div>
            <br class="my-4" />

            <div class="row g-3">
              <div class="col-4">
                <button
                  class="w-100 btn btn-success btn-lg mb-4"
                  type="button"
                  @click="handleKullaniciAdDegistir"
                >
                  Kullanıcı Ad Güncelle
                </button>
              </div>
              <div class="col-4">
                <button class="w-100 btn btn-warning btn-lg mb-4" type="button" @click="handleEmailDegistir">
                  Email Güncelle
                </button>
              </div>
              <div class="col-4">
                <button class="w-100 btn btn-danger btn-lg mb-4" type="button" @click="handleParolaDegistir">
                  Parola Güncelle
                </button>
              </div>
            </div>

            <hr />
            <div class="text-center text-danger"></div>
          </form>
        </div>
      </div>
    </main>
  </div>
  <AdminFooter/>
</template>

<script>
import { ref } from "vue";
import { auth, firestore } from "@/firebase/config";
import { updateProfile, updateEmail,updatePassword } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { notify } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";
import useLogout from "@/composables/useLogout";
import AdminFooter from '@/components/AdminFooter.vue'
import AdminNavbar from "@/components/AdminNavbar.vue";

export default {
  components:{
AdminFooter,
AdminNavbar
  },
  setup() {
    const router = useRouter();
    const kAd = ref('');
    const email = ref('');
    const parola=ref('');

    const {logout}=useLogout();

    const handleKullaniciAdDegistir = () => {
      if (kAd.value !== "") {
        updateProfile(auth.currentUser, {
          displayName: kAd.value,
        })
          .then(() => {
            updateDoc(doc(firestore, "kullanicilar", auth.currentUser.uid), {
              kullaniciAd: kAd.value,
            })
              .then(() => {
                notify({
                  title: "Güncellendi",
                  text: "Kullanıcı adınız güncellendi",
                  type: "success",
                  duration: 2000,
                });
                setTimeout(() => {
                  router.go(0);
                }, 2500);
              })
              .catch((err) => {
                notify({
                  title: "UPPSSSS! Bir Hata Oluştu",
                  text: "Kullanıcı adınız Güncellenmedi",
                  type: "error",
                  duration: 2000,
                });
              });
          })
          .catch((err) => {
            notify({
              title: "UPPSSSS! Bir Hata Oluştu",
              text: "Kullanıcı adınız Güncellenmedi",
              type: "error",
              duration: 2000,
            });
          });
      }
    };

    const handleEmailDegistir = () => {

        // const credential =EmailAuthCredential.credential(auth.currentUser.email,'Parola')

        // reauthenticateWithCredential(auth.currentUser,credential).then(()=>{

        // })

      if (email.value !== "") {
        updateEmail(auth, email.value)
          .then(() => {
            updateDoc(doc(firestore, "kullanicilar", auth.currentUser.uid), {
              email: email.value,
            })
              .then(() => {
                notify({
                  title: "Güncellendi",
                  text: "Email bilginiz güncellendi",
                  type: "success",
                  duration: 2000,
                });
              })
              .catch((err) => {
                notify({
                  title: "UPPSSSS! Bir Hata Oluştu",
                  text: "Email Adresiniz Güncellenmedi",
                  type: "error",
                  duration: 2000,
                });
              });
          })
          .catch((err) => {
            
            notify({
              title: "UPPSSSS! Bir Hata Oluştu",
              text: "Email Adresiniz Güncellenmedi",
              type: "error",
              duration: 2000,
            });
          });
      }
    };

    const handleParolaDegistir =()=>{
        if (parola.value !== "") {
            updatePassword(auth.currentUser,parola.value).then(()=>{
                notify({
                  title: "Güncellendi",
                  text: "Email bilginiz güncellendi",
                  type: "success",
                  duration: 2000,
                })
                setTimeout(async() => {
                    await logout();
                  router.go(0);
                }, 2500);
            }).catch((err)=>{
                notify({
                  title: "UPPPSSSS!!! Bir Hata Oluştur",
                  text: "Parola güncellendi",
                  type: "error",
                  duration: 2000,
                })
            })
        }
    }
    return { kAd, handleKullaniciAdDegistir,handleEmailDegistir,email,handleParolaDegistir,parola };
  },
};
</script>

<style>
</style>