<template>
  <Navbar />
  <ScrollButton />
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>

  <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <i class="fa fa-home"></i>
              <p>S.S. Hasköy</p>
              <h4>İletişim</h4>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->
  <div class="body-inner">
    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <h3 class="section-sub-title">Lokasyonumuz</h3>
          </div>
        </div>
        <!--/ Title row end -->

        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="ts-service-box-bg text-center h-100">
              <span class="ts-service-icon icon-round">
                <i class="fas fa-map-marker-alt mr-0"></i>
              </span>
              <div class="ts-service-box-content">
                <h4>Hasköy Sanayi Sitesi <br />İdari Bina</h4>
                <p>Sultanorhan Mah. No:15 Gebze/KOCAELİ</p>
              </div>
            </div>
          </div>
          <!-- Col 1 end -->

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="ts-service-box-bg text-center h-100">
              <span class="ts-service-icon icon-round">
                <i class="fa fa-envelope mr-0"></i>
              </span>
              <div class="ts-service-box-content">
                <h4>Email</h4>
                <p>gebzehaskoysanayi@gmail.com</p>
                <p>info@gebzehaskoy.com</p>
              </div>
            </div>
          </div>
          <!-- Col 2 end -->

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="ts-service-box-bg text-center h-100">
              <span class="ts-service-icon icon-round">
                <i class="fa fa-phone-square mr-0"></i>
              </span>
              <div class="ts-service-box-content">
                <h4>Telefon</h4>
                <p>(+90) 262 644 1319-20</p>
                <p>(+90) 537 526 5041</p>
              </div>
            </div>
          </div>
          <!-- Col 3 end -->
        </div>
        <!-- 1st row end -->

        <div class="gap-60"></div>
        <div class="ratio ratio-16x9">
          <div class="ratio ratio-16x9">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d377.52149471105673!2d29.4707850236554!3d40.80221491732383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb2118a823af45%3A0xd4c238378aee63d6!2sHCP%20Pompa%20Sistemleri!5e0!3m2!1str!2str!4v1707909402405!5m2!1str!2str"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div class="gap-40"></div>

        <div class="row">
          <div class="col-md-12">
            <h3 class="column-title">Talepleriniz</h3>
            <!-- contact form works with formspree.io  -->
            <!-- contact form activation doc: https://docs.themefisher.com/constra/contact-form/ -->
            <form
              id="contact-form"
              action="#"
              method="post"
              role="form"
              @submit.prevent="handleSubmit"
            >
              <div class="error-container"></div>
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12">
                  <div class="form-group">
                    <label>İsim Soyisim *</label>
                    <input
                      class="form-control form-control-name"
                      id="isim"
                      v-model="isim"
                      placeholder=""
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                  <div class="form-group">
                    <label>Email *</label>
                    <input
                      class="form-control form-control-email"
                      v-model="email"
                      id="email"
                      placeholder=""
                      type="email"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                  <div class="form-group">
                    <label>Telefon *</label>
                    <input
                      class="form-control form-control-telNo"
                      v-model="telNo"
                      id="telNo"
                      placeholder=""
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12">
                  <label>Kurum *</label>  
                  <input
                      type="text"
                      class="form-control mt-2"
                      id="kurum"
                      placeholder="İlgili Kurum *"
                      v-model="kurum"
                      required
                    />
                  </div>
                <div class="col-lg-9 col-md-9 col-sm-12">
                  <div class="form-group">
                    <label>Konu *</label>
                    <input
                      class="form-control form-control-subject"
                      v-model="konu"
                      type="text"
                      id="konu"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12">
                  <div class="form-group">
                    <label>Mesaj *</label>
                    <textarea
                      class="form-control form-control-message"
                      v-model="mesaj"
                      id="mesaj"
                      placeholder=""
                      rows="10"
                      required
                    ></textarea>
                  </div>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12">
                  <input
                    type="checkbox"
                    id="chk_user"
                    class="checkbox-custom"
                    required
                  />
                  <label for="chk_user" class="checkbox-custom-label ml-2">
                    <router-link to="/kvkk" target="_blank"
                      >S.S. Hasköy Kişisel Verilerin Korunması Aydınlatma
                      Metni’ni okudum</router-link
                    >
                  </label>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-12">
                  <div class="text-right">
                    <br />
                    <button class="btn btn-submit solid blank" type="submit">
                      Gönder
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- Content row -->
      </div>
      <!-- Conatiner end -->
    </section>
    <!-- Main container end -->
  </div>
  <!-- Body inner end -->

  <Footer />
</template>


<script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { notify } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Loading,
    Footer,
    Navbar,
    ScrollButton,
  },
  data() {
    return {};
  },

  setup() {
    const email = ref("");
    const telNo = ref("");
    const isim = ref("");
    const olusturulmaTarihi = ref("");
    const okundu = ref(false);
    const konu = ref("");
    const mesaj = ref("");
    const kurum = ref("");
    const oneriTalep = ref("");
    const isLoading = ref(false);
    const router=useRouter();

    const handleSubmit = async () => {
      isLoading.value = true;
      const myDocumentData = {
        email: email.value,
        telNo: telNo.value,
        isim: isim.value,
        okundu: okundu.value,
        konu: konu.value,
        mesaj: mesaj.value,
        kurum: kurum.value,
        olusturulmaTarihi: serverTimestamp(),
        oneriTalep: "Talep",
        kvkk: true,
      };

      await addDoc(collection(firestore, "mesajlar"), myDocumentData)
        .then(() => {
          isLoading.value = false;
          notify({
            title: "Başarılı",
            text: "Gönderildi",
            type: "success",
            duration: 2000,
          });
        })
        .catch(() => {
          isLoading.value = false;
          notify({
            title: "Uppppsss Hata Oluştu",
            text: "Mesajınız gönderilemedi.",
            type: "error",
            duration: 2000,
          });
        });
      isLoading.value = false;
      window.location.reload();

    };

    return {
      email,
      telNo,
      isim,
      okundu,
      konu,
      mesaj,
      kurum,
      olusturulmaTarihi,
      oneriTalep,
      handleSubmit,
      isLoading,
    };
  },
};
</script>
<style scoped>
.btn-submit {
  background: #001e57;
  color: #fff;
}
@media (max-width: 767px) {
  .col-sm-12 {
    margin-bottom: 20px;
  }
}
</style>