<template>
  <AdminNavbar/>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>

  <main class="container" v-if="kullanici">
    <div class="py-5 text-center">
      <div class="alert alert-dark" role="alert">
        <h2>Kullanıcı Yetkilendirme Sayfası</h2>
        <p class="lead">
          <router-link to="/new-user-list"
            >Yeni Kullanıcı Sayfası için tıklayınız</router-link
          >
        </p>
      </div>
    </div>

    <div class="row g-5">
      <div class="col-md-5 col-lg-4 order-md-last">
        <h4 class="d-flex justify-content-between align-items-center mb-3">
          <span class="text-primary">Kullanıcı Resim</span>
        </h4>
        <img class="rounded img-thumbnail" alt="..." :src="resimUrl" />
      </div>
      <div class="col-md-7 col-lg-8">
        <h4 class="mb-3 text-primary">Kullanıcı Bilgileri</h4>
        <form class="needs-validation" @submit.prevent="handleClick">
          <div class="row g-3">
            <div class="col-sm-12">
              <small class="text-muted">Üyelik Tarihi : </small>
              <label class="form-label"> {{ olusturulmaTarihi }}</label>
            </div>
            <div class="col-sm-6">
              <small class="text-muted">Kullanıcı Ad: </small>
              <label class="form-label"> {{ kAd }} </label>
            </div>
            <div class="col-sm-6">
              <small class="text-muted">Email : </small>
              <label class="form-label"> {{ email }}</label>
            </div>

            <div class="col-sm-6">
              <small class="text-muted">İsim : </small>
              <label class="form-label">{{ isim }} </label>
            </div>
            <div class="col-sm-6">
              <small class="text-muted">Soyisim : </small>
              <label class="form-label"> {{ soyisim }} </label>
            </div>

            <div class="col-sm-6">
              <small class="text-muted">Telefon No : </small>
              <label class="form-label"> {{ telefonNo }} </label>
            </div>

            <div class="col-sm-6">
              <small class="text-muted">Adres : </small>
              <label class="form-label"> {{ adres }} </label>
            </div>
          </div>
          <hr class="my-4" />

          <button :disabled="buttonDisabled" class="w-100 btn btn-warning btn-lg" type="submit">
            Yetkilendir
          </button>
        </form>
      </div>
    </div>
  </main>

  <main class="container" v-if="!kullanici">
    <div class="py-5 text-center">
      <div class="alert alert-dark" role="alert">
        <h2>Kullanıcı Bulunamadı</h2>
        <p class="lead">
          <router-link to="/new-user-list"
            >Yeni Kullanıcı Sayfası için tıklayınız</router-link
          >
        </p>
      </div>
    </div>
  </main>
  <AdminFooter/>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { firestore } from "@/firebase/config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { notify } from "@kyvg/vue3-notification";
import AdminFooter from '@/components/AdminFooter.vue'
import AdminNavbar from "@/components/AdminNavbar.vue";

export default {
  components: {
    Loading,
    AdminFooter,
    AdminNavbar
  },
  setup() {
    moment.locale("tr");

    const route = useRoute();
    const router = useRouter();

    const kullanici = ref(null);
    const isLoading = ref(true);

    const kAd = ref("");
    const isim = ref("");
    const soyisim = ref("");
    const email = ref("");
    const telefonNo = ref("");
    const olusturulmaTarihi = ref("");
    const adres = ref("");
    const resimUrl = ref("");

    const kId = ref(route.params.userId);

    const buttonDisabled = ref(false);

    onMounted(async () => {
      const docRef = doc(firestore, "kullanicilar", kId.value);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        kAd.value = docSnap.data().kullaniciAd;
        email.value = docSnap.data().email;
        olusturulmaTarihi.value = docSnap.data().olusturulmaTarihi.toDate();
        isim.value = docSnap.data().isim;
        soyisim.value = docSnap.data().soyisim;
        telefonNo.value = docSnap.data().telefonNo;
        adres.value = docSnap.data().adres;
        resimUrl.value = docSnap.data().profilResim;

        olusturulmaTarihi.value = moment(olusturulmaTarihi.value).format("LL");
        isLoading.value = false;
        kullanici.value = true;
      } else {
        isLoading.value = false;
        kullanici.value = false;
      }
    });

    const handleClick = async () => {
      isLoading.value = true;
      buttonDisabled.value = true;

      const docRef = doc(firestore, "kullanicilar", kId.value);
      updateDoc(docRef, {
        yetki: "uye",
      })
        .then(() => {
          isLoading.value = false;
          notify({
            title: "Kullanıcı yetkilendirildi.",
            text: "Kullanıcıyı yetkilendirdiniz. Dashboard sayfasına yönlendiriliyorsunuz.",
            type: "success",
            duration: 2000,
          });
          setTimeout(() => {
            router.push("/dashboard");
          }, 2500);
        })
        .catch((err) => {
          isLoading.value = false;
          notify({
            title: "Bir Hata Oluştu.",
            text: "Kullanıcıyı yetkilendirilemedi",
            type: "error",
            duration: 2000,
          });
        });
    };

    return {
      kAd,
      email,
      olusturulmaTarihi,
      isim,
      soyisim,
      adres,
      telefonNo,
      resimUrl,
      isLoading,
      kullanici,
      handleClick,buttonDisabled
    };
  },
};
</script>

<style>
</style>