<template>
  <AdminNavbar/>
  <loading
    v-mode:active="isLoading"
    :is-full-page="true"
    color="#ffc300"
  ></loading>
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Yeni Kullanıcı Listesi</h2>
        </div>
      </div>

      <div class="row g-5">
        <div class="col-md-12 col-lg-12">
          <h4 class="mb-3">Yeni Üye Olmuş Kullanıcılar</h4>
          <div
            class="row justify-content-md-center"
            v-if="kullanicilar.length !== 0"
          >
            <ol class="list-group list-group-numbered">
              <li
                class="list-group-item d-flex justify-content-between align-items-start"
                v-for="k in kullanicilar"
                :key="k.id"
              >
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Email: {{ k.email }}</div>
                  Kullanıcı Ad: {{ k.kullaniciAd }} - İsim Soyisim:
                  {{ k.isim }} {{ k.soyisim }}
                </div>
                <router-link :to="{name:'UserDetail', params:{userId:k.id}}" class="badge bg-primary rounded-pill"
                  ><i class="bi bi-search"></i
                ></router-link>
              </li>
            </ol>
          </div>
          <div
            class="row justify-content-md-center"
            v-if="kullanicilar.length == 0"
          >
            <h3>{{ bosMesajı }}</h3>
          </div>
        </div>
      </div>
    </main>
  </div>
  <AdminFooter/>
</template>

<script>
import { ref, onMounted } from "vue";
import { auth, firestore } from "@/firebase/config";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
  doc,
} from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AdminFooter from '@/components/AdminFooter.vue'
import AdminNavbar from "@/components/AdminNavbar.vue";

export default {
  components: {
    Loading,
    AdminFooter,
    AdminNavbar
  },
  setup() {
    const kullanicilar = ref([]);
    const isLoading = ref(true);

    const bosMesajı = ref("Yeni Kullanıcı Bulunamadı");

    onMounted(async () => {
      let q = query(
        collection(firestore, "kullanicilar"),
        where("yetki", "==", "basit"),
        where("durum", "==", true),
        orderBy("olusturulmaTarihi", "desc"),
        limit(20)
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      kullanicilar.value = veri;
      isLoading.value = false;
    });
    return { kullanicilar, isLoading, bosMesajı };
  },
};
</script>

<style scoped>
.container {
  max-width: 750px;
}
</style>