import { ref } from "vue";
import { auth } from "@/firebase/config";
import { signOut } from "firebase/auth";

const hataLogut =ref(null);

const logout =async ()=>{
    hataLogut.value=null;

    try {
        const res= await signOut(auth)
        hataLogut.value=null;
        return res;

    } catch (error) {
        hataLogut.value=error.message;
    }
}

const useLogut=()=>{
    return {hataLogut,logout}
}

export default useLogut;