<template>
    <Navbar/>
    <ScrollButton/>
    <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <i class="fa fa-home"></i>
              <p>S.S. Hasköy</p>
              <h4>Kurucu Kurulu</h4>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->
  
  <div id="about-3" class="about-section gray-bg section-padding">
    <div class="container">
      <div class="row align-items-center mb-5 ">
          <div class="col-xl-8 col-lg-8 col-md-5">
            <div class="about-content-wrap">
              <div class="section-title">
                <h6>Kurucu Kurulu Üyesi</h6>
                <h2>Yilmaz ERIS</h2>
              </div>
            </div>
          </div>
          <div class="col-xl-8 col-lg-8 col-md-5 mt-3">
            <div class="about-content-wrap">
              <div class="section-title">
                <h6>Kurucu Kurulu Üyesi</h6>
                <h2>Ahmet OZAN</h2>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-5 mt-3">
            <div class="about-content-wrap">
              <div class="section-title">
                <h6>Kurucu Kurulu Üyesi</h6>
                <h2>Ahmet BEKTAS</h2>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-5 mt-3">
            <div class="about-content-wrap">
              <div class="section-title">
                <h6>Kurucu Kurulu Üyesi</h6>
                <h2>Mehmet T. TOPRAK</h2>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-5 mt-3">
            <div class="about-content-wrap">
              <div class="section-title">
                <h6>Kurucu Kurulu Üyesi</h6>
                <h2>Orhan ERTÜRK</h2>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  
    <Footer />
  </template>
  
  <script>
   import { ref, onMounted } from "vue";
import { auth, firestore } from "@/firebase/config";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";

import ConfirmDialog from "@/components/ConfirmDialog.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Loading,
    ConfirmDialog,
    Footer,
    Navbar,
    ScrollButton
  },
  setup() {
    const kurcu = ref([]);
    const isLoading = ref(true);

    const bosMesajı = ref("Liste Bulunamadı");

    onMounted(async () => {
      let q = query(
        collection(firestore, "kurulListe"),
        where("tur", "==", "Kurucu")
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      kurcu.value = veri;
      isLoading.value = false;
    });

    return {
      kurcu,
      isLoading,
      bosMesajı,
    };
  },
};
  </script>
  
  <style scoped>
  .about-content-wrap{
    border-bottom: 2px solid #001e57;
  }
  </style>