<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>

  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Eklenen Reklamlar</h2>

          <router-link to="/admin/add-ads" class="btn btn-secondary"
            >Reklam Ekle</router-link
          >
        </div>
      </div>

      <custom-table
        :rows="displayedAds"
        @updateButtonClick="updateHandler($event)"
        @deleteButtonClick="deleteHandler($event)"
        :update-button-visible="true"
        :delete-button-visible="true"
        :actionsButtonsVisible="true"
        :columns="tableColumns"
      >
      </custom-table>
    </main>
    <div class="pagination-admin">
      <button @click="prevPage" v-if="currentPage > 1">
        <i class="bi bi-chevron-double-left"></i>
      </button>
      <button
        v-for="pageNumber in totalPages"
        :key="pageNumber"
        @click="goToPage(pageNumber)"
        :class="{ active: currentPage === pageNumber }"
      >
        {{ pageNumber }}
      </button>
      <button @click="nextPage" v-if="currentPage < totalPages">
        <i class="bi bi-chevron-double-right"></i>
      </button>
    </div>
  </div>
  <AdminFooter />
</template>
  
  <script>
import { ref, onMounted,computed } from "vue";
import { auth, firestore } from "@/firebase/config";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
import CustomTable from "@/components/Table.vue";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import "vue-loading-overlay/dist/vue-loading.css";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    Loading,
    VueFinalModal,
    ModalsContainer,
    AdminFooter,
    AdminNavbar,
    CustomTable,
  },
  data() {
    return {
      tableColumns: [
        { label: "Firmanın Adı", value: "firmaAd" },
        { label: "Reklam Başlık", value: "baslik" },
        { label: "Firma Logo", value: "logo" },
        { label: "Firma Telefon No", value: "telNo" },
        { label: "Firma Web Adres", value: "webAdres" },
        { label: "Durum", value: "durum" },
      ],
    };
  },
  methods: {
    updateHandler(data) {
      console.log("update", data);
      console.log(data.item.id);

      this.$router.push({
        name: "AdsDetail",
        params: { reklamId: data.item.id },
        props: true,
      });
    },
  },
  setup() {
    const reklamlar = ref([]);
    const isLoading = ref(true);
    const router = useRouter();
    const showModal = ref(false);
    const hataMesaj = ref("");
    const currentPage = ref(1);
    const pageSize = 5; // Sayfa başına öğe sayısı
    const totalItems = ref(null); // Toplam öğe sayısı

    const totalPages = computed(() => Math.ceil(totalItems.value / pageSize));

    const displayedAds = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      console.log(reklamlar.value.slice(startIndex, endIndex));
      return reklamlar.value.slice(startIndex, endIndex);
    });
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        // Sayfaya göre verileri güncelleme işlemleri burada yapılabilir
      }
    };
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        goToPage(currentPage.value);
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        goToPage(currentPage.value);
      }
    };

    const deleteHandler = async (data) => {
      isLoading.value = true;
      console.log("delete2", data.item.id);
      const myCollection = collection(firestore, "reklamlar");
      const myDocRef = doc(myCollection, data.item.id);
      try {
        const data = {
          durum: false,
        };
        await updateDoc(myDocRef, data);
        router.go(0);
        isLoading.value = false;
      } catch (error) {
        hataMesaj.value = error.message;
        isLoading.value = false;
        showModal.value = true;
      }
    };
    onMounted(async () => {
      let q = query(
        collection(firestore, "reklamlar"),
        orderBy("olusturulmaTarihi", "desc"),
        where("durum", "==", true),
        limit(20)
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      reklamlar.value = veri;
      isLoading.value = false;
      totalItems.value = veri.length;
    });

    return {
      reklamlar,
      isLoading,
      deleteHandler,
      showModal,
      hataMesaj,
      currentPage,
      totalPages,
      goToPage,
      displayedAds,
      prevPage,
      nextPage,
    };
  },
};
</script>
  
  <style scoped>
</style>