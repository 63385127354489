<template>
  <ScrollButton />
  <div class="container">
    <div id="service-1" class="service-section section-padding pt-40">
      <div class="row align-items-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="section-title">
            <h3>
              Küçük sanayi siteleri, endüstrimizin bütünleyici unsurlarıdır.
            </h3>
          </div>
        </div>
      </div>
      <div class="row mt-60">
        <div
          class="col-xl-3 col-lg-3 col-md-6 col-sm-6 wow fadeInUp"
          data-wow-delay=".2s"
          style="
            visibility: visible;
            animation-delay: 0.2s;
            animation-name: fadeInUp;
          "
        >
          <div class="single-service-item rounded-2">
            <div class="service-icon">
              <span class="center">
                <img src="/images/slider/slider10.jpg" class="size rounded-2" />
              </span>
            </div>
            <div class="service-title">
              <h4>
                Bir memleket sanayiyle<br />
                kalkınıyor<br />
              </h4>
            </div>
            <div class="box-size">
              <router-link to="/content-first" class="read_more_link">
                <span class="link_text">Devamını oku</span>
                <span class="link_icon">
                  <i class="bi bi-arrow-right"></i>
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="col-xl-3 col-lg-3 col-md-6 col-sm-6 wow fadeInUp"
          data-wow-delay=".4s"
          style="
            visibility: visible;
            visibility: visible;
            animation-delay: 0.4s;
            animation-name: fadeInUp;
          "
        >
          <div class="single-service-item rounded-2">
            <div class="service-icon">
              <span class="center">
                <img src="/images/slider/1.jpg" class="size rounded-2" />
              </span>
            </div>
            <div class="service-title">
              <h4>Her yönüyle sanayi kenti; <br />KOCAELİ</h4>
            </div>
            <div class="box-size">
              <router-link to="/content-second" class="read_more_link">
                <span class="link_text">Devamını oku</span>
                <span class="link_icon">
                  <i class="bi bi-arrow-right"></i>
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="col-xl-3 col-lg-3 col-md-6 col-sm-6 wow fadeInUp"
          data-wow-delay=".6s"
          style="
            visibility: visible;
            visibility: visible;
            animation-delay: 0.6s;
            animation-name: fadeInUp;
          "
        >
          <div class="single-service-item rounded-2">
            <div class="service-icon">
              <span class="center">
                <img src="/images/slider/slider3.jpg" class="size rounded-2" />
              </span>
            </div>
            <div class="service-title">
              <h4>Kazanan <br />Türkiye olacak<br /><br /></h4>
            </div>
            <div class="box-size">
              <router-link to="/content-third" class="read_more_link">
                <span class="link_text">Devamını oku</span>
                <span class="link_icon">
                  <i class="bi bi-arrow-right"></i>
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="col-xl-3 col-lg-3 col-md-6 col-sm-6 wow fadeInUp"
          data-wow-delay=".8s"
          style="
            visibility: visible;
            visibility: visible;
            animation-delay: 0.8s;
            animation-name: fadeInUp;
          "
        >
          <div class="single-service-item rounded-2">
            <div class="service-icon">
              <span class="center">
                <img src="/images/slider/slider11.jpg" class="size rounded-2" />
              </span>
            </div>
            <div class="service-title">
              <h4>
                Yan sanayi ihtiyacını karşılayan<br />
                KÜÇÜK DEV
              </h4>
            </div>
            <div class="box-size">
              <router-link to="/content-fourth" class="read_more_link">
                <span class="link_text">Devamını oku</span>
                <span class="link_icon">
                  <i class="bi bi-arrow-right"></i>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import ScrollButton from "./ScrollButton.vue";
export default {
  components: {
    ScrollButton,
  },
};
</script>
  
  <style scoped>
.size {
  width: 200px;
  height: 150px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .size {
    width: 200px;
  height: 150px;
}
}
@media (max-width: 767px) {
  .size {
    width: 200px;
  height: 150px;
}
}
.center {
  display: flex;
  justify-content: center;
}
.box-size {
  max-height: 50px;
}
.pt-40 {
  padding-top: 40px;
}
.section-padding {
  padding: 60px 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-padding {
    padding: 100px 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding {
    padding: 90px 30px;
  }
}
@media (max-width: 767px) {
  .section-padding {
    padding: 70px 10px;
  }
}
/*Section Title */
.section-title {
  position: relative;
  margin-bottom: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .section-title {
    margin-bottom: 10px;
  }
}

.section-title h1 {
  font-size: 70px;
  font-weight: 600;
  line-height: 75px;
  letter-spacing: -2px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title h1 {
    font-size: 60px;
    line-height: 65px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h1 {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .section-title h1 {
    font-size: 40px;
    line-height: 50px;
  }
}

.section-title h2 {
  font-size: 60px;
  font-weight: 500;
  line-height: 65px;
  color: #181d4e;
  letter-spacing: -1px;
  margin-top: 20px;
  text-transform: none;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title h2 {
    font-size: 55px;
    line-height: 60px;
    margin: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2 {
    font-size: 45px;
    line-height: 50px;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .section-title h2 {
    font-size: 35px;
    line-height: 45px;
    margin: 15px 0;
  }
}

.section-title h3 {
  font-size: 36px;
  line-height: 40px;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .section-title h3 {
    font-size: 30px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h3 {
    font-size: 36px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .section-title h3 {
    font-size: 24px;
    line-height: 30px;
  }
}

.section-title h6 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 10px;
  opacity: 0.5;
}

.section-title p {
  margin: 0;
}

.section-title span {
  color: #fa4729;
}

.read_more_link {
  position: relative;
  display: inline-block;
  top: auto;
  left: auto;
  height: auto;
  width: auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #181d4e;
  z-index: 1;
  overflow: hidden;
}

.read_more_link .link_text {
  position: relative;
  margin-right: 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
  text-indent: -110px;
  visibility: hidden;
  white-space: nowrap;
  opacity: 0;
  transition: visibility 0.4s ease, margin-right 0.4s ease-out,
    text-indent 0.5s ease-out, opacity 0.4s ease;
  will-change: visibility, margin-right, text-indent, opacity;
}

.read_more_link .link_icon i {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  font-size: 26px;
  line-height: 20px;
  text-align: center;
}
.single-service-item:hover .read_more_link .link_text {
  opacity: 1;
  text-indent: 0;
  margin-right: 6px;
  visibility: visible;
  transition: visibility 0.4s ease, margin-right 0.3s ease-out,
    text-indent 0.4s ease-out, opacity 0.4s ease;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #service-1.pt-60 {
    padding-top: 0;
  }
}
@media (max-width: 767px) {
  #service-1.pt-60 {
    padding-top: 0;
  }
}
.single-service-item:hover .read_more_link .link_text {
  opacity: 1;
  text-indent: 0;
  margin-right: 6px;
  visibility: visible;
  transition: visibility 0.4s ease, margin-right 0.3s ease-out,
    text-indent 0.4s ease-out, opacity 0.4s ease;
}
#service-1 .single-service-item {
  border: 1px solid #ddd;
  padding: 20px 25px 0px 30px;
  width: 280px;
  height: 370px;
}
@media only screen and (min-width:991px) and (max-width: 1200px) {
  #service-1 .single-service-item {
    width: 230px;
    height: 380px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #service-1 .single-service-item {
    width: 280px;
    height: 370px;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  #service-1 .single-service-item {
    padding: 20px 30px 10px 30px;
    margin-top: 10px;
  }
}
#service-1 .single-service-item .service-icon i:before {
  font-size: 60px;
  color: #fa4729;
}
#service-1 .single-service-item .service-title h4 {
  color: #001e57;
  letter-spacing: 0;
  margin: 40px 0;
  text-transform: none;
  max-height: 150px;
}
#service-1 .single-service-item:hover .service-icon i:before {
  color: #0b0d26;
}
</style>