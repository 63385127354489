<template>
   <Slider />
      <News />
      <TotalMember />
      <Ads />
      <Footer />
      <ScrollButton/>
</template>

<script>
import Slider from "@/components/Slider.vue";
import Footer from "@/components/Footer.vue";
import Ads from "@/components/Ads.vue";
import News from "@/components/News.vue";
import TotalMember from "@/components/TotalMember.vue";
import ScrollButton from "@/components/ScrollButton.vue";


export default {
  components: {
    Slider,
    Footer,
    Ads,
    News,
    TotalMember,
    ScrollButton
  },
 
};
</script>

