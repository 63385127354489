<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="fileHata">{{ fileHata }}</div>
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Kurul Üye Bilgilerini Güncelle</h2>
          <p class="lead">
            <router-link to="/admin/board-list"
              >Kurul Üye Listesi içn Tıklayınız</router-link
            >
          </p>
        </div>
      </div>

      <div class="row g-5">
        <div class="col-md-8 col-lg-8">
          <h4 class="mb-3">Kurul Üye Bilgileri</h4>
          <div class="d-flex justify-content-between">
            <p>Üyelik tarihi: {{ tarih }}</p>
          </div>
          <form class="needs-validation" @submit.prevent="handleSubmit">
            <div class="row g-3">
              <div class="col-sm-6">
                <label for="isim" class="form-label">İsim Soyisim</label>
                <input
                  type="text"
                  class="form-control"
                  id="isim"
                  placeholder=""
                  v-model="isim"
                />
              </div>

              <div class="col-sm-6">
                <label for="baslik" class="form-label">Ünvan</label>
                <input
                  type="text"
                  class="form-control"
                  id="baslik"
                  placeholder=""
                  v-model="baslik"
                />
              </div>
              <div class="col-sm-6">
                <label for="sira" class="form-label">Sıra</label>
                <input
                  type="text"
                  class="form-control"
                  id="sira"
                  placeholder="1,2, vs."
                  v-model="sira"
                />
              </div>

              <div class="col-6">
                <label for="tur" class="form-label">Tür {{ selected }}</label>
                <select class="form-select" v-model="selected">
                  <option
                    v-for="option in options"
                    :key="option"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="col-6">
                Durum <br />
                <div>
                  <input
                    type="radio"
                    id="aktif"
                    :value="true"
                    name="durumRadio"
                    v-model="durum"
                    :checked="durum == true"
                  />
                  <label for="aktif" class="form-label"> Aktif </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="pasif"
                    :value="false"
                    name="durumRadio"
                    v-model="durum"
                    :checked="durum == false"
                  />
                  <label for="pasif" class="form-label"> Pasif </label>
                </div>
              </div>
            </div>

            <hr class="my-4" />

            <button class="w-100 btn btn-success btn-lg mb-4" type="submit">
              Güncelle
            </button>
          </form>
        </div>

        <div class="col-md-4 col-lg-4 order-md-last">
          <h4 class="d-flex justify-content-between align-items-center mb-3">
            <span>Resim</span>
          </h4>
          <img :src="resimUrl" class="rounded img-thumbnail" ref="resim" />
        </div>
      </div>
    </main>
  </div>
  <AdminFooter />
</template>
    
    <script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { doc, getDoc, updateDoc, collection } from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { notify } from "@kyvg/vue3-notification";
import { useRoute, useRouter } from "vue-router";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";

export default {
  components: {
    Loading,
    AdminFooter,
    AdminNavbar,
    VueFinalModal,
    ModalsContainer,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const showModal = ref(false);
    const isLoading = ref(true);
    const kId = ref(route.params.kurulId);
    const tarih = ref("");

    const olusturulmaTarihi = ref("");
    const isim = ref("");
    const baslik = ref("");
    const tur = ref("");
    const sira = ref("");
    const durum = ref(false);
    const resimUrl = ref("");
    const fileHata = ref(null);
    const hataMesaj = ref("");

    const resim = ref(null);

    const selected = ref("");
    const options = ref([
      { text: "Yönetim", value: "Yönetim" },
      { text: "Denetim", value: "Denetim" },
    ]);

    const bilgiKontrol = ref(false);
    moment.locale("tr");

    onMounted(async () => {

      const docRef = doc(firestore, "kurulListe", kId.value);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        olusturulmaTarihi.value = docSnap.data().olusturulmaTarihi.toDate();
        isim.value = docSnap.data().isim;
        baslik.value = docSnap.data().baslik;
        sira.value = docSnap.data().sira;
        tur.value = docSnap.data().tur;
        resimUrl.value = docSnap.data().profilResim;
        durum.value = docSnap.data().durum;

        isLoading.value = false;
        tarih.value = moment(olusturulmaTarihi.value).fromNow();
        selected.value=tur.value;
        console.log(docSnap.data());
      } else {
        console.log("Veriye erişilemedi");
      }
    });

    const handleSubmit = async () => {
      isLoading.value = true;
      const myCollection = collection(firestore, "kurulListe");
      const myDocRef = doc(myCollection, kId.value);
      try {
        const data = {
          isim: isim.value,
          baslik: baslik.value,
          tur: selected.value ,
          sira: sira.value ,
          durum: durum.value === true ? true : false,
        };
        await updateDoc(myDocRef, data);
        router.push("/admin/board-list");
      } catch (error) {
        hataMesaj.value = error.message;
        isLoading.value = false;
        showModal.value = true;
      }
    };

    return {
      tarih,
      kId,
      olusturulmaTarihi,
      isim,
      baslik,
      tur,
      sira,
      resimUrl,
      bilgiKontrol,
      isLoading,
      handleSubmit,
      hataMesaj,
      fileHata,
      resim,
      durum,
      showModal,
      selected,
      options
    };
  },
};
</script>
    
    <style>
</style>
    
    
    