<template>

  <div class="carousel slide" id="mySlide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="0"
        class="carousel-indicator-item active"
        aria-current="true"
        aria-label="ilk slayt"
      ></button>
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="1"
        class="carousel-indicator-item"
        aria-label="ikinci slayt"
      ></button>
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="2"
        class="carousel-indicator-item"
        aria-label="üçüncü slayt"
      ></button>
      <button
        type="button"
        data-bs-target="#mySlide"
        data-bs-slide-to="3"
        class="carousel-indicator-item"
        aria-label="dördüncü slayt"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          src="images/sanayi-resim/sanayi6r.jpg"
          class="d-block w-100"
          alt="slide-1"
        />
      </div>
      <div class="carousel-item">
        <img
          src="images/slider/slider10.jpg"
          class="d-block w-100"
          alt="slide-2"
        />
      </div>
      <div class="carousel-item">
        <img
          src="images/slider/slider4.jpg"
          class="d-block w-100"
          alt="slide-3"
        />
      </div>
      <div class="carousel-item">
        <img
          src="images/slider/slider6.jpg"
          class="d-block w-100"
          alt="slide-4"
        />
      </div>
    </div>

    <button
      type="button"
      class="carousel-control-prev"
      data-bs-target="#mySlide"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Önceki</span>
    </button>
    <button
      type="button"
      class="carousel-control-next"
      data-bs-target="#mySlide"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Sonraki</span>
    </button>
  </div>

</template>

<script>
export default {};
</script>

<style scoped>
.carousel {
  justify-content: center;
  z-index: 0;
}
.carousel-inner {
  position: relative;
  height: 400px;
  overflow: hidden;
}
.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.carousel-item h1 {
  margin: 0;
  color: white;
}
.carousel-item img {
  width: 100%;
  object-fit: cover;
}
.carousel-indicators {
  position: absolute;
  bottom: 1.5em;
  z-index: 2;
}
.carousel-indicator-item {
  width: 15px;
  height: 15px;
  border: none;
  background: #fff;
  opacity: 0.5;
  margin: 0.2em;
  border-radius: 50%;
  cursor: pointer;
}

.active {
  opacity: 1;
}
.carousel-control {
  background-color: white;
  border: none;
  display: inline-block;
  position: absolute;
  height: 50px;
  width: 70px;
  top: calc(50% - 25px);
  color: gray;
  cursor: pointer;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.carousel-caption {
  color: white;
  margin-bottom: 10px;
}
.carousel-caption h5 {
  color: white;
}
</style>

