<template>
  <Navbar />
  <ScrollButton/>
  <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <i class="fa fa-home"></i>
              <p>S.S. Hasköy</p>
              <h4>Tarihçe</h4>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->
  <div class="body-inner">
    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="column-title">Tarihçemiz</h3>
            <p>
              İstanbul Büyükşehir Belediyesi tarafından "Haliç imar Projesi
              Uygulaması" nedeniyle Hasköy'deki "Torna Döküm-Pres
              imalatçılarının işyerleri istimlak edilmiştir.
            </p>

            <p>
              Kooperatifimiz 1985 yılında, esnaf ve sanatkarların
              kooperatifleşmesi düşüncesi ile kurulmuş ve o dönemin Belediye
              Başkanı Sayın Bedrettin DALAN 'ın yardımları ile Arsa ofisinde
              arsa talep edilmiştir. Yasal işlemlerin sonucunda Pendik-Kurtköy
              mevkiinde 160 dönüm arazi bedeli ödenerek alınmıştır.
            </p>

            <p>
              10.09.1987 tarihinde Ana kent Belediye Başkanı ve Kartal belediye
              Başkanlarının huzurunda TRT Televizyonunda da yayınlanan temel
              atma töreni ile inşaata başlanmıştır.
            </p>
          </div>
          <!-- Col end -->
          <div class="col-lg-6 mt-5">
            <img src="/images/sanayi-resim/IMG_9367.jpg" class="mission-img" />
          </div>
          <div class="col-lg-12 mt-2">
            <p>
              1988 yılının ortalarına doğru 20 milyar TL harcama ile 70.000 m2
              alan üzerine 88 Bloktan oluşan 357 işyeri ve sosyal tesis binaları
              ile inşaatın %75'i bitirilmiş iken Hükümet tarafından planlanan
              "İleri Teknoloji Endüstrisi Parkı ve Havalanı inşaatı" sahası
              içine alınarak Bakanlar Kurulunca yıkım kararı çıkarılmıştır.
            </p>

            <p>
              30.03.1990 tarihinde Gebze sınırları içinde Arsa Ofisinden 149.000
              m2 yeni bir arsa alınmıştır.
            </p>
            <p>14.09.1990 tarihinde plan ve proje aşamasına gelindi.</p>
            <p>
              Otoyol Gebze Gişelerine sınır olan Sanayi Sitemiz, 08.10.1990
              yılında inşaata başlanmıştır.
            </p>
            <p>
              19.05.1994 tarihinde kanalizasyon, pis su, temiz su ihalisi
              yapılmıştır.
            </p>
            <p>
              10.10.1994 tarihinde Elektrik altyapı, Trafo Merkezleri ve
              Elektrik Ana Tesisi ihale edildi.
            </p>
            <p>
              05.01.1996 tarihinde çevre ve yol bordür yapımı ihalesi verildi.
            </p>
            <p>
              Günümüzde ise, yapılan çalışmalar neticesinde Sanayi Sitemiz
              faaliyetlerine devam etmektedir.
            </p>
          </div>
        </div>
        <!-- Content row end -->
      </div>
      <!-- Container end -->
    </section>
    <!-- Main container end -->
  </div>
  <!-- Body inner end -->
  <Footer />
</template>
  
  <script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Footer,
    Navbar,
    ScrollButton
  },
};
</script>
  
  <style scoped>
.mission-img {
  display: block;
  left: 20%;
  width: 500px;
  max-height: 150%;
}
.carousel {
  display: flex;
  justify-content: center;
}
.carousel-inner {
  position: relative;
  height: 200px;
  overflow: hidden;
}
.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.carousel-item img {
  justify-content: center;
}
.active {
  opacity: 1;
}
.carousel-caption {
  color: white;
  margin-bottom: 10px;
}
.carousel-caption h4 {
  color: white;
}
</style>