import { ref } from "vue";

import { auth } from "@/firebase/config";
import { createUserWithEmailAndPassword ,updateProfile} from "firebase/auth";

const hataSignup=ref(null)

const signup = async (email,parola,kAd) =>{
    hataSignup.value=null
    try {
        const res=await createUserWithEmailAndPassword(auth,email,parola)
        if(!res)
        {
            throw new Error('Signup hatası')
        }

        await updateProfile(res.user,{
            displayName:kAd
        })
        hataSignup.value=null
        return res;

    } catch (error) {
        hataSignup.value=error.message;
        
    }
}

const useSignup=()=>{
    return {hataSignup,signup}
}

export default useSignup