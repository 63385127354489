<template>
    <Navbar />
    <ScrollButton/>
    <div
      id="banner-area"
      class="banner-area"
      style="background-image: url(images/director.jpg)"
    >
      <div class="banner-text">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="banner-heading">
                <i class="fa fa-home"></i>
                <p>S.S. Hasköy</p>
                <h4>Kvkk</h4>
              </div>
            </div>
            <!-- Col end -->
          </div>
          <!-- Row end -->
        </div>
        <!-- Container end -->
      </div>
      <!-- Banner text end -->
    </div>
    <!-- Banner area end -->
    <div class="body-inner">
      <section id="main-container" class="main-container">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <h3 class="column-title">Kişisel Verilerin Korunması Aydınlatma Metni</h3>
              <p>
<b>S.S Hasköy Pik Dökümcü ve İşleme Sanatkarları Küçük Sanayi Sitesi İşletme Kooperatifi</b> kişisel verilerinizin hukuka uygun olarak toplanması, saklanması ve paylaşılmasını sağlamak ve gizliliğinizi korumak amacıyla mümkün olan en üst seviyede güvenlik tedbirlerini almaktadır.
Amacımız; 6698 sayılı Kişisel Verilerin Korunması Kanununun 10. maddesi gereğince ve sizlerin memnuniyeti doğrultusunda, kişisel verilerinizin alınma şekilleri, işlenme amaçları, paylaşılan kişiler, hukuki nedenleri ve haklarınız konularında sizi en şeffaf şekilde bilgilendirmektir.
<ol type="a">
  <li><b>Veri Sorumlusu</b>
    <p>6698 sayılı Kişisel Verilerin Korunması Kanunu (“6698 sayılı Kanun”) uyarınca, kişisel verileriniz; veri sorumlusu olarak S.S Hasköy Pik Dökümcü ve İşleme Sanatkarları Küçük Sanayi Sitesi İşletme Kooperatifi  (“KURUM”) 
tarafından aşağıda açıklanan kapsamda toplanacak ve işlenebilecektir.</p></li>
  <li><b>Kişisel Verilerinizin Toplanma Şekli</b>
  <p>Kişisel verileriniz,</p>
  <ul>
    <li>Kurumumuz internet sitesindeki formlar veya ad, soyad, iş veya özel e-posta adresi gibi bilgiler girilerek veya 
    kullanıcı adı ve şifresi kullanılarak giriş yapılan sayfalar aracılığıyla,</li>
    <li>Alt yapı ve diğer hizmetlerimizle ilgili bilgi almak amacıyla çalışanlarımız veya tedarikçilerimize, sözlü, yazılı, 
      elektronik olarak veya kağıt üzerindeki formlar aracılığıyla verdiğiniz bilgiler aracılığıyla,</li>
      <li>Kurumumuz ile ticari ilişki kurmak, iş başvurusu yapmak, teklif vermek gibi amaçlarla fiziki veya sanal bir ortamda, yüz yüze ya da mesafeli, sözlü veya 
        yazılı ya da elektronik olarak verilen kartvizit, özgeçmiş (cv), teklif aracılığıyla,</li>
        <li>Ayrıca farklı kanallardan dolaylı yoldan elde edilen, web sitesi, blog, anket, bilgilendirme yazıları ve benzeri amaçlı kullanılan web sitelerinden ve sosyal medyadan elde edilen veriler, kamuya açık veri tabanlarının sunduğu veriler, 
          sosyal medya platformlarından paylaşıma açık profil ve verilerden,</li>
  </ul>
          <p>toplanabilmekte ve işlenebilmektedir.</p>
<p>KVKK’ nın yürürlük tarihi olan 7 Nisan 2016 tarihinden önce, yukarıda açıklanmış yollarla 
  veya diğer şekillerde hukuka uygun olarak elde edilmiş olan kişisel verileriniz de bu belgede 
  düzenlenen şart ve koşullara uygun olarak işlenmekte ve muhafaza edilmektedir.
</p>
</li>
  <li><b>Kişisel Verilerinizin Ne Şekilde İşlenebileceği ve İşleme Amaçları</b>
   <p> 6698 sayılı KVKK uyarınca, Kurum ile paylaştığınız kişisel verileriniz, 
tamamen veya kısmen, otomatik olarak veyahut herhangi bir veri kayıt 
sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilerek, 
kaydedilerek, depolanarak, değiştirilerek, yeniden düzenlenerek, 
kısacası veriler üzerinde gerçekleştirilen her türlü işleme konu olarak 
tarafımızdan işlenebilecektir. KVKK kapsamında veriler üzerinde gerçekleştirilen 
her türlü işlem "kişisel verilerin işlenmesi” olarak kabul edilmektedir.</p>
<p>Kişisel verilerinizin hukuka aykırı olarak işlenmesinin ve 
verilerinize hukuka aykırı olarak erişilmesinin önlenmesi ve kişisel verilerinizin 
güvenli bir şekilde muhafaza edilmesi amacıyla gerekli her türlü teknikve idari tedbir alınmaktadır.</p>
<p>Kişisel verileriniz, Kurum hizmetlerini kullanmak ve takip etmek niyetiyle internet sayfamızı kullandığınızda, 
“S.S. Hasköy Kullanıcı Girişi” ve “Mesaj Gönder” portalına giriş yaptığınızda, kişisel verilerinizin işlenmesine 
yönelik rızanız ya da Kanun’un 5. ve 6. maddeleri ile sair mevzuatta belirtilen hukuki sebeplere dayanarak toplanabilir,
işlenebilir ve aktarılabilir. Kişisel verileriniz, açık rızanız bulunmadan başka bir amaçla kullanılmayacaktır.</p></li>
<li><b>İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</b>
<p>Toplanan kişisel verileriniz; Kurum tarafından sunulan hizmetlerden sizleri faydalandırmak için gerekli 
çalışmaların iş birimlerimiz tarafından yapılması, Kurumumuz tarafından sunulan hizmetlerin sizlerin talebi ve 
ihtiyaçlarına göre özelleştirilerek sizlere önerilmesi, Kurumumuzun ve Kurumumuzla iş ilişkisi içerisinde olan 
kişilerin hukuki ve ticari güvenliğinin temini (Kurumumuz tarafından yürütülen teknik ve idari operasyonlar, 
Kuruma ait lokasyonların fiziksel güvenliğini ve denetimini sağlamak, tedarikçi değerlendirme süreçleri, 
hukuki uyum süreci, mali işler v.b.), Kurumumuzun faaliyet stratejilerinin belirlenmesi ve uygulanması ile 
Kurumumuzun insan kaynakları politikalarının yürütülmesinin temini amaçlarıyla ortak paydaşlarımıza, sunduğumuz 
hizmetlerin amacı doğrultusunda iş ortaklarımız, iş bağlantılarımız, ifa yardımcılarımız ve alt yüklenicilerimize, 
kamu güvenliğine ilişkin hususlarda ve hukuki uyuşmazlıklarda, talep halinde ve mevzuat gereği savcılıklara, mahkemelere 
ve ilgili kamu görevlilerine bilgi verebilme çerçevesinde aktarılabilecektir.</p></li>
<li><b>Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</b>
<p>Kişisel verileriniz KURUM Genel Müdürlük, anlaşmalı internet siteleri üzerinden yapılmış olan başvurular, 
  destek hizmeti verdiğimiz / aldığımız sair kurumlar ile her türlü mevzuat veya sözleşme dahilinde işlem yapılan gerçek
  ve / veya tüzel kişiler, internet sitemiz ve mobil uygulamamız, sosyal medya hesaplarımız gibi mecralardan sözlü,
   yazılı veya elektronik ortamda veya ilerde kurulacak/oluşabilecek diğer kanallar başta olmak üzere;</p>
<p>KURUM tarafından yasal mevzuat çerçevesinde yukarıda belirtilen amaçlarla, sözleşmenin ifası dahilinde toplanmaktadır.</p></li>
<li><b>Kişisel Veri Sahibinin 6698 sayılı Kanun’un 11. Maddesinde Sayılan Hakları</b>
<p>6698 sayılı KVKK’nın 11. maddesi gereğince, Kişisel Veri Sahibi’nin hakları aşağıdaki gibidir:</p>
<p> Bu kapsamda kişisel veri sahipleri;</p>
<ol type="1">
  <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
  <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
  <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
  <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
<li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin 
  kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
<li>6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerin silinmesini veya yok edilmesini isteme ve 
  bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
<li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi 
  aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
<li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara 
  uğraması halinde zararın giderilmesini talep etme haklarına sahiptir.</li>
</ol></li>
<p>Yukarıda belirtilen haklarınızı kullanma ile ilgili talebinizi, 6698 sayılı Kanunu’nun 13. maddesinin 
  1. fıkrası ve 30356 sayılı ve 10.03.2018 tarihli Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında 
  Tebliğ gereğince Türkçe ve yazılı olarak KURUM’a  daha önce bildirilen ve sistemimizde kayıtlı bulunan
   elektronik posta adresini kullanmak suretiyle iletebilirsiniz. Başvurularda sadece başvuru sahibi kişi hakkında 
   bilgi verilecek olup diğer aile fertleri ve üçüncü kişiler hakkında bilgi alınması mümkün olmayacaktır. 
  KURUM’un cevap vermeden önce kimliğinizi doğrulama hakkı saklıdır. </p>
  <p>Başvurunuzda;</p>
  <ol type="a">
    <li>Adınızın, soyadınızın ve başvuru yazılı ise imzanızın,</li>
    <li>Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numaranızın, yabancı iseniz uyruğunuzun, 
      pasaport numaranızın veya varsa kimlik numaranızın,</li>
      <li>Tebligata esas yerleşim yeri veya iş yeri adresinizin,</li>
      <li>Varsa bildirime esas elektronik posta adresi, telefon ve faks numaranızın,</li>
      <li>Talep konunuzun,</li>
  </ol>
  <p>bulunması zorunlu olup varsa konuya ilişkin bilgi ve belgelerin de başvuruya eklenmesi gerekmektedir.</p>
  <p>Yazılı olarak yapmak istediğiniz başvurularınızı, gerekli belgeleri ekleyerek veri sorumlusu olarak Kurumumuz 
S.S Hasköy Pik Dökümcü ve İşleme Sanatkarları Küçük Sanayi Sitesi İşletme Kooperatifi Sultanorhan Mah. 
İdari Bina No:15 Gebze/KOCAELİ adresine verebilirsiniz. Başvuru formuna <a href="images/Kvkk-Basvuru-Formu.pdf" style="color: red; " target="_blank"><u>buradan</u></a> erişebilirsiniz.</p>
<p>E-posta yoluyla yapmak istediğiniz başvurularınızı info@gebzehaskoy.com e-posta adresine yapabilirsiniz.</p>
<p>Talebinizin niteliğine göre kimlik tespitine olanak sağlayacak bilgi ve belgelerin eksiksiz ve 
doğru olarak tarafımıza sağlanması gerekmektedir. İstenilen bilgi ve belgelerin gereği gibi sağlanmaması durumunda, 
KURUM  tarafından talebinize istinaden yapılacak araştırmaların tam ve nitelikli şekilde yürütülmesinde aksaklıklar yaşanabilecektir. 
Bu durumda, KURUM  kanuni haklarını saklı tuttuğunu beyan eder. 
Bu nedenle, başvurunuzun talebinizin niteliğine göre eksiksiz ve istenilen bilgileri ve belgeleri içerecek şekilde 
gönderilmesi gerekmektedir.</p>
</ol>
</p>
            </div>
          </div>
          <!-- Content row end -->
        </div>
        <!-- Container end -->
      </section>
      <!-- Main container end -->
    </div>
    <!-- Body inner end -->
    <Footer />
  </template>
    
    <script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Footer,
    Navbar,
    ScrollButton,
  },
};
</script>
    
    <style scoped>
.mission-img {
  display: block;
  position: absolute;
  left: 20%;
  width: 500px;
  max-height: 100%;
}

.work-img-wrap {
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work-img-wrap {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .work-img-wrap {
    margin-top: 60px;
  }
}
.work-img-wrap .image-one img {
  max-width: calc(100% - 150px);
}
.work-img-wrap .image-two {
  position: absolute;
  top: 35%;
  left: 0;
}
@media (max-width: 767px) {
  .work-img-wrap .image-two {
    bottom: -40px;
  }
}
.work-img-wrap .image-two img {
  max-width: calc(100% - 60px);
}
</style>