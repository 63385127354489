<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="fileHata">{{ fileHata }}</div>
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>

  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Duyuru Bilgilerini Güncelle</h2>
          <p class="lead">
            <router-link to="/admin/notice-list"
              >Duyuru Listesi içn Tıklayınız</router-link
            >
          </p>
        </div>
      </div>

      <div class="row g-5">
        <div class="col-md-8 col-lg-8">
          <h4 class="mb-3">Duyuru Bilgileri</h4>
          <div class="d-flex justify-content-between">
            <p>Eklenen tarih: {{ tarih }}</p>
          </div>
          <form class="needs-validation" @submit.prevent="handleSubmit">
            <div class="row g-3">
              <div class="col-lg-6">
                <label for="baslik" class="form-label">Duyuru Başlık</label>
                <input
                  type="text"
                  class="form-control"
                  id="baslik"
                  placeholder=""
                  v-model="baslik"
                />
                <label for="icerik" class="form-label mt-2"
                  >Duyuru İçerik</label
                >
                <textarea
                  rows="5"
                  type="text"
                  class="form-control"
                  id="icerik"
                  v-model="icerik"
                ></textarea>

                <p>Durum</p>

                <input
                  type="radio"
                  id="aktif"
                  :value="true"
                  name="durumRadio"
                  v-model="durum"
                  :checked="durum == true"
                />
                <label for="aktif" class="form-label"> Aktif </label>

                <input
                  type="radio"
                  id="pasif"
                  :value="false"
                  name="durumRadio"
                  v-model="durum"
                  :checked="durum == false"
                />
                <label for="pasif" class="form-label"> Pasif </label>
              </div>
            </div>

            <button class="w-100 btn btn-success btn-lg mb-4" type="submit">
              Güncelle
            </button>
          </form>
        </div>
      </div>
    </main>
  </div>
  <AdminFooter />
</template>
      
<script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { doc, getDoc, updateDoc, collection } from "firebase/firestore";
import Loading from "vue-loading-overlay";

import moment from "moment";
import { notify } from "@kyvg/vue3-notification";
import { useRoute, useRouter } from "vue-router";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
    AdminFooter,
    AdminNavbar,
    VueFinalModal,
    ModalsContainer,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const showModal = ref(false);
    const isLoading = ref(true);
    const dId = ref(route.params.duyuruId);
    const tarih = ref("");

    const olusturulmaTarihi = ref("");
    const baslik = ref("");
    const icerik = ref("");
    const durum = ref(false);
    const fileHata = ref(null);
    const hataMesaj = ref("");

    const bilgiKontrol = ref(false);
    moment.locale("tr");

    onMounted(async () => {
      const docRef = doc(firestore, "duyurular", dId.value);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        olusturulmaTarihi.value = docSnap.data().olusturulmaTarihi.toDate();
        baslik.value = docSnap.data().baslik;
        icerik.value = docSnap.data().icerik;

        durum.value = docSnap.data().durum;

        isLoading.value = false;
        tarih.value = moment(olusturulmaTarihi.value).fromNow();

        console.log(docSnap.data());
      } else {
        console.log("Veriye erişilemedi");
      }
    });

    const handleSubmit = async () => {
      isLoading.value = true;
      const myCollection = collection(firestore, "duyurular");
      const myDocRef = doc(myCollection, dId.value);
      try {
        const data = {
          baslik: baslik.value,
          icerik: icerik.value,
          durum: durum.value === true ? true : false,
        };
        await updateDoc(myDocRef, data);
        router.push("/admin/notice-list");
      } catch (error) {
        hataMesaj.value = error.message;
        isLoading.value = false;
        showModal.value = true;
      }
    };

    return {
      tarih,
      dId,
      olusturulmaTarihi,
      baslik,
      icerik,
      bilgiKontrol,
      isLoading,
      handleSubmit,
      hataMesaj,
      fileHata,
      durum,
    };
  },
};
</script>
  
      
    <style scoped>
</style>