import { firestore } from "@/firebase/config";
import { ref } from "vue";
import { doc, getDoc } from "firebase/firestore";

const yetki = ref(null);

const yetkiKontrol = async (uid) => {

    const docRef = doc(firestore, 'kullanicilar', uid);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        switch (docSnap.data().yetki) {
            case 'admin':
                yetki.value = 'admin'
                break;
            case 'uye':
                yetki.value = 'uye'
                break;
            case 'basit':
                yetki.value = 'basit'
                break;

            default:
                yetki.value=null;
                break;
        }
    }
    else {
        yetki.value = null;
    }

}

const getYetkili=()=>{
    return {yetki,yetkiKontrol}
}

export default getYetkili