
import{initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'


const firebaseConfig = {
  apiKey: "AIzaSyCykG0cSKZvlc3yFxS1cg9YJidmEPgFBNI",
  authDomain: "haskoy-admin-panel-461ca.firebaseapp.com",
  projectId: "haskoy-admin-panel-461ca",
  storageBucket: "haskoy-admin-panel-461ca.appspot.com",
  messagingSenderId: "603890139492",
  appId: "1:603890139492:web:7d817ce970ce82787a7a71",
  measurementId: "G-SMHBER5BG7"
};

  const app = initializeApp(firebaseConfig);

  const auth=getAuth(app);
  const firestore=getFirestore(app);
  const storage=getStorage(app);

  export {auth,firestore,storage}