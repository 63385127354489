<template>
  <Navbar />
  <ScrollButton />
  <div
    id="banner-area"
    class="banner-area"
    style="background-image: url(images/director.jpg)"
  >
    <div class="banner-text">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-heading">
              <i class="fa fa-home"></i>
              <p>S.S. Hasköy</p>
              <h4>Vizyon-Misyon</h4>
            </div>
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <!-- Banner text end -->
  </div>
  <!-- Banner area end -->

  <div class="body-inner">
    <section id="main-container" class="main-container">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="column-title">Misyonumuz</h3>
            <p>
              Küçük ve orta ölçekli sanayi tesislerinin bir plan dahilinde belli
              bir bölgede toplanmasını sağlayarak, uğraşları gereği ihtiyaç
              duydukları bütün fonksiyonları yerine getirmeye imkan sağlamak,
              kaliteli, güvenilir ve hızlı hizmet vermek ,topluma, çevreye
              saygılı, etkin verimlilik ve sorumluluk bilinciyle çalışarak
              bölgemizdeki iş hacmini ve istihdamı arttırmak, büyük sanayi
              işletmelerine yan sanayi olarak hizmet vermektir.
            </p>

            <h3 class="column-title">Vizyonumuz</h3>
            <p>Alt yapısını tamamlamış, üretim kalitesiyle, ihracatıyla ülke kalkınmasına ve toplumun refah düzeyinin 
              artmasına önemli katkılar sağlamak, sunduğu ürün ve hizmetleri kalite ve kurumsal değerden ödün vermeden sürdürerek, 
              müşteri memnuniyetini her zaman en üst seviyede tutarak gelişimini sürdürmektir.</p>
          </div>
          <!-- Col end -->

          <div class="col-lg-6 mt-5 mt-lg-0">
            <div style="max-width: 150px">
              <img
                src="images/sanayi-resim/mission-img.jpg"
                class="mission-img"
              />
            </div>
          </div>
        </div>
        <!-- Content row end -->
      </div>
      <!-- Container end -->
    </section>
    <!-- Main container end -->
  </div>
  <!-- Body inner end -->
  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import ScrollButton from "@/components/ScrollButton.vue";

export default {
  components: {
    Footer,
    Navbar,
    ScrollButton,
  },
};
</script>

<style scoped>
.mission-img {
  display: block;
  position: absolute;
  left: 20%;
  width: 500px;
  max-height: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mission-img {
    width: 300px;
  }
}
@media (max-width: 767px) {
  .mission-img {
    display: none;
  }
}
</style>