<template>
  <AdminNavbar/>
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Kullanıcı Durum Değiştir</h2>
        </div>
      </div>

      <div class="row g-5">
        <div class="col-md-12 col-lg-12">
          <h4 class="mb-3">Email Adresine Göre Kullanıcı Ara</h4>
          <div class="row justify-content-md-center">
            <form @submit.prevent="handleAra" class="needs-validation col-md-6">
              <div class="input-group mb-5">
                <input v-model="emailAdres"
                  type="text"
                  class="form-control"
                  placeholder="Email Adres"
                  aria-label="Email Adres"
                  aria-describedby="button-addon2"
                />
                <button
                  class="btn btn-outline-dark"
                  type="submit"
                  id="button-addon2"
                >
                  Ara
                </button>
              </div>
            </form>
          </div>
          <hr class="my-4" />
        </div>
      </div>

      <div class="row g-5">
        <div class="col-md-12 col-lg-12">
          <h4 class="mb-3">{{ baslikMesaj }}</h4>
          <div
            class="row justify-content-md-center"
            v-if="kullanicilar.length !== 0"
          >
            <ol class="list-group list-group-numbered">
              <li
                v-for="k in kullanicilar"
                :key="k.id"
                class="list-group-item d-flex justify-content-between align-items-start"
              >
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Email: {{ k.email }}</div>
                  Kullanıcı Ad: {{ k.kullaniciAd }} - İsim Soyisim :
                  {{ k.isim }} {{ k.soyisim }}
                </div>
                <span
                  :class="k.durum == true ? 'bg-success' : 'bg-danger'"
                  class="mx-3 badge rounded-pill bg-success"
                  >{{ k.durum == true ? "Aktif" : "Pasif" }}</span
                >
                <button
                  :disabled="disabledButton"
                  @click="durumDegistir(k.id)"
                  class="badge btn bg-warning"
                >
                  <i class="bi bi-arrow-repeat"></i>
                </button>
              </li>
            </ol>
          </div>
        </div>
        <div
          class="row justify-content-md-center"
          v-if="kullanicilar.length == 0"
        >
          <h3>{{ bosMesaj }}</h3>
        </div>
      </div>
    </main>
  </div>
  <AdminFooter/>
</template>

<script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import {
  query,
  orderBy,
  limit,
  collection,
  getDocs,
  where,
  updateDoc,
  Timestamp,
  getDoc,
  doc
} from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { notify } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";

import getYetkili from "@/composables/getYetkili";
import getUser from "@/composables/getUser";
import { duration } from "moment";
import AdminFooter from '@/components/AdminFooter.vue'
import AdminNavbar from "@/components/AdminNavbar.vue";

export default {
  components: {
    Loading,
    AdminFooter,
    AdminNavbar
  },
  setup() {
    const kullanicilar = ref([]);
    const baslikMesaj = ref("Son 20 Yeni Kayıt");
    const bosMesaj = ref("Kullanıcı Bulunamadı");

    const isLoading = ref(true);

    const router = useRouter();
    const disabledButton = ref(false);

    const { yetki, yetkiKontrol } = getYetkili();
    const { kullanici } = getUser();

    const emailAdres=ref();

    onMounted(async () => {
      if (kullanici.value) {
        await yetkiKontrol(kullanici.value.uid);
      }
      if (yetki.value !== "admin") {
        router.push({ name: "Login" });
      }

      let q = query(
        collection(firestore, "kullanicilar"),
        where("yetki", "in", ["basit", "uye"]),
        orderBy("olusturulmaTarihi", "desc"),
        limit(20)
      );

      const querysnapShot = await getDocs(q);

      const veri = [];

      querysnapShot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      kullanicilar.value = veri;
      isLoading.value = false;
    });

    const durumDegistir = async (id) => {
      isLoading.value = true;
      disabledButton.value = true;

      const docRef = doc(firestore, "kullanicilar", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let durum = docSnap.data().durum == true ? false : true;
        let kullaniciAd = docSnap.data().kullaniciAd;

        updateDoc(docRef, {
          durum,
        }).then(() => {
          notify({
            title: `${kullaniciAd} Durum Değişti`,
            text: `${kullaniciAd} kuallnıcının durumu ${durum} oldu. Sayfa yeniden yöndelendiriliyor.`,
            type: "success",
            duration: 2000,
          });
          isLoading.value = false;
          setTimeout(() => {
            router.go(0);
          }, 2500);
        });
      } else {
        notify({
          title: "Upss!! Kullanıcı bulunamadı ",
          text: "Lütfen tekrar deneyiniz.",
          type: "error",
          duration: 2000,
        });
        isLoading.value = false;
        disabledButton.value = false;
      }
    };

    const handleAra= async () =>{
      kullanicilar.value=[];
      isLoading.value=true;

      baslikMesaj.value='Arama Sonuçları'

      if(emailAdres.value!==''){
          let q= query(collection(firestore,'kullanicilar'),where('yetki','in',['basit','uye']))

          q=query(q,where('email','>=',emailAdres.value),where('email','<=',emailAdres.value +'\uf8ff'))

          const querySnapshot=await getDocs(q);

          const veri=[];

          querySnapshot.forEach(doc=>{
            veri.push({...doc.data(),id:doc.id})
          })
          
          kullanici.value=veri;
          isLoading.value=false;
        }
      else{
        
      let q = query(
        collection(firestore, "kullanicilar"),
        where("yetki", "in", ["basit", "uye"]),
        orderBy("olusturulmaTarihi", "desc"),
        limit(20)
      );

      const querysnapShot = await getDocs(q);

      const veri = [];

      querysnapShot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      kullanicilar.value = veri;
      isLoading.value = false;
      }
    }

    return {
      kullanicilar,
      baslikMesaj,
      isLoading,
      bosMesaj,
      durumDegistir,
      disabledButton,
      handleAra,
      emailAdres
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 750px;
}
</style>