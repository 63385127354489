<template>
    <div class="container">
  <div class="row">
    <div class="col-lg-12">
    <ul class="nav nav-tabs">
      <li v-for="tab in tabs" :key="tab" class="nav-item">
        <a class="nav-link" :class="{ active: tab === selected }" @click="setTab(tab)">
        {{ tab }}</a>
      </li>
    </ul>
    <slot></slot>
  </div>
</div>
</div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  methods: {
    setTab(tab) {
      this.$emit("selected", tab);
    },
  },
};
</script>

<style scoped>

.nav-tabs a.active{
    color: #212121;
    background: gray;
    font-weight: 700;
}
.nav-tabs a{
    display: inline-block;
    font-family:"Open Sans", sans-serif;
    color:#555;
    font-size: 15px;
    line-height:26px;
    text-rendering:optimizeLegibility;
    text-transform:uppercase;
    font-weight: 700;

}
.nav-tabs{
    border-bottom: 2px solid gray;
    font-family:"Open Sans", sans-serif;
    color:#555;
    font-size: 15px;
    line-height:26px;
    text-rendering:optimizeLegibility;
    margin-bottom: 20px;
    padding-top: 10px;
    text-transform:uppercase;
    cursor: pointer;
}
</style>