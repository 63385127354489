import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { auth } from '../src/firebase/config'
import { onAuthStateChanged } from 'firebase/auth'

import Notifications  from '@kyvg/vue3-notification'
let app;

onAuthStateChanged(auth,(user)=>{
    
    if(!app)
    {
        app=createApp(App).use(router).use(Notifications).mount('#app')
        
    }
})