<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="fileHata">{{ fileHata }}</div>
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <Loading
    v-model:active="isLoading"
    :is-full-page="true"
    color="#ffc300"
    loader="spiners"
  />
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Haber Ekle</h2>
          <p class="lead">
            <router-link to="/admin/news-list"
              >Haber Listesi için Tıklayınız</router-link
            >
          </p>
        </div>
      </div>
      <div class="row g-5">
        <div class="col-md-8 col-lg-8">
          <form class="needs-validation" @submit.prevent="handleSubmit">
            <h4 class="mb-3">Haber Bilgileri</h4>
            <div class="mb-2">
              <label for="baslik" class="form-label text-start">Başlık</label>
              <input
                type="text"
                class="form-control"
                id="baslik"
                v-model="baslik"
              />
            </div>

            <div class="mb-2">
              <label for="baslikAlt" class="form-label text-start"
                >Alt Başlık</label
              >
              <input
                type="text"
                class="form-control"
                id="baslikAlt"
                v-model="baslikAlt"
              />
            </div>
            <div class="col-6">
              Durum
              <label for="aktif" class="form-label">Durum</label>
              <div class="col-3">
                <input
                  type="radio"
                  id="aktif"
                  name="durum"
                  :value="true"
                  v-model="durum"
                />
                <label for="aktif" class="form-label">Aktif</label>
              </div>
              <div class="col-3">
                <input
                  type="radio"
                  id="pasif"
                  name="durum"
                  :value="false"
                  v-model="durum"
                />
                <label for="pasif" class="form-label">Pasif</label>
              </div>
            </div>

            <div class="mb-1">
              <label for="fileSm" class="form-label"
                >Ana Haber Resmi Yükleyiniz</label
              >
              <input
                class="form-control form-control-sm inp"
                id="fileSm"
                type="file"
                multiple
                @change="handleChange"
              />
            </div>
            <h1>Haber Detay Ekleyiniz</h1>
            <div class="row">
              <div class="col-md-6 mb-1">
                <label for="icerik" class="form-label text-start">İçerik</label>
                <textarea
                  class="form-control"
                  id="icerik"
                  v-model="icerik"
                ></textarea>
              </div>

              <div class="mb-1">
                <label for="fileSm1" class="form-label"
                  >Haber Detay için 3 tane resim yükleniyiz.</label
                >
                <input
                  class="form-control form-control-sm inp"
                  id="fileSm1"
                  type="file"
                  @change="handleChangeDetail"
                  multiple
                />
                <div v-for="(dosya, index) in secilenDosyalar" :key="index">
                  <!-- Dosya adını da gösterebilirsiniz -->
                  <p>{{ dosya.name }}</p>
                </div>
              </div>
            </div>

            <button class="w-100 btn btn-lg btn-success" type="submit">
              Haber Ekle
            </button>
            <hr />
          </form>
        </div>
      </div>
    </main>
  </div>
  <AdminFooter />
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import useStorage from "../../../composables/useStorage";
import useCollection from "../../../composables/useCollection";
import { firestore } from "@/firebase/config";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";

export default {
  components: {
    Loading,
    VueFinalModal,
    ModalsContainer,
    AdminFooter,
    AdminNavbar,
  },
  setup() {
    const showModal = ref(false);
    const baslik = ref("");
    const baslikAlt = ref("");
    const icerik = ref("");
    const resim = ref("");
    const durum = ref(true);
    const isLoading = ref(false);

    const hataMesaj = ref(null);

    const file = ref(null);
    const file1 = ref(null);
    const file2 = ref(null);
    const file3 = ref(null);
    const secilenDosyalar = ref([]);

    const fileHata = ref(null);

    const router = useRouter();

    const { resimYukle, resimArrYukle, url, urlArray, fileYol, hataStorage } =
      useStorage();
    const { hataFirestore, yeniBelgeEkle, subBelgeEkle, id, subId } =
      useCollection("haberler");

    const gecerliTipler = ["image/jpeg", "image/png"];

    moment.locale("tr");

    onMounted(async () => {
      isLoading.value = false;
    });

    const handleChange = (e) => {
      isLoading.value = true;
      let secilen = e.target.files[0];
      console.log(e.target.files);

      if (secilen && gecerliTipler.includes(secilen.type)) {
        file.value = secilen;
        fileHata.value = null;
        isLoading.value = false;
      } else {
        file.value = null;
        fileHata.value = "Lütfen jpeg ya da png uzantılı dosya yükleyiniz";
        showModal = true;
        isLoading.value = false;
      }
    };
    const handleChangeDetail = (event) => {
      isLoading.value = true;
      const dosyalar = event.target.files;
      for (let i = 0; i < dosyalar.length; i++) {
        const dosya = dosyalar[i];
        if (uzantiKontrolEt(dosya.name)) {
          secilenDosyalar.value.push(dosya);
          isLoading.value = false;
        } else {
          isLoading.value = false;
          console.error("Geçersiz dosya uzantısı:", dosya.name);
        }
      }
    };
    const uzantiKontrolEt = (dosyaAdı) => {
      // İzin verilen uzantılar buraya eklenir
      const izinVerilenUzantilar = [".jpg", ".jpeg", ".png"];
      const dosyaUzantısı = dosyaAdı
        .substring(dosyaAdı.lastIndexOf("."))
        .toLowerCase();
      return izinVerilenUzantilar.includes(dosyaUzantısı);
    };

    const handleSubmit = async () => {
      isLoading.value = true;
      if (
        baslik.value !== "" &&
        baslikAlt.value !== !""
      ) {
        const myDocumentData = {
          baslik: baslik.value,
          baslikAlt: baslikAlt.value,
          durum: durum.value === true ? true : false,
          resim: file.value.name,
          olusturulmaTarihi: serverTimestamp(),
        };
        const newsDetail = {
          icerik: icerik.value,
          
          resim1: secilenDosyalar.value.length>0 ? secilenDosyalar.value[0].name : "" ,
          resim2:secilenDosyalar.value.length>1 ? secilenDosyalar.value[1].name : "",
          resim3:secilenDosyalar.value.length>2 ? secilenDosyalar.value[2].name : "",
        };

        // await yeniBelgeEkle(myDocumentData);
        await subBelgeEkle(myDocumentData, newsDetail);

        if (!hataFirestore.value) {
          const arr = secilenDosyalar;
          await resimYukle(file.value, id.value);
          console.log(arr);

          await resimArrYukle(secilenDosyalar);
          // await resimArrYukle(
          //   secilenDosyalar.value,
          //   secilenDosyalar.value.length,
          //   id.value
          // );

          if (!hataStorage.value) {
            const myCollection = collection(firestore, "haberler");
            const myDocRef = doc(myCollection, id.value);

            // Ana koleksiyondan belirli bir belgeyi alın
            const anaBelgeRef = doc(firestore, "haberler", id.value);

            // Belgeye bağlı alt koleksiyonu alın
            const altKoleksiyonRef = collection(anaBelgeRef, "haberDetay");

            // Alt koleksiyonda güncelleme yapın (örneğin, belirli bir belgeyi güncelleme)
            const belgeRef = doc(altKoleksiyonRef, subId.value);

            const belgeID = belgeRef.id;

            console.log(belgeID);

            try {
              const data = {
                resim: url.value,
              };
              await updateDoc(myDocRef, data);

              const dataDetail = {
                resim1: urlArray.value.length>0 ? urlArray.value[0] :"",
                resim2:urlArray.value.length>1 ? urlArray.value[1] :"",
                resim3: urlArray.value.length>2 ? urlArray.value[2] :"",
              };
              await updateDoc(belgeRef, dataDetail);
              console.log("Alt koleksiyon başarıyla güncellendi.");
              // await setDoc(collection(myDocRef, "haberDetay"), dataDetail);
              isLoading.value = false;
              // await setDoc(myDetailCollection,dataDetail)
              router.push("/admin/news-list");
            } catch (error) {
              hataMesaj.value = error.message;
              isLoading.value = false;
              showModal.value = true;
            }
          } else {
            hataMesaj.value = hataStorage.value;
            isLoading.value = false;
            showModal.value = true;
          }
        } else {
          hataMesaj.value = hataFirestore.value;
          isLoading.value = false;
          showModal.value = true;
        }
      }
    };

    const verifyMethod = () => {
      buttonDisabled.value = false;
    };
    return {
      baslik,
      baslikAlt,
      icerik,
      durum,
      resim,
      hataMesaj,
      handleSubmit,
      handleChange,
      handleChangeDetail,
      file,
      file1,
      file2,
      file3,
      fileHata,
      verifyMethod,
      showModal,
      isLoading,
      secilenDosyalar,
    };
  },
};
</script>

<style scoped>
</style>