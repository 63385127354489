<template>
  <footer id="footer" class="footer bg-overlay mt-auto">
    <div class="footer-main">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-3 col-md-6 col-sm-6 footer-widget footer-about">
            <h3 class="widget-title">Hakkımızda</h3>
            <div class="footer-widget ">
              <ul
                class="top-info-box2"
                style="background: white; border-radius: 10%"
              >
                <li class="last">
                  <div class="info-box last">
                    <div class="info-box-content ">
                      <img src="images/ataturk.png" alt="" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <br /><br /><br /><br />
            <div class="footer-social">
              <ul>
                <li>
                <a href="https://www.facebook.com/people/Gebze-Hask%C3%B6y-Sanayi-Sitesi/61556941386615/" target="_blank"  aria-label="Facebook"
                  ><i class="fab fa-facebook-f"></i
                ></a>
              </li>
                <li>
                  <a href="#" aria-label="Twitter"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
                <li>
                <a
                  href="https://www.instagram.com/haskoysanayisitesi/" target="_blank"
                  aria-label="Instagram"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
                <li>
                  <a href="#" aria-label="Github"
                    ><i class="fab fa-github"></i
                  ></a>
                </li>
              </ul>
             
            </div>
            <!-- Footer social end -->
          </div>
          <!-- Col end -->

          <div class="col-lg-4 col-md-6 footer-widget mt-5 mt-md-0 none">
            <h3 class="widget-title">Çalışma Saatlerimiz</h3>
            <div class="working-hours" >
              Haftanın 6 günü, büyük tatiller hariç her gün çalışıyoruz. Acil
              bir durumda Yardım Hattımızı kullanarak bizimle iletişime
              geçebilirsiniz.
              <br />
              0262 644 13 19-20
              <br /><br />
              Pazartesi - Cuma: <span class="text-right">07:30 - 18:00 </span>
              <br />
              Cumartesi: <span class="text-right">07:30 - 18:00</span> <br />
              Pazar: <span class="text-right">--</span>
            </div>
          </div>
          <!-- Col end -->

          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 footer-widget">
            <h3 class="widget-title  ">Hızlı Bağlantılar</h3>
            <ul class="list-arrow">
              <li>
                <router-link to="/about" aria-current="page"
                  >Hakkımızda</router-link
                >
              </li>
              <li>
                <router-link to="/director" aria-current="page"
                  >Yönetim</router-link
                >
              </li>
              <li>
                <router-link to="/company-list" aria-current="page"
                  >Firma Listesi</router-link
                >
              </li>
              <li>
                <router-link to="/member-list" aria-current="page"
                  >Üye Listesi</router-link
                >
              </li>
              <li>
                <router-link to="/contact" aria-current="page"
                  >İletişim</router-link
                >
              </li>
            </ul>
          </div>
          <!-- Col end -->
          <div class="col-lg-2 col-md-6 d-flex justify-content-between w-100 ">
            <img
              loading="lazy"
              class="footer-logo2 none"
              src="images/haskoy-logo2.png"
              alt="Industry"
            />
          </div>
          <!-- Col end -->
        </div>
        <!-- Row end -->
      </div>
      <!-- Container end -->
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div
          class="row justify-content-center align-items-center justify-content-center"
        >
          <div class="col-lg-6 col-md-6 col-sm-12 ">
            <p class="copyright-line">
              © 2024 S.S. Hasköy. Tüm hakları saklıdır.
            </p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 ">
            <p class="privacy" style="float:right;">Gizlilik Politikası | Şartlar ve Koşullar</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer main end -->
  </footer>
  <!-- Footer end -->
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
ul.top-info-box2 {
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
}
.footer-logo2 {
  max-height: 200px;
  margin-left: 65px;
  margin-top: 25px;
}
.footer-bottom {
  font-size: 15px;
  font-weight: 400;
  padding: 20px 0;
  background: #001e57e4;
  border-top: 1px solid #222;
}
.footer-bottom p {
  margin: 0;
  color: #fff;
  font-size: 16px;
}
@media (max-width: 767px) {
  .footer-bottom {
    text-align: center;
  }
 
}
p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  position: relative;
  color: #5A5A67;
}
</style>