<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>

  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Eklenen Duyurular</h2>

          <router-link to="/admin/add-notice" class="btn btn-secondary"
            >Duyuru Ekle</router-link
          >
        </div>
      </div>

      <custom-table
        :rows="displayedNotice"
        @updateButtonClick="updateHandler($event)"
        @deleteButtonClick="deleteHandler($event)"
        :update-button-visible="true"
        :delete-button-visible="true"
        :actionsButtonsVisible="true"
        :columns="tableColumns"
      >
      </custom-table>
    </main>
    <div class="pagination-admin">
      <button @click="prevPage" v-if="currentPage > 1">
        <i class="bi bi-chevron-double-left"></i>
      </button>
      <button
        v-for="pageNumber in visiblePages"
        :key="pageNumber"
        @click="goToPage(pageNumber)"
        :class="{ active: currentPage === pageNumber }"
      >
        {{ pageNumber }}
      </button>
      <span v-if="totalPages > 5 && currentPage < totalPages - 2">...</span>
      <button @click="nextPage" v-if="currentPage < totalPages">
        <i class="bi bi-chevron-double-right"></i>
      </button>
    </div>
  </div>
  <AdminFooter />
</template>
    
    <script>
import { ref, onMounted,computed } from "vue";
import { auth, firestore } from "@/firebase/config";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
import CustomTable from "@/components/Table.vue";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";
import "vue-loading-overlay/dist/vue-loading.css";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    Loading,
    VueFinalModal,
    ModalsContainer,
    AdminFooter,
    AdminNavbar,
    CustomTable,
  },
  data() {
    return {
      tableColumns: [
        { label: "Duyuru Başlık", value: "baslik" },
        { label: "Duyuru İçerik", value: "icerik" },
        { label: "Durum", value: "durum" },
      ],
    };
  },
  methods: {

    updateHandler(data) {
      console.log("update", data);
      this.$router.push({
        name: "NoticeDetail",
        params: { duyuruId: data.item.id },
        props: true,
      });
    },
  },
  setup() {
    const duyurular = ref([]);
    const isLoading = ref(true);
    const router = useRouter();
    const showModal=ref(false);
    const hataMesaj=ref("");
    const currentPage = ref(1);
    const pageSize = 5; // Sayfa başına öğe sayısı
    const totalItems = ref(null); // Toplam öğe sayısı

    const totalPages = computed(() => Math.ceil(totalItems.value / pageSize));

    const displayedNotice = computed(() => {
      const startIndex = (currentPage.value - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      console.log(duyurular.value.slice(startIndex, endIndex));
      return duyurular.value.slice(startIndex, endIndex);
    });
    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        // Sayfaya göre verileri güncelleme işlemleri burada yapılabilir
      }
    };
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        goToPage(currentPage.value);
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        goToPage(currentPage.value);
      }
    };
    const visiblePages = computed(() => {
      if (totalPages.value <= 5) {
        return Array.from({ length: totalPages.value }, (_, i) => i + 1);
      } else {
        const startPage = Math.max(currentPage.value - 2, 1);
        const endPage = Math.min(startPage + 4, totalPages.value);
        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
      }
    });
    onMounted(async () => {
      let q = query(
        collection(firestore, "duyurular"),
        orderBy("olusturulmaTarihi", "desc"),
        where("durum", "==", true)
      );

      const querySnapshot = await getDocs(q);

      const veri = [];

      querySnapshot.forEach((doc) => {
        veri.push({ ...doc.data(), id: doc.id });
      });

      duyurular.value = veri;
      isLoading.value = false;
      totalItems.value = veri.length;
    });

    const deleteHandler = async (data) => {
        isLoading.value = true;
        console.log("delete2" ,data.item.id);
      const myCollection = collection(firestore, "duyurular");
      const myDocRef = doc(myCollection, data.item.id);
      try {
        const data = {
          durum:false
        };
        await updateDoc(myDocRef, data);
        router.go(0)
        isLoading.value = false;
      } catch (error) {
        hataMesaj.value = error.message;
        isLoading.value = false;
        showModal.value = true;
      }
    }

    return {
      duyurular,
      isLoading,
      showModal,
      hataMesaj,
      deleteHandler,
      currentPage,
      totalPages,
      goToPage,
      displayedNotice,
      prevPage,
      nextPage,
      visiblePages
    };
  },
};
</script>
    
    <style scoped>
</style>