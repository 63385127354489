<template>
  <AdminNavbar />
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <button
      type="button"
      class="btn-close modal__close"
      aria-label="Close"
      @click="showModal = false"
    ></button>

    <span class="modal__title text-danger">Hata Mesajı</span>
    <div class="modal-content">
      <div class="fs-4 text-center" v-if="fileHata">{{ fileHata }}</div>
      <div class="fs-4 text-center" v-if="hataMesaj">{{ hataMesaj }}</div>
    </div>
  </vue-final-modal>
  <loading
    v-model:active="isLoading"
    :is-full-page="true"
    loader="bars"
    color="#ffc300"
  ></loading>
  <div class="container">
    <main>
      <div class="pt-5 text-center">
        <div class="alert alert-dark" role="alert">
          <h2>Firma Bilgileri Güncelle</h2>
          <p class="lead">
            <router-link to="/admin/company-list"
              >Firmaların Listesi içn Tıklayınız</router-link
            >
          </p>
        </div>
      </div>

      <div class="row g-5">
        <div class="col-md-8 col-lg-8">
          <h4 class="mb-3">Firma Bilgileri</h4>
          <div class="d-flex justify-content-between">
            <p>Üyelik tarihi: {{ tarih }}</p>
          </div>
          <form class="needs-validation" @submit.prevent="handleSubmit">
            <div class="row g-3">
              <div class="col-sm-6">
                <label for="firmaAd" class="form-label">Firmanın Adı</label>
                <input
                  type="text"
                  class="form-control"
                  id="firmaAd"
                  placeholder=""
                  v-model="firmaAd"
                />
              </div>

              <div class="col-sm-6">
                <label for="blokNo" class="form-label">Blok No</label>
                <input
                  type="text"
                  class="form-control"
                  id="blokNo"
                  placeholder=""
                  v-model="blokNo"
                />
              </div>

              <div class="col-6">
                <label for="isyeriNo" class="form-label">isyeri No</label>
                <input
                  type="text"
                  class="form-control"
                  id="isyeriNo"
                  placeholder=""
                  v-model="isyeriNo"
                />
              </div>

              <div class="col-6">
                <label for="telNo" class="form-label">Telefon No</label>
                <input
                  type="text"
                  class="form-control"
                  id="telNo"
                  placeholder=""
                  v-model="telNo"
                />
              </div>
              <div class="col-6">
                <label for="email" class="form-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="abc@abc.com"
                  v-model="email"
                />
              </div>
              <div class="col-6">
                <label for="uretimTur" class="form-label">Üretim Türü</label>
                <input
                  type="text"
                  class="form-control"
                  id="uretimTur"
                  placeholder="Dökümcü, Tornacı vs."
                  v-model="uretimTur"
                />
              </div>

              <div class="col-6">
                Durum <br />
                <div>
                  <input
                    type="radio"
                    id="aktif"
                    :value="true"
                    name="durumRadio"
                    v-model="durum"
                    :checked="durum == true"
                  />
                  <label for="aktif" class="form-label"> Aktif </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="pasif"
                    :value="false"
                    name="durumRadio"
                    v-model="durum"
                    :checked="durum == false"
                  />
                  <label for="pasif" class="form-label"> Pasif </label>
                </div>
              </div>
            </div>

            <hr class="my-4" />

            <button class="w-100 btn btn-success btn-lg mb-4" type="submit">
              Güncelle
            </button>
          </form>
        </div>

        <div class="col-md-4 col-lg-4 order-md-last">
          <h4 class="d-flex justify-content-between align-items-center mb-3">
            <span>Firma Logosu</span>
          </h4>
          <img :src="resimUrl" class="rounded img-thumbnail" ref="resim" />
        </div>
      </div>
    </main>
  </div>
  <AdminFooter />
</template>
  
  <script>
import { ref, onMounted } from "vue";
import { firestore } from "@/firebase/config";
import { doc, getDoc, updateDoc,collection } from "firebase/firestore";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import { notify } from "@kyvg/vue3-notification";
import { useRoute,useRouter } from "vue-router";
import AdminFooter from "@/components/AdminFooter.vue";
import AdminNavbar from "@/components/AdminNavbar.vue";
import { VueFinalModal, ModalsContainer } from "vue-final-modal";

export default {
  components: {
    Loading,
    AdminFooter,
    AdminNavbar,
    VueFinalModal,
    ModalsContainer
  },

  setup() {
    const route = useRoute();
    const router= useRouter();
    const showModal = ref(false);
    const isLoading = ref(true);
    const fId = ref(route.params.firmId);
    const tarih = ref("");

    const olusturulmaTarihi = ref("");
    const firmaAd = ref("");
    const blokNo = ref("");
    const isyeriNo = ref("");
    const telNo = ref("");
    const email = ref("");
    const uretimTur = ref("");
    const durum = ref(false);
    const resimUrl = ref("");
    const fileHata = ref(null);
    const hataMesaj = ref("");

    const resim = ref(null);
    const docc = ref([]);

    const bilgiKontrol = ref(false);
    moment.locale("tr");

    onMounted(async () => {
      const docRef = doc(firestore, "firmalar", fId.value);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        olusturulmaTarihi.value = docSnap.data().olusturulmaTarihi.toDate();
        firmaAd.value = docSnap.data().firmaninAdi;
        blokNo.value = docSnap.data().blokNo;
        telNo.value = docSnap.data().telNo;
        isyeriNo.value = docSnap.data().isyeriNo;
        email.value = docSnap.data().email;
        resimUrl.value = docSnap.data().profilResim;
        uretimTur.value = docSnap.data().uretimTur;
        durum.value = docSnap.data().durum;

        isLoading.value = false;
        tarih.value = moment(olusturulmaTarihi.value).fromNow();

        console.log(docSnap.data());
      } else {
        console.log("Veriye erişilemedi");
      }
    });

    const handleSubmit = async () => {
      isLoading.value = true;
      const myCollection = collection(firestore, "firmalar");
      const myDocRef = doc(myCollection, fId.value);
      try {
        const data = {
          firmaninAdi: firmaAd.value,
          blokNo: blokNo.value,
          isyeriNo: isyeriNo.value,
          telNo: telNo.value,
          email: email.value,
          uretimTur: uretimTur.value,
          durum: durum.value === true ? true : false,
        };
        await updateDoc(myDocRef, data);
        router.push("/admin/company-list");

      } catch (error) {
        hataMesaj.value = error.message;
        isLoading.value = false;
        showModal.value = true;
      }
    };

    return {
      tarih,
      fId,
      olusturulmaTarihi,
      firmaAd,
      blokNo,
      isyeriNo,
      telNo,
      email,
      resimUrl,
      bilgiKontrol,
      isLoading,
      handleSubmit,
      hataMesaj,
      fileHata,
      resim,
      uretimTur,
      durum,
      showModal
    };
  },
};
</script>
  
  <style>
</style>
  
  
  